import React from 'react';
import styled from "styled-components";
import _ from 'lodash';
import { Descriptions } from 'antd';
import Amount from "../../../components/display/Amount";


const SummaryContainer = styled.div`
    text-align:left;
    margin-bottom: 15px;
    border-radius: 5px;
`;


const PaymentSummary = ({action}) => {

    return <SummaryContainer>        
            <Descriptions bordered size="small" layout="vertical">
                <Descriptions.Item label="Development">{action.developmentLabel}</Descriptions.Item>
                <Descriptions.Item label="Unit">
                    {action.unitLabel}
                    {action.selectedPlan ? "(Plan "+action.selectedPlan.label+")" :''}
                </Descriptions.Item>
                <Descriptions.Item label={action.reservationFee.label}>                
                    <Amount amount={action.reservationFeeAmount} style={{fontWeight:'bold'}}/>
                </Descriptions.Item>           
            </Descriptions>
        </SummaryContainer>
}
export default PaymentSummary;