import React from 'react';
import {BrowserRouter, BrowserRouter as Router} from "react-router-dom";
import 'antd/dist/antd.css';
import './index.css';
import './i18n';

import ReactDOM from 'react-dom';

import * as serviceWorker from './serviceWorker';
import MainRouter from './MainRouter'
let root = document.getElementById('root');


if (!root) {
    // eslint-disable-next-line no-console
    console.error('Could not attach react to element with id root, because it does not exist')
} else {
    ReactDOM.render(
        <BrowserRouter>
             <MainRouter/>
        </BrowserRouter>
        , root);
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
