import React from 'react';
import Button from "antd/es/button";
import {Link} from "react-router-dom";

export const Demo = () =>{
    return "demo"
}
export const LaunchPreviewSwitcher = ({link}) =>{


    return  <div style={{background:"red",overflow:'hidden',width:'100%', padding:'0 10px'}}>
        <span style={{float:'left',color:'white',paddingTop:5}}>DEMO</span>
        <Button  style={{float:'right',color:'white'}} type={"link"}>{link}
        </Button>
    </div>
}

export default Demo