import React, { useEffect, useState, useContext } from 'react';
import styled from "styled-components";
import find from 'lodash/find';

import {Provider, useTracked} from "../../../MainRouter"
import { pathToReservationOnceOffRoute } from '../../../routes/publicRoutes';
import { withRouter, useHistory, Link } from "react-router-dom";
import { Modal,notification, Row, Col} from 'antd';

import { Formik } from 'formik'
import WizardContent from "../../../components/containers/WizardContent"
import { PrimaryBlockButton, SecondaryBlockButton } from '../../../components/button/Button';
import { fetchUnitData, postUnitSource } from '../../../services/PropertyPassService';
import { updateUnitReservationStep } from '../../../services/EventTriggerService';
import { ActionRequestContext } from "../ActionRequestContext";
import OnceOff from "../OnceOff";
import PlanSelector from "../../../components/property/PlanSelector";
import { convertTimestampToDate, calculateExpiryTime, CountdownTicker } from "../../../components/progress/CountdownTicker";
import Amount from '../../../components/display/Amount';
import {PaddedSkeleton, withReservation} from './ReservationProvider'
import RoadBlock from '../../../components/containers/RoadBlock'


const PriceHeader = styled.div`
    .label{
        text-transform: 'uppercase';
        font-size: 0.7rem;
        line-height: 1;
        margin: 0;
        display: block;
    }
    .amount{
        font-size: 1.2em;
        line-height: 1;
        margin: 0;
        font-weight: bold;

    }
    .left{
        font-weight: bold;
        padding: 10px 20px 5px 40px;       
        text-align: left;
        background: whitesmoke;
        color: black;
        /* height: 40px; */
        
    }
    .right{
        background: ${props => props.theme.primaryActionBgColor};
        color: ${props => props.theme.primaryActionColor};
        padding: 10px 40px 5px 20px;
        text-align: left;
        /* height: 40px; */
    }
`;

const UnitHeader = styled.div`
    padding: 20px 40px;
    text-align: left;
    border-bottom: 1px solid #f0f0f0;
    h2 {        
        font-weight: bold;
        color: ${props => props.theme.primaryActionBgColor};
        margin-bottom:0;    }
    h3{
        margin-bottom:0;color: rgba(0,0,0,.45);
    }
`;


const UnitConfirm = (props) => {

    const [messengerState, messengerDispatch] = useTracked();
    const [appContext] = useContext(ActionRequestContext);
    const [unit, setUnit] = useState({});
    const [unitFetch, setUnitFetch] = useState({fetchingData:false, attempts:0});   
    const [exiting, setExiting] = useState(false); 

    const [totalPrice, setTotalPrice] = useState(null);
    const [reservationFeeAmount, setReservationFeeAmount] = useState(null)
    const [selectedPlan, setSelectedPlan] = useState({id:null,label:null});
    const [expiryDate, setExpiryDate] = useState(props.expiryDate);
    const { arToken, userToken, actionToken, currentAction} = appContext;

    const nextUrl = pathToReservationOnceOffRoute(arToken, userToken, actionToken, "purchaser");

    const returnExpiryDate = (expiryDate) => {                          
        var millisecondDate = parseInt(expiryDate.getTime());
        setExpiryDate(expiryDate);
        props.postActionDataToServer(arToken, actionToken, { expiryDate: millisecondDate }).then(()=>{
            props.setExpiryDate(millisecondDate);     
        })                
    }   
    
    useEffect(() => {     
        console.log("UnitConfirmation useEffect.... ")
        props.updateActionState("viewed");  
        props.updateLastStep("confirmation");                       
        if (currentAction && (props.expiryDate === null || props.expiryDate === undefined)) {
            //Fetch and set                                             
            calculateExpiryTime(
                props.expiryDate,
                currentAction.timeout,
                currentAction.startTime,
                (expiryDate => { returnExpiryDate(expiryDate); })
            )
        } else {
            //we have data
            if (!expiryDate && !(props.expiryDate instanceof Date)){
                setExpiryDate(convertTimestampToDate(props.expiryDate));
            }           
        }
        
        fetchUnitDataOnKey(false);            
    }, [])

    useEffect(() => {
        if (props.expiryDate !== expiryDate){            
            setExpiryDate(props.expiryDate);
        }       
    }, [props.expiryDate])

    //When the unit information gets populated we want to take a look and see if the unit is still available
    useEffect(() => {
        //unit.isAvailable
        //unit.saleStatus
        console.log("UNIT RESPONDER>>> ", unit)
        if (unit.saleStatus === "sold"){
            props.handleSoldOut();
        }
    },  [unit])

    const fetchUnitDataOnKey = (refetch) => {        
        if ((currentAction.unitSource || currentAction.unitKey) && unitFetch.attempts < 3 &&  (refetch || unitFetch.fetchingData === false)) {             
            let attempts = unitFetch.attempts++
            setUnitFetch((state) => ({...state, fetchingData:true, attempts:attempts})); 
            let unitKey = currentAction.unitKey?currentAction.unitKey:currentAction.unitSource.source+"-"+currentAction.unitSource.developmentKey+"-"+currentAction.unitSource.unitKey;                                  
            fetchUnitData(unitKey).then(unitData => {
                //What do we do if unit data isn't ready?                                
                if (unitData.error && unitData.error === "non-existant"){
                    //There was a problem fetching this data, trigger a refetch                    
                    postUnitSource(currentAction.unitSource).then(data =>{                        
                        if (data.unitKey && !currentAction.unitKey){
                            currentAction.unitKey = data.unitKey;
                            props.updateCurrentAction({unitKey:data.unitKey});
                        }                        
                        fetchUnitDataOnKey(true);  
                    })
                }else{
                    if (unitData.label === null || unitData.label === undefined){                    
                        fetchUnitDataOnKey(true);
                    }else{
                        setUnitFetch((state) => ({...state, fetchingData:false})); 
                        setUnit(unitData);
                    }    
                }
                
                
            }).catch(error =>{
                //lets try again
                console.log("we have an error", error);
                fetchUnitDataOnKey(true);
            })
        }      
    }

    const calculateReservationAmount = (totalPrice) => {

        const reservationFee = currentAction.reservationFee;
        const reservationAmount = reservationFee.amount;
        if(reservationFee.type === 'fixed'){         
            return reservationAmount;
        }else if(reservationFee.type === 'percentage'){
            return totalPrice * reservationAmount;
        }
        
    }

    // Triggered when a user changes plan with the PlanSelector
    const onPlanSelectorChange = (current) => {
        changePlan(current);
      
    }

    const changePlan = (current) => {
        if(current.id === null){            
            setSelectedPlan({id:null,label:null})      
        }
        else if(current.id === 'plot-only'){
            setSelectedPlan({id:'plot-only',label:'Plot only'})   
            setTotalPrice(unit.plotPrice)   
            setReservationFeeAmount(calculateReservationAmount(unit.plotPrice))
        }else{
        
            const selectedPlanOption = find(unit.plans,(plan)=>{
                return plan.id === current.id
            })

            setSelectedPlan(selectedPlanOption)
            const newTotalPrice = unit.plotPrice + selectedPlanOption.planPrice
            setTotalPrice(newTotalPrice)   
            setReservationFeeAmount(calculateReservationAmount(newTotalPrice))
        }
    }

    
    useEffect(()=>{
        //console.log("selectedPlan",selectedPlan)        
    },[selectedPlan, totalPrice])
  

    const handleCancelClick = (returnPath) => {
        // Cancel this action                
        // Redirect to path
        setExiting(true);
        props.postActionDataToServer({state:"user-cancelled", actionType:"userCancelled"}).then(data =>{            
            window.location = returnPath;
        });    
        
    }
    const handleOpenWrongUnitClick = () => {
        Modal.info({
            icon: null,
            okText: "Close",
            title: 'What would you like to do?',
            content: (
              <div style={{margin:"20px 0 0"}}>                                   
                  {currentAction.returnPaths.map((returnPath,index)=>{                        
                        return <SecondaryBlockButton loading={exiting} onClick={()=>{ handleCancelClick(returnPath.value) }}>{returnPath.label}</SecondaryBlockButton>
                    })}
              </div>
            ),
            onOk() {},
          });
    }

    const handlePrimaryClicked = () => {     
        props.updateActionState("started").then(() =>{
            let data = {selectedPlan:{id:selectedPlan.id, label:selectedPlan.label}, 
            totalPrice:totalPrice,
            reservationFeeAmount:reservationFeeAmount}    
            props.postActionDataToServer(data);        
        })
    }

    const handleTimesUp = () => {        
        props.setExpiryDate(false);        
    }        
    
    return (
            
        <OnceOff ready={appContext.currentAction}>                             
            <RoadBlock blocked={appContext.blocked}>                     
                <WizardContent
                    bodyStyle="noPadding" activeStep={1} totalSteps={6} title="Confirm unit" info="Before we go any further please review the details of the unit you are about to reserve"
                    afterHeader={(expiryDate)?<CountdownTicker expiryDate={expiryDate} handleTimesUp={handleTimesUp} />:null}
                    >                                           
                 
                    
                    {/* HEADER */}
                    <UnitHeader>
                        {unitFetch.fetchingData === true?
                            <PaddedSkeleton paragraph={{ rows: 2 }} active /> :
                            <React.Fragment>
                                <h2>Unit {unit.label}</h2>
                                <h3>{unit.description}</h3>
                                {unit.development ?  <p>{unit.development.label}, {unit.development.suburb}, {unit.development.province}, {unit.development.country}</p> :''}                    
                            </React.Fragment>                        
                        }
                    </UnitHeader>

                    {/* PLAN INFO */}
                    {unit.plans ?
                        <PlanSelector plans={unit.plans} selectedPlan={currentAction.selectedPlan} onChange={onPlanSelectorChange} unitLabel={unit.label} planRequired={unit.planRequired} unitType={unit.propertyType} plotSize={unit.plotSize}/>
                        :''}   

                    {/* PRICING */}
                    {currentAction && !unitFetch.fetchingData?<PriceHeader style={{marginTop:2}}>
                        <Row>
                            <Col span={12} className="left">
                                <span className='label'>Total Price</span>
                                {totalPrice ? <Amount amount={totalPrice} /> : '...'}
                            </Col>
                            <Col span={12} className="right">
                                <span className='label'>{currentAction.reservationFee.label ? currentAction.reservationFee.label : 'Reservation Fee'}</span>
                                {reservationFeeAmount ? <Amount amount={reservationFeeAmount} /> : '...'}
                            </Col>
                        </Row>
                    </PriceHeader>:<PaddedSkeleton />}

                    {/* ACTION */}
                    <div style={{ margin: 30 }}>
                        <PrimaryBlockButton style={{ marginBottom: 15 }} disabled={selectedPlan.id === null}>
                           {selectedPlan.id ? <Link to={nextUrl} onClick={handlePrimaryClicked}> Purchase Unit {unit.label} with Plan {selectedPlan.label} </Link> : "Please select an plan option above"}                            
                        </PrimaryBlockButton>
                        <SecondaryBlockButton style={{ marginBottom: 30 }} onClick={handleOpenWrongUnitClick}>This isn't the correct unit</SecondaryBlockButton>
                    </div>
                    
                </WizardContent>                
            </RoadBlock>                         
        </OnceOff>
    )
}

export default withRouter(withReservation(UnitConfirm))