
import React from 'react';
import { Button as AntButton } from 'antd';
import styled from "styled-components";
import Icon from "../icon/Icon";
import Col from "antd/es/grid/col";

export const StyledAntButton = styled(AntButton)`
&&&{
    color:${props => props.theme.primaryActionColor};
    background-color:${props => props.theme.primaryActionBgColor};
    border-color:${props => props.theme.primaryActionBgColor};
    font-weight: bold;
    max-width: 400px;
    margin-bottom: 10px;
    // height: 50px;
    // line-height: 50px;
    &[disabled]{
        background-color: #f5f5f5;
        border-color:#d9d9d9;
    }
}
`;

export const StyledSecondaryButton = styled(AntButton)`
&&&{
    max-width: 400px;
    margin-bottom: 10px;
    // height: 50px;
    // line-height: 50px;

}
`;
export const OauthButton = styled(AntButton)`
&&&{
    color:#FFFFFF;
    font-weight: bold;
    margin-bottom: 10px;
    text-align:left;
    max-width: 300px;
    &.centered{
        margin-left:auto;
        margin-right:auto;
    } 
}
`

export const GoogleStyleButton = styled(OauthButton)`
&&&{
    background-color: rgb(224, 73, 50);
    border-color:rgb(177, 59, 39);
}
`;
export const FacebookStyleButton = styled(OauthButton)`
&&&{
    background-color: rgb(57, 88, 154);
    border-color:rgb(0, 106, 178);
}
`;
export const EmailStyleButton = styled(OauthButton)`
&&&{
    background-color: rgb(57, 88, 154);
    border-color:rgb(0, 106, 178);
}
`;
const Button = (props) => {
    return <StyledAntButton {...props}>{props.content}</StyledAntButton>
};

const PrimaryBlockButton = (props) => {
    return <StyledAntButton type="primary" size="large" block {...props}>{props.children}</StyledAntButton>
};

const SecondaryBlockButton = (props) => {
    return <StyledSecondaryButton  size="large" block {...props}>{props.children}</StyledSecondaryButton>
};

export const GoogleButton = ({actionLabel,...props}) => {

    return <GoogleStyleButton type="primary"  size="large" block icon={<Icon icon="google" color={'white'}/>} {...props}>
        {actionLabel||"Continue"} with Google
           </GoogleStyleButton>
}

export const FacebookButton = ({actionLabel,...props}) => {

    return <FacebookStyleButton type="primary" size="large" block icon={<Icon icon="facebook" color={'white'}/>} {...props}>
        {actionLabel||"Continue"} with Facebook
    </FacebookStyleButton>
}

export const EmailButton = ({actionLabel,...props}) => {

    return <EmailStyleButton type="primary" size="large" block icon={<Icon icon="envelope" color={'white'}/>} {...props}>
        {actionLabel||"Continue"}  with Email
    </EmailStyleButton>
}


export {
    PrimaryBlockButton ,
    SecondaryBlockButton,
    Button};
