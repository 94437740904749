import React, { useState } from 'react';
import styled from "styled-components";
import { Formik } from 'formik'
import { Form} from 'formik-antd'
import { Button, Select } from 'antd';

const DemoWrapper = styled.div`
    max-width: 600px;
    background: whitesmoke;
    margin: 0 auto;
    padding: 40px;
    border-radius: 5px;    
`;

const DemoPresets = (wrapperProps) =>{

    const [preset, setPreset] = useState()

    const onPresetChange = (value) => {
        setPreset(value);
    }

    return <Formik
            enableReinitialize={true}
            initialValues={[]}
            onSubmit={(values) => {
                values.presetId = preset;
                wrapperProps.onGenerate(values)

            }}
            render={(props) => {
                return <DemoWrapper>
                            <Form onSubmit={props.handleSubmit} layout='vertical' >
                                <h1>Demo Page</h1>
                                <p>Use this page to launch a demo for a particular preset journey</p>
                                <Form.Item label="Select preset" name="preset">
                                    <Select name="preset" style={{minWidth:200}} value={preset} onChange={onPresetChange}>
                                        {wrapperProps.presetList.map(preset => (
                                            <Select.Option key={preset.code}>{preset.label}</Select.Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                                <Button size="large" htmlType="submit">Generate</Button>
                            </Form>
                     </DemoWrapper>
            }} />

}

export default DemoPresets;