import React from 'react';
import styled from "styled-components";
const splitter = require('full-name-splitter');

const NameSpan = styled.span`
    text-transform: capitalize;
`;
/**
 * Used to display a persons first name when given the full name
 */
const FirstName = ({name,...props}) =>{

    let splitName = splitter.default(name)
    let firstName = splitName[0]
    return <NameSpan>{firstName}</NameSpan>
}

export {
    FirstName
};