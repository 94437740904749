import React, {useState,useContext} from 'react';
import Layout from "antd/es/layout";
import Menu from "antd/es/menu";
import {EntityContext} from "../../providers/EntityProvider";
import {useHistory, withRouter} from "react-router-dom";
import styled,{ThemeProvider} from "styled-components";
import Image from "../image/Image";
import {RightMenu} from "../../ui/TopBar";
import {AuthContext} from "../../pages/auth/AuthProvider";
import Avatar from "antd/es/avatar";
import Space from "antd/es/space";
import firebase from "firebase";
const { SubMenu } = Menu;
const { Header, Content, Sider } = Layout;

const TopHeader = styled(Header)`
    background: white;
    padding: 0 10px;
    .brandHolder img{
        max-height: 40px;
    }
`
const StyledSider = styled(Sider)`
   
`


const UserContainer = (props) =>{
    const leftMenu = props.leftMenu

    const {userData} = useContext(AuthContext)
    const [leftMenuCollapsed, setLefMenuCollapsed] = useState(false)

    const history = useHistory();

    const handleMenuToggle = () =>{
        setLefMenuCollapsed(!leftMenuCollapsed)
    }

    const handleSignOut = () => {
        firebase
            .auth()
            .signOut()
            .then(res => {
                history.push("/login");
            })
    }

    const onCollapse = (collapsed) =>{
        setLefMenuCollapsed(collapsed)
    }

    if( !userData){
        return <div>loading...</div>
    }
    // console.log("entity.theme",entity.theme)

    return  <Layout>
                <TopHeader>
                    <span className="brandHolder">
                        <img src='/images/logo/property-pass-logo.svg' alt="Property Pass" />
                        <span style={{display:"inline-block",lineHeight:'49px',verticalAlign:"bottom",padding:'0 10px',fontSize:'25px',fontWeight:'bold'}}>Property Pass</span>
                        {/*<Image json={entity.theme.logo} defaultImage={entity.theme.defaultLogo} />*/}
                    </span>
                    <RightMenu>
                    <Menu theme="light" mode="horizontal" style={{border:'none'}} >
                        <SubMenu  title={<Space><Avatar src={userData.photoUrl} />{userData.displayName}</Space>}>
                            <Menu.Item key="setting:4" onClick={handleSignOut}>Sign out</Menu.Item>
                        </SubMenu>
                    </Menu>
                    </RightMenu>
                </TopHeader>
                <Layout>
                    {leftMenu ? <StyledSider  collapsible collapsed={leftMenuCollapsed} onCollapse={onCollapse}>
                        {leftMenu}
                    </StyledSider> :''}
                    <Layout>
                    {/*    <Breadcrumb style={{ margin: '16px 0' }}>*/}
                    {/*    <Breadcrumb.Item>Home</Breadcrumb.Item>*/}
                    {/*    <Breadcrumb.Item>List</Breadcrumb.Item>*/}
                    {/*    <Breadcrumb.Item>App</Breadcrumb.Item>*/}
                    {/*</Breadcrumb>*/}
                        <Content style={{
                                margin: 0,
                                minHeight: 280,
                            }}>
                            {props.children}
                        </Content>
                    </Layout>
                </Layout>
            </Layout>

}

export default withRouter(UserContainer)