import React, { useEffect, useState } from "react";
import {fetchEntity} from "../services/PropertyPassService";
import {withRouter} from "react-router-dom";

const initialState = {
    entity: null,
    loadingEntityState: true
}

export const EntityContext = React.createContext(initialState);

const EntityProvider = (props) => {

    const params = props.match.params
    const entityId2 = params.entityId
    const launchId = params.launchId
    const slug = params.slug

    console.log("AccountContainer:entityId2",entityId2)
    console.log("AccountContainer:launchId",launchId)
    console.log("AccountContainer:slug",slug)

    const [entityId] = useState(props.match.params.entityId)
    const [entity,setEntity] = useState(null)
    const [loadingEntityState] = useState(true);

    useEffect(() => {
        if(entityId){
            // load from params
            fetchEntity(entityId).then(data => {
               setEntity(data)
            })
        }


    }, []);

    return (
        <EntityContext.Provider
            value={{
                entity,
                setEntity,
                loadingEntityState
            }}>
            {props.children}
        </EntityContext.Provider>
    );

}


export default withRouter(EntityProvider)