import React, {useState} from 'react';
import { Form, Radio, Select, Input } from 'formik-antd'
import { Col, Row } from 'antd';
import {SelectTwoInRow} from './Responder'
const { Option } = Select;


const ResponderPriceBracket = ({ pageProps, formProps }) => {

    const interestPageData = formProps.values
    let purchaseInterest = pageProps.pageData['interest'].rentOrBuy;
    if (purchaseInterest === undefined){
        purchaseInterest = "either"
    }
    console.log("ormProps.values.rentOrBuy", formProps.values.rentOrBuy)
    console.log("interestPageData", interestPageData)
    console.log("wrapperProps.pageProps ", purchaseInterest)

    const priceRange = [{ value: 0, display: "Min Price", text: "Any" },
    { value: 100000, display: "R 100k", text: "R 100 000" },
    { value: 150000, display: "R 150k", text: "R 150 000" },
    { value: 200000, display: "R 200k", text: "R 200 000" },
    { value: 250000, display: "R 250k", text: "R 250 000" },
    { value: 300000, display: "R 300k", text: "R 300 000" },
    { value: 350000, display: "R 350k", text: "R 350 000" },
    { value: 400000, display: "R 400k", text: "R 400 000" },
    { value: 450000, display: "R 450k", text: "R 450 000" },
    { value: 500000, display: "R 500k", text: "R 500 000" },
    { value: 600000, display: "R 600k", text: "R 600 000" },
    { value: 700000, display: "R 700k", text: "R 700 000" },
    { value: 800000, display: "R 800k", text: "R 800 000" },
    { value: 900000, display: "R 900k", text: "R 900 000" },
    { value: 1000000, display: "R 1m", text: "R 1 000 000" },
    { value: 1250000, display: "R 1.25m", text: "R 1 250 000" },
    { value: 1500000, display: "R 1.5m", text: "R 1 500 000" },
    { value: 1750000, display: "R 1.75m", text: "R 1 750 000" },
    { value: 2000000, display: "R 2m", text: "R 2 000 000" },
    { value: 2500000, display: "R 2.5m", text: "R 2 500 000" },
    { value: 3000000, display: "R 3m", text: "R 3 000 000" },
    { value: 3500000, display: "R 3.5m", text: "R 3 500 000" },
    { value: 4000000, display: "R 4m", text: "R 4 000 000" },
    { value: 4500000, display: "R 4.5m", text: "R 4 500 000" },
    { value: 5000000, display: "R 5m", text: "R 5 000 000" },
    { value: 6000000, display: "R 6m", text: "R 6 000 000" },
    { value: 7000000, display: "R 7m", text: "R 7 000 000" },
    { value: 8000000, display: "R 8m", text: "R 8 000 000" },
    { value: 9000000, display: "R 9m", text: "R 9 000 000" },
    { value: 10000000, display: "R 10m", text: "R 10 000 000" },
    { value: 15000000, display: "R 15m", text: "R 15 000 000" }
    ]

    const rentalRange = [{value:0, text: "Any"},
        { value: 1000, text: "R 1000" },
        { value: 2000, text: "R 2000" },
        { value: 3000, text: "R 3000" },
        { value: 4000, text: "R 4000" },
        { value: 5000, text: "R 5000" },
        { value: 6000, text: "R 6000" },
        { value: 7000, text: "R 7000" },
        { value: 8000, text: "R 8000" },
        { value: 9000, text: "R 9000" },
        { value: 10000, text: "R 10 000" },
        { value: 15000, text: "R 15 000" },
        { value: 20000, text: "R 20 000" },
        { value: 25000, text: "R 25 000" },
        { value: 30000, text: "R 30 000" },
        { value: 35000, text: "R 35 000" },
        { value: 40000, text: "R 40 000" },
        { value: 45000, text: "R 45 000" },
        { value: 50000, text: "R 50 000" }
    ]

    const [maxPriceRange, setMaxPriceRange] = useState(priceRange)
    const [maxRentalRange, setMaxRentalRange] = useState(rentalRange)

    

    const handleMinRangeChange = value => {
        console.log(value)
        const maxPriceRange = []        
        for (let i = 0; i < priceRange.length; i++) {
            let loopPrice = priceRange[i];
            if (value < loopPrice.value) {
                maxPriceRange.push(loopPrice)
            }
        }        
        setMaxPriceRange(maxPriceRange);
    };

    const handleMinRentaChange = value => {      
        const maxRentalRange = []          
        for (let i = 0; i < rentalRange.length; i++) {
            let loopPrice = rentalRange[i];
            if (value < loopPrice.value) {
                maxRentalRange.push(loopPrice)
            }
        }
        setMaxRentalRange(maxRentalRange);
    }


    return <React.Fragment>
        {(purchaseInterest === 'buy' || purchaseInterest === 'either') ?
            <Form.Item label="What sort of price range are you looking at?" name="purchasePriceRange">
                <Row>
                    <Col xs={{ span: 24}} md={{ span: 12}}>
                        <SelectTwoInRow name="minPrice" style={{ width: 180 }} onChange={handleMinRangeChange} placeholder="Min Price">
                            {priceRange.map(price => (
                                <Option key={price.value}>{price.text}</Option>
                            ))}
                        </SelectTwoInRow>
                    </Col>
                    <Col xs={{ span: 24 }} md={{ span: 12}}>
                        <SelectTwoInRow name="maxPrice" style={{ width: 180 }} placeholder="Max Price">
                            {maxPriceRange.map(price => (
                                <Option key={price.value}>{price.text}</Option>
                            ))}
                        </SelectTwoInRow>
                    </Col>
                </Row>
            </Form.Item> : ""
        }
        {(purchaseInterest === 'rent' || purchaseInterest === 'either') ?
            <Form.Item label="What sort of rent are you looking at?" name="rentPriceRange">
                <Row>
                    <Col xs={{ span: 24}} md={{ span: 12}}>
                        <SelectTwoInRow name="minRental" style={{ width: 180 }} onChange={handleMinRentaChange} placeholder="Min Rental">
                            {rentalRange.map(price => (
                                <Option key={price.value}>{price.text}</Option>
                            ))}
                        </SelectTwoInRow>
                    </Col>
                    <Col xs={{ span: 24 }} md={{ span: 12}}>
                        <SelectTwoInRow name="maxRental" style={{ width: 180 }} placeholder="Max Rental">
                            {maxRentalRange.map(price => (
                                <Option key={price.value}>{price.text}</Option>
                            ))}
                        </SelectTwoInRow>
                    </Col>
                </Row>
            </Form.Item> : ""
        }
        {(purchaseInterest === 'buy' || purchaseInterest === 'either') ?
            <Form.Item label="Do you have money for a deposit?" name="hasDeposit">
                <Radio.Group name="hasDeposit">
                    <Radio.Button value="false">No</Radio.Button>
                    <Radio.Button value="true">Yes</Radio.Button>
                </Radio.Group>
            </Form.Item >            
        :""}
        {formProps.values.hasDeposit === 'true' ?
             <Form.Item label="Approximately how much do you have for a deposit?" name="depositAmount">
                 <Input type="number" name="depositAmount" addonBefore="R" defaultValue="" min="0" />
             </Form.Item > : ''}

    </React.Fragment>
}


export default ResponderPriceBracket;