import React, { useEffect, useState, useContext } from 'react';
import styled from "styled-components";
import _ from 'lodash';

import { pathToReservationOnceOffRoute } from '../../../routes/publicRoutes';
import { withRouter, useHistory, Link } from "react-router-dom";
import { PrimaryBlockButton, SecondaryBlockButton } from '../../../components/button/Button';
import {CountdownTicker} from "../../../components/progress/CountdownTicker";
import { Collapse } from 'antd';

import WizardContent from "../../../components/containers/WizardContent"
import { ActionRequestContext } from "../ActionRequestContext";
import OnceOff from "../OnceOff";
import RoadBlock from '../../../components/containers/RoadBlock'
import withReservation from './ReservationProvider'
import { updateUnitReservationStep } from '../../../services/EventTriggerService';

const { Panel } = Collapse;


const QrHolder = styled.div`
    padding: 10px;
    background: whitesmoke;
    border-radius: 5px;
    max-width: 250px;
    margin:  20px auto;
`;
;

const ReservationPaymentScanToPay = (props) => {
        
    const [appContext] = useContext(ActionRequestContext);    
    const { arToken, userToken, actionToken, currentAction} = appContext;
    const backUrl = pathToReservationOnceOffRoute(arToken, userToken, actionToken, "payment")
    const [expiryDate, setExpiryDate] = useState(props.expiryDate);

    const handleTimesUp = () => {
        props.setExpiryDate(false);        
    }

    useEffect(() =>{    
        if (!(currentAction.termsAccepted && (currentAction.verificationId !== "" || currentAction.verificationId !== undefined))){            
            props.handleInvalidStep();
        }else{
            props.updateLastStep("payment-selected", true);            
        }
    }, [])

    useEffect(() => {
        if (props.expiryDate !== expiryDate){            
            setExpiryDate(props.expiryDate);
        }       
    }, [props.expiryDate])


    return (
        <OnceOff>
            <RoadBlock blocked={appContext.blocked}>         
                <WizardContent 
                    activeStep={5} totalSteps={6} title="FNB Scan-to-pay" info="Pay using FNBs Mobile App"
                    afterHeader={(expiryDate)?<CountdownTicker expiryDate={expiryDate} handleTimesUp={handleTimesUp}/>:null}
                >
                    <h3>Scan this QR Code with your FNB App</h3>
                    <QrHolder>
                        <img src="/images/demo/demoqr.png" style={{width:"100%",height:"auto"}}/>
                    </QrHolder>

                    <h3>Instructions</h3>
                    <Collapse bordered={false} style={{textAlign:'left'}}>
                        <Panel header="Open FNB app on your phone" key="1">
                            <img src="/images/payment/scan-to-pay/scan-to-pay1.jpg" alt="Open FNB app on your phone" style={{display:"block",margin:"0 auto",width:"70%",height:"auto"}}/>
                        </Panel>
                        <Panel header="Select Scan to pay" key="2">
                            <img src="/images/payment/scan-to-pay/scan-to-pay2.jpg" alt="Select Scan to pay" style={{display:"block",margin:"0 auto",width:"70%",height:"auto"}}/>
                        </Panel>
                        <Panel header="Scan the QR Code above" key="3">
                            <img src="/images/payment/scan-to-pay/scan-to-pay3.jpg" alt="Scan the QR Code above" style={{display:"block",margin:"0 auto",width:"70%",height:"auto"}}/>
                        </Panel>
                    </Collapse>
                    <SecondaryBlockButton>
                        <Link to={backUrl}>Back</Link>                            
                    </SecondaryBlockButton>
                </WizardContent>
            </RoadBlock>    
        </OnceOff>
    )
}

export default withRouter(withReservation(ReservationPaymentScanToPay))