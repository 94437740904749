import React from 'react';
import styled from "styled-components";
import ProgressStepIndicator from "../progress/ProgressStepIndicator";

// Wizar
const WizardContainer = styled.div`
    max-width: ${props => props.theme.onceOffPages.maxWidth};
    margin: 0 auto;  
`;


const WizardContent = styled.div`
    background: ${props => props.theme.contentBgColor};    
    text-align: center;

    @media only screen and (min-width: 970px) {
        padding: 50px 80px;   
      
    }
`;

const ContentBody = styled.div`
    background: ${props => props.theme.contentBgColor};    
    text-align: center;
    min-height: 500px;
    min-width: 340px;
    padding: 20px 20px;   
    &.noPadding{
        padding: 0;   
    }
    @media only screen and (min-width: 970px) {
        padding: 50px 80px;   
        &.noPadding{
            padding: 0;   
        }
    }
`;


const WizardContentWithHeader = styled.div`
`;

const ContentHeader = styled.div`
    color: ${props => props.theme.wizard.headerColor}; 
    background: ${props => props.theme.wizard.headerBgColor}; 
    padding: 25px 50px;   
    text-align: center;
    
    h2 {
        font-size: ${props => props.theme.fontSize.default};
        font-weight: bold;
        margin-bottom: 0.2em;
    }
    p{
        font-size: ${props => props.theme.fontSize.default};
        margin-bottom:0;
    }

`;

const WizardImage = styled.img`
    max-width: 100px;
    max-height: 100px;
    margin-bottom: -25px;
    &.middle{
        margin-bottom: 0px;
        margin-top: -10px;
    }
`;


const WizardPageContent = React.memo((props) => {

    let showHeader = props.title || props.info || props.image;

    let bodyStyle = props.bodyStyle ? props.bodyStyle : "default"
    let content = null
    if (!showHeader) {
        content = <WizardContent>{props.children}</WizardContent>
    } else {
        content = <WizardContentWithHeader>
            <ContentHeader>
                {props.title ? <h2>{props.title}</h2> : ''}
                {props.info ? <p>{props.info}</p> : ''}
                {props.image ? <WizardImage src={props.image} className={props.imageClass} /> : ''}
            </ContentHeader>
            {props.afterHeader ? props.afterHeader : ''}
            <ContentBody className={bodyStyle}>
                {props.children}
            </ContentBody>
        </WizardContentWithHeader>
    }
    return <WizardContainer>
        {props.totalSteps ? <ProgressStepIndicator activeStep={props.activeStep} totalSteps={props.totalSteps} /> : ''}

        {content}
    </WizardContainer>
})

export default WizardPageContent;
