import React, { useState, useEffect } from 'react';
import styled from "styled-components";
import { Layout} from 'antd';

import MainPageLoader from '../../components/loading/MainPageLoader';
import DemoForm from './DemoForm';
import {fetchUsers, fetchEntities, fetchPresets, postPreset, putPreset} from '../../services/PropertyPassService';


const { Content } = Layout;

const PresetContainer = styled(Layout)`
    padding: 4em;
    background: white;
    form .ant-form-item-label{
        padding-bottom: 0;
    }
`;

const Preset = () => {
    
    const [entities, setEntities] = useState([])
    const [users, setUsers] = useState([]);
    const [presetList, setPresetList] = useState([])
    const [preset, setPreset] = useState("new")

    useEffect(() => {        
        fetchEntities().then(responseData => {    
            let userData = [];
            Object.keys(responseData).forEach(function(key, val){                
                userData.push({value:key, text:responseData[key].name})
            })                        
            setEntities(userData);
            //console.log(responseData)
        });
        fetchPresets().then(responseData => {    
            let presetData = [];
            console.log("PRESET SET?!@# presetData", presetData)
            Object.keys(responseData).forEach(function(key, val){                
                presetData.push({code:key, label:responseData[key].presetLabel, values:responseData[key]})
            })        
            console.log("presetData", presetData)                
            setPresetList(presetData);
            //console.log(responseData)
        });
        fetchUsers().then(responseData => {    
            let userData = [];
            Object.keys(responseData).forEach(function(key, val){                
                userData.push({value:key, text:responseData[key].name})
            })                        
            setUsers(userData);
            //console.log(responseData)
        });
    }, [])    
    
   

    const onGenerate = (values) => {            
        console.log("values", values);
        if (values.presetId === "new"){
            //POST
            postPreset(values).then((data)=>{
                console.log("we got an id back", data)
                let tmpPresetList = presetList
                tmpPresetList.push({"code":data.id, "label":data.presetLabel})
                setPresetList(tmpPresetList)
                setPreset(data.id)
            })
        }else{
            //PUT
            putPreset(values.presetId, values).then((data)=>{
                console.log("we got an id back", data)
            })
        }
    }  
    
    console.log("presetList", presetList)
    console.log("Object.keys(users).length", Object.keys(users).length)
    return <PresetContainer>        
        {Object.keys(users).length == 0?<MainPageLoader/>:
            <Content style={{ overflow: 'scroll' }}>                
                <DemoForm data={[]} invokers={users} entities={entities} presetList={presetList} preset={preset} onGenerate={onGenerate}/>                                       
            </Content>  
        }      
    </PresetContainer>
}

export default Preset;
