import React, {useState, useEffect} from 'react';
import { Form, Radio, Select } from 'formik-antd'
import {BlockCheckboxGroup, BlockRadioGroup, MultipleSelectBox, fetchLocation} from './Responder'
const { Option } = Select;

const ResponderPropertyInterest = ({ pageProps, formProps }) => {


    const [data, setData] = useState([])
    const propertyTypeOptions = [
        { label: "House", value: "house" },
        { label: "Vacant Land/Plot", value: "plot" },
        { label: "Townhouse", value: "townhouse" },
        { label: "Farm", value: "farm" },
        { label: "Apartment", value: "apartment" },

    ]

    const handleSearch = value => {
        if (value) {
            fetchLocation(value, data => setData(data));
        } else {
            setData([]);
        }
    };


    useEffect(() => {
        console.log("useEffect ", data)

    }, [data]);

    const interestPageData = formProps.values

    const options = data.map(d => <Option key={d.value}>{d.text}</Option>);

    return <React.Fragment>
         <Form.Item label="Where are you looking to move?" name="propertyLocation">
            <MultipleSelectBox name="propertyLocation" style={{ width: "100%" }} placeholder="Select multiple" mode="multiple" onSearch={handleSearch} notFoundContent="Start typing to search the suburbs you are interested in"
                showSearch
                defaultActiveFirstOption={false}
                showArrow={false}
                filterOption={false}
            >
                {options}
            </MultipleSelectBox>
        </Form.Item>
        <Form.Item label="Property type" name="propertyType">
            <BlockCheckboxGroup name="propertyType" options={propertyTypeOptions} />
        </Form.Item >
        <Form.Item label="Bedrooms" name="bedrooms">
            <Radio.Group name="bedrooms">
                <Radio.Button value="">Any</Radio.Button>
                <Radio.Button value="1">1+</Radio.Button>
                <Radio.Button value="2">2+</Radio.Button>
                <Radio.Button value="3">3+</Radio.Button>
                <Radio.Button value="4">4+</Radio.Button>
                <Radio.Button value="5">5+</Radio.Button>
            </Radio.Group>
        </Form.Item >
        <Form.Item label="Bathrooms" name="bathrooms">
            <Radio.Group name="bathrooms">
                <Radio.Button value="">Any</Radio.Button>
                <Radio.Button value="1">1+</Radio.Button>
                <Radio.Button value="2">2+</Radio.Button>
                <Radio.Button value="3">3+</Radio.Button>
                <Radio.Button value="4">4+</Radio.Button>
                <Radio.Button value="5">5+</Radio.Button>
            </Radio.Group>
        </Form.Item >
        <Form.Item label="Garages" name="garages">
            <Radio.Group name="garages">
                <Radio.Button value="">Any</Radio.Button>
                <Radio.Button value="1">1+</Radio.Button>
                <Radio.Button value="2">2+</Radio.Button>
                <Radio.Button value="3">3+</Radio.Button>
                <Radio.Button value="4">4+</Radio.Button>
                <Radio.Button value="5">5+</Radio.Button>
            </Radio.Group>
        </Form.Item >
        {interestPageData.rentOrBuy !== 'rent' ?
            <Form.Item label="Would you like to..." name="homeStage">
                <BlockRadioGroup name="homeStage">
                    <Radio.Button value="offplan">Build off plan</Radio.Button>
                    <Radio.Button value="resale">Buy an existing home</Radio.Button>
                    <Radio.Button value="either">Either</Radio.Button>
                </BlockRadioGroup>
            </Form.Item > : ''}
    </React.Fragment>
}

export default ResponderPropertyInterest;