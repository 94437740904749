import React from "react";
import PropTypes from "prop-types";
import ReactPlayer from "react-player";
import styled from "styled-components";


const createUrlFromJson = (videoData) => {

    const url = videoData.url;

    return url;
};

const createUrlFromString = (videoString) =>{
    return createUrlFromJson(JSON.parse(videoString))
};

const VideoWrapper = styled.div`
  position: relative;
  padding-top: 56.25%; /* Player ratio: 100 / (1280 / 720) */
  .react-player{
      position: absolute;
      top: 0;      
      left: 0;
      right: 0;
      bottom: 0;
  }
`;

const Video = props => {

    const json = props.json;

    let url = props.url;
    if(json){
        url = createUrlFromString(json)
    }

    return <VideoWrapper>
        <ReactPlayer url={url}
                     className='react-player'
                     width='100%'
                     height='100%'/>
    </VideoWrapper>;
};

Video.propTypes = {
    width: PropTypes.oneOfType([PropTypes.number]),
    height: PropTypes.oneOfType([PropTypes.number]),
};

Video.defaultProps = {
    width: 300,
    height: 300,
};

export default Video;