import React, {useContext} from "react";
import {Route, Redirect} from "react-router-dom";
import {AuthContext} from "./AuthProvider";
const PrivateRoute = ({ component:RouteComponent, ...rest }) => {

    const {authenticated, loadingAuthState} = useContext(AuthContext);


    if (loadingAuthState) {
        return (
            <div>
                <h1>Loading...</h1>
            </div>
        );
    }
    // console.log("authenticatedff",authenticated)
    // console.log("loadingAuthStateff",loadingAuthState)
    // console.log("rest",rest)

    return (
        <Route
            {...rest}
            render={routeProps =>{
                console.log("isAuthed",authenticated)

               return authenticated ? (
                    <RouteComponent {...routeProps} />
                ) : (
                   <Route render={() => <Redirect to="/join"/>}/>
                )
            }
            }
        />
    );

};
export default PrivateRoute;