import React from 'react';
import styled from "styled-components";

import Icon from "../../components/icon/Icon";

const getScoreBandFromScore = (scoreBands, score) => {
    let match = null;
    Object.keys(scoreBands).map((keyName) => {

        const range = scoreBands[keyName]
        // console.log("Comparing "+keyName+" "+range[0]+"->"+range[1]+"="+(score>range[0] && score < range[1]) )
        if (score > range[0] && score < range[1]) {
            match = keyName
        }
        return null
    });
    return match;
}

const getInfoFromScoreBand = (scoreBand) => {
    switch (scoreBand) {
        case "excellent":
            return { order: 1, color: "#36CA5F", title: 'Excellent' };
        case "good":
            return { order: 2, color: "#B8CA38", title: 'Good' };
        case "average":
            return { order: 3, color: "#EEB12B", title: 'Average' };
        case "poor":
            return { order: 4, color: "#E65D60", title: 'Poor' };
        default:
            throw Error("Couldn't find info for band " + scoreBand)
    }
};

const ScoreBandContainer = styled.div`
    text-align: left;
`;
const ScoreBandIndicator = styled.span`
    margin-right:2em;
`;
const CreditBandRange = ({ title, scoreBands, ...props }) => {


    let items = [];
    Object.keys(scoreBands).map((keyName, index) => {
        const range = scoreBands[keyName];
        const info = getInfoFromScoreBand(keyName);
        console.log("range ->", keyName, range,info);

        items[info.order] = <ScoreBandIndicator key={index}><Icon color={info.color} icon="circle-filled" style={{ fontSize: '1em' }} /> {range[0]} - {range[1]}</ScoreBandIndicator>        
        return null
    });
    
    return <ScoreBandContainer>
            {title? title :''}
            <div>
                {items.map((item,index)=>item)}
            </div>
           </ScoreBandContainer>
}
export {
    getScoreBandFromScore,
    getInfoFromScoreBand
}
export default CreditBandRange;