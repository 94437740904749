import React, { useEffect, useState, useContext } from 'react';
import styled from "styled-components";
import _ from 'lodash';
import qs from 'qs';

import { NarrowContent } from '../../../theme/DefaultTheme';
import { pathToReservationOnceOffRoute } from '../../../routes/publicRoutes';
import { withRouter, Link } from "react-router-dom";
import { CountdownTicker } from "../../../components/progress/CountdownTicker";

import Amount from "../../../components/display/Amount";
import WizardContent from "../../../components/containers/WizardContent"
import PeachCardForm from "../../../components/payment/PeachCardForm"
import { SecondaryBlockButton} from '../../../components/button/Button';
import {postActionData, fetchPeachPaymentsFormData, fetchPaymentReference } from '../../../services/PropertyPassService';
import { ActionRequestContext } from "../ActionRequestContext";
import OnceOff from "../OnceOff";
import withReservation from './ReservationProvider';
import PaymentSummary from "./PaymentSummary";
import RoadBlock from '../../../components/containers/RoadBlock'
import { updateUnitReservationStep } from '../../../services/EventTriggerService';
import {Spin} from "antd";

export const LoadingPaymentForm = styled.div`
    background: whitesmoke;
    width: 100%;
    min-height: 384px;
    margin-bottom: 20px;
    
    .ant-spin{
        display:block;
        text-align: center;
        padding-top:40%;
        margin: 0 auto;

    }
`;
const ReservationPeachPayment = (props) => {

    const [appContext] = useContext(ActionRequestContext);
    const { arToken, userToken, actionToken, currentAction, currentRecipient} = appContext;

    const [paymentReference, setPaymentReference] = useState(currentAction.paymentReference);

    const [transactionId, setTransactionId] = useState(null);    
    const [peachData, setPeachData] = useState(null)
    const [expiryDate, setExpiryDate] = useState(props.expiryDate);

    
    const backUrl = pathToReservationOnceOffRoute(arToken, userToken, actionToken, "payment")

    let queryParams = qs.parse(props.location.search, { ignoreQueryPrefix: true });
    let forceNew = queryParams.forceNew || false;

    useEffect(()=>{
        if (!(currentAction.termsAccepted && (currentAction.verificationId !== "" || currentAction.verificationId !== undefined))){            
            props.handleInvalidStep();
        }else{
            props.updateLastStep("payment-selected", true);             
        }
        if(paymentReference && arToken){
            fetchPeachData(arToken, actionToken, currentAction.transactionId, forceNew)
        }

        if(!paymentReference){ // only generate a new payment reference if there isn't one already
            // fetch a payment reference to so EFT can be done
            fetchPaymentReference(arToken,actionToken).then(response =>{
                let ref = response.paymentReference
                setPaymentReference(ref)

                console.log("Got response ", response)
                //We have a valid Peach reference number, so pass it with a customer name (that can be configured via input's later if need be)
                return props.postActionDataToServer({paymentReference:ref, customerName:currentRecipient.name}).then(() =>{
                    fetchPeachData(arToken, actionToken, currentAction.transactionId, forceNew)
                })                
            }).catch(err=>{
                console.log(err)
            })
        }


    },[])

    useEffect(() => {
        if (props.expiryDate !== expiryDate){            
            setExpiryDate(props.expiryDate);
        }       
    }, [props.expiryDate])


    const fetchPeachData = (arToken,actionToken, transactionId,forceNew) => {
        fetchPeachPaymentsFormData(arToken, actionToken, transactionId, forceNew ).then(transactionData =>{
            console.log("Got response ", transactionData)
            setTransactionId(transactionData.id)
            setPeachData(transactionData.vendorData.checkoutData)
            return postActionData(arToken, actionToken, {transactionId:transactionData.id});
        }).catch(()=>{
            console.log("error")
        })
    }
    
    const handleTimesUp = () => {
        props.setExpiryDate(false);        
    }

    const shopperResultUrl = process.env.REACT_APP_SERVER_ROOT+ pathToReservationOnceOffRoute(arToken, userToken, actionToken,"payment-processing/"+transactionId)    


    return (
        <OnceOff>
            <RoadBlock blocked={appContext.blocked}>         
                <WizardContent
                    activeStep={5} totalSteps={6} title="Enter Card Details" info="Please enter card details to reserve your unit"
                    afterHeader={(expiryDate)?<CountdownTicker expiryDate={expiryDate} handleTimesUp={handleTimesUp}/>:null}
                >
                    <NarrowContent>
                        <PaymentSummary action={currentAction}/>
                                                
                        {peachData ? <PeachCardForm checkoutId={peachData.id} resultUrl={shopperResultUrl}/> :  <LoadingPaymentForm><Spin tip="Initialising payment..." /></LoadingPaymentForm>}
                        <SecondaryBlockButton>
                            <Link to={backUrl}>Back</Link>                            
                        </SecondaryBlockButton>
                    </NarrowContent>
                </WizardContent>
            </RoadBlock>


        </OnceOff>
    )
}

export default withRouter(withReservation(ReservationPeachPayment))