import React, { useState, useEffect } from 'react';
import styled from "styled-components";
import { Select } from 'formik-antd'
import { Tabs, Layout} from 'antd';
import _ from 'lodash';
import DemoPresets from './DemoPresets';
import DemoOutput from './DemoOutput';
import {fetchPresets} from '../../services/PropertyPassService';
import find from 'lodash/find';

const { Content } = Layout;

const DemoContainer = styled(Layout)`
    padding: 4em;
    background: white;
    form .ant-form-item-label{
        padding-bottom: 0;
    }
`;

const Demo = () => {

    const [generate, setGenerate] = useState(false)
    const [data, setData] = useState({})
    const [presetList, setPresetList] = useState([]);

    useEffect(() => {
        fetchPresets().then(responseData => {    
            let presetData = [];
            console.log("PRESET SET?!@# presetData", presetData)
            Object.keys(responseData).forEach(function(key, val){                
                presetData.push({code:key, label:responseData[key].presetLabel, values:responseData[key]})
            })        
            console.log("presetData", presetData)                
            setPresetList(presetData);
            //console.log(responseData)
        });
    }, [])

    const onGenerate = (values) => {            
        console.log("onGenerate", values)
        const matchingPreset = find(presetList,(item)=>{
            return item.code === values.presetId
        });

        console.log("matchingPreset", matchingPreset)
        if (matchingPreset !== undefined){
            console.log(matchingPreset)
            setData(matchingPreset.values)
            setGenerate(true) 
        }
        //setData(values) 
               
    }

    const handleBack = () => {
        setGenerate(false)
    }
    
    return <DemoContainer>
        <Content style={{ overflow: 'scroll' }}>
            {generate ? 
                <DemoOutput data={data} handleBack={handleBack}/>:
                <DemoPresets presetList={presetList} onGenerate={onGenerate}/>
                }
        </Content>
    </DemoContainer>
}

export default Demo;
