import React, {useContext, useEffect, useState} from 'react';
import {Button} from "antd";
import {Link, useHistory} from "react-router-dom";
import {SimpleDate} from '@bit/cleaver.propertyengine.date';

import firebase from "firebase/app";
import 'firebase/auth';
import {UserContext, propertyPassFb} from "../../MainRouter";
import UserContainer from "../../components/containers/UserContainer";
import {
    fetchLaunchInfo,
    fetchPublicUserLaunchData,
    fetchPublicUserLaunchList,
    getDocDataList
} from "../../services/PropertyPassService";
import {AuthContext} from "../auth/AuthProvider";
import List from "antd/es/list";
import Icon from "../../components/icon/Icon";
import Image from "../../components/image/Image";
import {getLinkToLaunchWelcome, PreLaunchTimer} from "../launch/PreLaunch";
import Countdown from "antd/es/statistic/Countdown";
import moment from "moment";
import {AccountContent, NarrowContent} from "../../theme/DefaultTheme";
import Paragraph from "antd/es/skeleton/Paragraph";
import Text from "antd/es/typography/Text";

export default function  Dashboard() {
    const {user} = useContext(AuthContext);

    const [applications] = useState([]);
    const [loadingLaunches,setLoadingLaunches] = useState(true);
    const [launches,setLaunches] = useState([]);

    const history = useHistory();


    console.log('user',user)
    useEffect(()=>{
        if(user){
            fetchPublicUserLaunchList(user.uid).then(launchDocs=>{
                const promiseList = []
                launchDocs.forEach(launchDoc =>{
                    promiseList.push(fetchLaunchInfo(launchDoc.id))
                })

                return Promise.all(promiseList)
            }).then(results =>{
                setLaunches(getDocDataList(results))
                setLoadingLaunches(false)
            })
        }

    },[user])



    return (
       <UserContainer>
            <AccountContent>
           <h2>Launches you've subscribed to</h2>
           <List
               style={{background:"#FFFFFF"}}
               split={true}
               itemLayout="horizontal"
               bordered={true}
               loading={loadingLaunches}
               dataSource={launches}
               renderItem={item => (
                   <List.Item  style={{textAlign:'left'}} onClick={()=>{history.push(getLinkToLaunchWelcome(item.entityId,item.id,item.slug))}}
                               extra={
                                   <Link to={getLinkToLaunchWelcome(item.entityId,item.id,item.slug)}>
                                       <Image json={item.primaryBrandLogo} height={170} width={170} fit="clip"/>
                                   </Link>

                               }
                   >

                       <List.Item.Meta

                           title={item.preLaunchTitle}
                           description={<Text>
                               {item.preLaunchInfo}<br/>
                                <strong><SimpleDate date={item.launchDate}/></strong>

                           </Text>
                               }
                       />
                   </List.Item>
               )}
           />
            </AccountContent>
       </UserContainer>
    );

}


