import React from 'react';
import Modal from "antd/es/modal";
import DisplayTextArea from "../../components/display/DisplayTextArea";

const RewardModal = ({incentive,visible,handleSave,handleCancel,...props}) => {

    let description = ""
    let terms = incentive.termsAndConditions

    if(incentive.type==='early-access'){
        description = incentive.incentiveDescription
    }else if(incentive.type==='discount'){
        description = incentive.discount.externalDescription
    }

    return <Modal
        title={"Reward details"}
        visible={visible}
        onCancel={handleCancel}
        footer={null}
    >
        <h4>You've gained the following reward</h4>
        <p>{description}</p>
        {terms?<React.Fragment>
            <h4>Terms & Conditions</h4>
            <DisplayTextArea text={terms}/>
        </React.Fragment>:""}
    </Modal>
}

export default RewardModal;