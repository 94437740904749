import React from 'react';

import { ResponsivePie } from '@nivo/pie'
import styled from "styled-components";
import {getInfoFromScoreBand} from "./CreditBandRange";


const CreditContainer = styled.div`
    position: relative;
`;

const CreditTitle = styled.div`
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    div{
        padding-top: 1em;
    }

    h2{
        font-size: 2em;
        line-height: 1em;
        margin-bottom: 0;
    }

    h3{
        font-size: 1.6em;
        line-height: 1em;
        margin-bottom: 0;
        font-weight: bold;
    }
`;



const CreditScorePie = ({scoreBand, goodPoints, totalPoints, ...props}) => {

    console.log("scoreBand",scoreBand)
    console.log("props",props)

    const badPoints  = totalPoints - goodPoints;

    const getColor = (slice) => {
        console.log("Checking color ",slice)
        return getInfoFromScoreBand(slice.id).color
    }

    const data = [
        
        {
          "id": "poor",
          "label": "Negative Credit Points",
          "value": badPoints
        },
        {
            "id": "excellent",
            "label": "Positive Credit Points",
            "value": goodPoints
        }
                
      ]

    const info = getInfoFromScoreBand(scoreBand)
      

    return <CreditContainer style={props.style}>
                <CreditTitle>
                    <div>
                        <h2>{goodPoints}</h2>
                        {info?<h3 style={{color:info.color}}>{info.title}</h3>:''} 
                    </div>
                </CreditTitle>
                <ResponsivePie
                data={data}
                margin={{ top: 20, right: 30, bottom: 20, left: 30 }}
                startAngle={130}
                endAngle={-135}
                innerRadius={0.75}
                enableRadialLabels={false}
                enableSlicesLabels={false}
                colors={getColor}
                // colors={{ scheme: 'nivo' }}
                borderWidth={1}
                borderColor={{ from: 'color', modifiers: [ [ 'darker', 0.2 ] ] }}
            
                animate={true}
                motionStiffness={90}
                motionDamping={15}
                
            />
        </CreditContainer>
}

export default CreditScorePie;