import React from 'react';
import { Form, Input } from 'formik-antd'
import {BlockRadioGroup} from './Responder'

const ResponderContact = () => {

    return <React.Fragment>
        <Form.Item label="Message to the agent" name="message">
            <Input.TextArea name="message" placeholder="I'm looking for..." />
        </Form.Item >
    </React.Fragment >

}

export default ResponderContact;