import React, { } from 'react';
import { withRouter } from "react-router-dom";
import { TextContent } from '../../theme/DefaultTheme';
import { propertyPassFb } from "../../MainRouter";
import OnceOff from "./OnceOff.js";
import OnceOffPageContent from '../../components/containers/OnceOffPageContent';
import Icon from "../../components/icon/Icon";

const VERIFICATION_FAILURE = 'verification-failure';
const ACTION_REQUEST_EXPIRED = 'action-request-expired';
const ACTION_REQUEST_COMPLETED = 'action-request-completed';



const errorOptions = {}
errorOptions[VERIFICATION_FAILURE] = {
    icon: "warning",
    color: "red",
    title: "Unabled to verify you",
    description: "Some of the security questions we asked of you were answered incorrectly and we can't be sure that you are who you say you are. Please contact your agent manually authenticate yourself"
}
errorOptions[ACTION_REQUEST_EXPIRED] = {
    icon: "warning",
    color: "red",
    title: "This link has expired",
    description: "The agent has set a time limit on you being able to complete this action and that time has expired. Please ask them to send you another one"
}
errorOptions[ACTION_REQUEST_COMPLETED] = {
    icon: "checkmark-circle",
    color: "green",
    title: "This action has already been completed",
    description: "It looks like you have already completed this action and there is no need to do anything more. You can close this window."
}

const OnceOffError = (props) => {

    const params = props.match.params

    const reason = params.reason

    

    const errorInfo = errorOptions[reason];
    const title = errorInfo.title;


    propertyPassFb.analytics().logEvent('error--once-off', {});


    return <OnceOff>
        <OnceOffPageContent>
            <TextContent>
                {errorInfo.icon ? <Icon icon={errorInfo.icon} color={errorInfo.color} style={{fontSize: '4em', marginBottom:'1rem' }} /> : ''}
                
                <h2>{title}</h2>
                <p>{errorInfo.description}</p>
            </TextContent>
        </OnceOffPageContent>
    </OnceOff>
}

export default withRouter(OnceOffError);