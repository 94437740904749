import React from 'react';
import { Spin } from 'antd';

import styled from "styled-components";

const Loading = styled.div`

  position: fixed;
  z-index: 999;
  height: 2em;
  width: 2em;
  overflow: show;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;

`;

const LoadingSpin = styled(Spin)`
    position:absolute;
    top: calc(50% - 2em);
`


const MainPageLoader = () => {

    return <Loading>
            <LoadingSpin size="large" tip="Loading..."/>
        </Loading>
}
export default MainPageLoader
