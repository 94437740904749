import React, { useEffect, useState, useContext } from 'react';
import styled from "styled-components";
import axios from 'axios';
import { TextContent } from '../../../theme/DefaultTheme';
import Icon from "../../../components/icon/Icon";

import MainPageLoader from '../../../components/loading/MainPageLoader';

import { NarrowForm } from '../../../theme/DefaultTheme';
import { Link, withRouter } from "react-router-dom";

import { Formik } from 'formik'
import {Checkbox, Radio, Select } from 'formik-antd'
import WizardContent from "../../../components/containers/WizardContent";
import { PrimaryBlockButton, SecondaryBlockButton } from '../../../components/button/Button';
import { pathToResponderOnceOffRoute} from '../../../routes/publicRoutes';
import { ActionRequestContext } from "../ActionRequestContext";
import OnceOff from "../OnceOff";
import { postActionData } from '../../../services/PropertyPassService';
import { useTracked} from "../../../MainRouter"
import { updateStep, updateState, inCompletedState } from '../../../services/EventTriggerService'
import { fetchDevelopmentInformationMap } from '../../../services/PropertyPassService'

import ResponderContact from "./ResponderContact"
import ResponderInterest from "./ResponderInterest"
import ResponderPropertyInterest from "./ResponderPropertyInterest"
import ResponderPriceBracket from "./ResponderPriceBracket"
import ResponderMarketingEnquiry from "./ResponderMarketingEnquiry"
import ResponderInformation from "./ResponderInformation"
import ResponderPersonalFinance from "./ResponderPersonalFinance"

const INTEREST = "interest";
const PROPERTY_INTEREST = "property-interest";
const PRICE_BRACKET = "price-bracket";
const PERSONAL_FINANCIAL = "personal-financial";
const SERVICE_PROVIDER = "service-provider";
const INFORMATION = "information";
const CONTACT = "contact";
const SUCCESS = "success";

const pages = {
    "welcome":{
        title:"Welcome",
        description:"Introduction to the responder...",
        pages:["welcome"],
        recordSteps:false
    },
    "move-now": {
        title: "Let's get on with it then!",
        description: "Fastest way to moving into your new home is answering our questions below",
        pages: [INTEREST, PROPERTY_INTEREST, PRICE_BRACKET, PERSONAL_FINANCIAL, SUCCESS]
    },
    "browsing": {
        title: "Ok, so you are seeing what's out there!",
        description: "No problem. Help us help you by telling us what you are looking for",
        pages: [INTEREST, PROPERTY_INTEREST, PRICE_BRACKET, PERSONAL_FINANCIAL, SUCCESS]
    },
    "more-info": {
        title: "More development details",
        description: "You can find more detailed information on our developments below",
        pages: [INFORMATION, CONTACT, SUCCESS],
        recordSteps:false
    },
    "service-offering": {
        title: "We need more information",
        description: "The information below will help us refer you to the correct department",
        pages: [SERVICE_PROVIDER, SUCCESS]
    }
}

const pageContent = {};
pageContent[CONTACT] = { title: "Sorry we didn't have what you need", description: "Fill in the form below and tell us exactly what you are looking for" }

// const UserAvatar  = styled(Avatar)`
//     margin-bottom: ${props => props.theme.defaultBlockMargin};
// `;

const BlockCheckboxGroup = styled(Checkbox.Group)`
&&&{
    label{
       width: 40%;
    }   
  
}    
`;

const MultipleSelectBox = styled(Select)`
    max-width: 320px;
    @media only screen and (min-width: 970px) {
        max-width: 100%;
    } 
`

      
const SelectTwoInRow = styled(Select)`
    &&&{
        margin-bottom: 5px;
    }
    @media only screen and (min-width: 970px) {
        margin-bottom: 0px;
    }
`;

const BlockRadioGroup = styled(Radio.Group)`
      label{
       display:block;
       margin-bottom: 5px;
    }
    @media only screen and (min-width: 970px) {
        label{
            display:inline-block;
            margin-bottom: 0px;
        }
    }
`;

const ResponderWrapper = (wrapperProps) => {
    //console.log("ResponderWrapper wrapperProps.pageData", wrapperProps.pageData);    
    const [startState, setStartState] = useState(wrapperProps.pageData[wrapperProps.code]||{});    
    //console.log("ResponderWrapper ", wrapperProps.code, startState);
    //console.log("wrapperProps.onPageBack  ==> ", wrapperProps.pageIndex);

    useEffect(()=>{
        setStartState(wrapperProps.pageData[wrapperProps.code]||{})
    },[wrapperProps])

    //const url = pathToResponderOnceOffRoute(arToken, userToken, actionToken, "welcome");
    const title = pageContent[wrapperProps.code] ? pageContent[wrapperProps.code].title : wrapperProps.title
    const description = pageContent[wrapperProps.code] ? pageContent[wrapperProps.code].description : wrapperProps.description
    return <Formik
        enableReinitialize={true}
        initialValues={startState}
        validationSchema={wrapperProps.schema}
        onSubmit={(values, {setSubmitting, setErrors, setStatus, resetForm}) => {
            // move this up add a minor natural delay,
            setTimeout(() => {
                wrapperProps.onPageComplete(wrapperProps.pageIndex, values).then(() =>{
                    resetForm({})
                })
            }, 250)
            
           
            
        }}
        render={(formProps) => (
            <WizardContent activeStep={wrapperProps.pageIndex + 1} totalSteps={wrapperProps.totalPages} title={title} info={description}>
                <NarrowForm layout='vertical' size='large' onSubmit={formProps.handleSubmit}>
                    {wrapperProps.form({ pageProps: wrapperProps, formProps: formProps })}
                    {wrapperProps.hideNextButton ? '' : <PrimaryBlockButton htmlType="submit" loading={formProps.isSubmitting}>{wrapperProps.nextText ? wrapperProps.nextText : 'Next'}</PrimaryBlockButton>}
                    {wrapperProps.pageIndex === 0 || wrapperProps.onPageBack === undefined ? '' : <SecondaryBlockButton onClick={() => wrapperProps.onPageBack(wrapperProps.pageIndex)} >{wrapperProps.backText ? wrapperProps.backText : 'Back'}</SecondaryBlockButton>}
                    {wrapperProps.pageIndex === 0 ? <SecondaryBlockButton><Link to={"welcome"} onClick={() => wrapperProps.onPageBack(-1)}>Back</Link></SecondaryBlockButton>: ""}
                    
                </NarrowForm>
            </WizardContent>
        )}
    />
};

const Success = (props) => {
    return <WizardContent activeStep={props.pageIndex + 1} totalSteps={props.totalPages} >
            <TextContent style={{paddingTop:40,paddingBottom:40}}>
                <Icon icon="checkmark-circle" color="green" style={{ fontSize: '4em', marginBottom: '1rem' }} />
                <h2>{props.title}</h2>
                <p>{props.description}</p>
            </TextContent>
        </WizardContent>
}

let timeout;
let currentValue;
const fetchLocation = (value, callback) => {
    // 

    currentValue = value;

    function fake() {

        axios.get(`https://www.propertyengine.com/reseller/location/searchPublicGlobal?q=${value}`)
            .then(response => {
                // console.log('response',response.data)

                if (currentValue === value) {
                    const data = [];
                    response.data.forEach(r => {
                        data.push({
                            value: r.label,
                            text: r.label,
                        });
                    });
                    //   console.log('data',data)
                    callback(data);
                }
            });
    }

    timeout = setTimeout(fake, 300);
}




const Responder = (props) => {
    
    const params = props.match.params;
    const [messengerState, messengerDispatch] = useTracked();    
    const [appContext] = useContext(ActionRequestContext);
    const [] = useState({});    
    const { arToken, userToken, actionToken, currentAction, currentRecipient} = appContext;
       
    const [pageData, setPageData] = useState(currentAction);
    const [startOption, setStartOption] = useState(params.option);
    const [currentPageIndex, setCurrentPageIndex] = useState(0);
    const [developmentInformationMap, setDevelopmentInformationMap] = useState([])
    const pageInfo = pages[startOption];

    const title = pageInfo.title;
    const description = pageInfo.description;
    const pagesToDisplay = pageInfo.pages;
    let currentPageCode = appContext.currentAction?pagesToDisplay[currentPageIndex]:null;    


    let currentPage = "";
    

    useEffect(() =>{        
        if (messengerState.currentActionInit === true && appContext.currentAction !== undefined){            
            setPageData(appContext.currentAction)            
            messengerDispatch({ type:"setGlobal", var:"currentActionInit", value:true});
            currentPageCode = pagesToDisplay[currentPageIndex];     
        } 
    }, [])

    useEffect(() =>{                         
        if (!messengerState.currentActionInit && appContext.currentAction !== undefined){                    
            setPageData(appContext.currentAction)                        
            messengerDispatch({ type:"setState", state:appContext.currentAction.state});
            messengerDispatch({ type:"setStep", step:appContext.currentAction.step});
            currentPageCode = pagesToDisplay[currentPageIndex];     
            //Not sure if we must fetch it here, but this is just for testing
            console.log("appContext.actionData", appContext.actionData)
            let developmentList = appContext.currentAction.developmentsOfInterest
            if (developmentList && developmentList.length > 0){
                fetchDevelopmentInformationMap(developmentList).then(data => {
                    console.log("DATA", data)
                    let developmentInfoMap = {}
                    data.forEach( item => {
                        console.log("ITEM?!@#", item)
                        developmentInfoMap[item.key] = item;
                    })
                    console.log("developmentInfoMap", developmentInfoMap)
                    setDevelopmentInformationMap(developmentInfoMap);
                    messengerDispatch({ type:"setGlobal", var:"currentActionInit", value:true});
                });
            }else{
                messengerDispatch({ type:"setGlobal", var:"currentActionInit", value:true});
            }
            
        }        
    }, [appContext])

    useEffect(() => {              
        //Responder has a unique situation where more-information can be viewed after completing a form, but nothing else can.
        //Ideally we want this to be smarter and be based on the actions, but for now we are just making it work
        //console.log("startOption", startOption)
        //console.log("messengerState.actionState", messengerState.actionState)        
        //Moved this code down into the render section
    }, [pageData, currentPageIndex]);          
          

    useEffect(() => { 
        console.log("USER EFFECEER!@#")
        //We don't want to track more information        
        if (pageInfo.recordSteps === false){                        
            return
        }        
        if (messengerState.currentActionInit === true && !inCompletedState(messengerState.actionState)){            
            console.log("============== WE HAVE A CHANGE ============", startOption, currentPageCode, currentPageIndex, messengerState.actionState)                      
            
        }
        if (appContext.currentAction !== undefined ){                   
            let previousState = messengerState.actionState?messengerState.actionState:appContext.currentAction.state
            let previousStep = messengerState.step?messengerState.step:appContext.currentAction.step
            let currentState = getResponderState(currentPageIndex);
            let currentStep = currentPageCode;
            //console.log("==============previousState", previousState, "previousStep", previousStep, "currentState", currentState, "currentStep", currentStep)
            let eventData = {};
            let stepPromise = updateStep(pagesToDisplay, previousStep, currentStep).then((value) =>{                
                eventData.step = value.step;
                eventData.awsTrigger = true;
                eventData.type = "responder";
                eventData.entireDataSet = true;
                messengerDispatch({ type:"setStep", step:value.step});
                return eventData;
            }).catch(error => {
                return null
            })

            let statePromise = updateState(previousState, currentState).then((value) =>{                
                eventData.state = value.state;
                eventData.awsTrigger = true;
                eventData.type = "responder";
                eventData.entireDataSet = true;
                messengerDispatch({ type:"setState", state:value.state});
                return eventData;
            }).catch(error => {
                return null
            })

            Promise.all([stepPromise, statePromise]).then((values) => {
                console.log("VALUE LIST!!", values);                                                   
            }).finally( () => {            
                if (Object.keys(eventData).length > 0){                    
                    postActionData(arToken, actionToken, eventData);                    
                }                                                
            })        
        }
        
        //messengerDispatch({type:"", })
    }, [startOption, currentPageCode])

    const getResponderState = (currentPageIndex) => {
        let state = "viewed";
        if(currentPageIndex > 0) {
            state = "started";
        }
        if(currentPageIndex === (pagesToDisplay.length-1)){
            state = "completed";
        }
        return state
    }

    const handlePageComplete = (pageIndex, values) => {
        //Do it asyc        
        console.log("we are here,.. messengerState", messengerState)
        let nextPageIndex = pageIndex + 1;
        setCurrentPageIndex(nextPageIndex)

        // Set the local state with the new values
        setPageData((pageData) => {
            pageData[currentPageCode] = values;
            return pageData;
        })
        return postActionData(arToken, actionToken, pageData).then(()=>{
            console.log("Write complete!!!!")
        })  
               
        /*
        pageData.step = getLastStep(pagesToDisplay,appContext.currentAction.step,currentPageCode);
        pageData.state =  getLatestState(appContext.currentAction.state,getResponderState(nextPageIndex))
        */
        
        

    }    

    const handlePageBack = (pageIndex) => {  
        if (pageIndex === -1){
            setStartOption("welcome");
        }else{
            setCurrentPageIndex(pageIndex - 1)
        }
        
    }

    const Welcome = (props) => {
        let actionButtons = [];
        const nextUrl = pathToResponderOnceOffRoute(arToken, userToken, actionToken, "")
        const subActions = currentAction.subActions;     
        let actionText = "What best describes the reason for your enquiry?"
        if (inCompletedState(currentAction.state)){
            actionText = "Thank you for completing the form, we will get back to in due course"
            if (subActions === undefined ||  subActions.indexOf("more-info") > -1){
                actionButtons.push({label:"I want more information on something",url:nextUrl+'more-info', 'start':'more-info'});
            }                                                                
        }else{                    
            //there must be a better way to do this
            if (subActions === undefined || subActions.indexOf("move-now") > -1){ actionButtons.push({label:"I need a new home now",url:nextUrl+'move-now', start:'move-now'}); }
            if (subActions === undefined || subActions.indexOf("browsing") > -1){ actionButtons.push({label:"I'm seeing what's out there",url:nextUrl+'browsing', start:'browsing'}); }
            if (subActions === undefined || subActions.indexOf("more-info") > -1){ actionButtons.push({label:"I want more information on something",url:nextUrl+'more-info', start:'more-info'}); }
            if (subActions === undefined || subActions.indexOf("service-offering") > -1){ actionButtons.push({label:"I've got a service to offer",url:nextUrl+'service-offering', start:'service-offering'}); }
        }
        return <React.Fragment>
            
            <WizardContent activeStep={1} totalSteps={1} title={title} info={actionText}>                
                {actionButtons.map((actionButton,index)=>{
                    //return <PrimaryBlockButton key={index} style={{marginBottom:"1em"}} onClick={() => setStartOption(actionButton.start)}>{actionButton.label}</PrimaryBlockButton> 
                    return <SecondaryBlockButton key={index} style={{marginBottom:"1em"}}><Link to={actionButton.url} onClick={() => setStartOption(actionButton.start)}>{actionButton.label}</Link></SecondaryBlockButton>
                })}   
            </WizardContent>            
        </React.Fragment>
    }

    let wrapperProps = {
        code:currentPageCode,
        title:title,
        description:description,
        pageIndex:currentPageIndex,
        totalPages:pagesToDisplay.length,
        pageData:appContext.currentAction,
        onPageComplete:handlePageComplete, 
        onPageBack:handlePageBack
    }
    
    //move-now -- interest -> property-interest -> price-bracket -> personal-financial -> success
    //Browsing -- 
    //more-info --  information -> contact
    //service-offering -- service-provider    
    if (!messengerState.currentActionInit){
        return <OnceOff><MainPageLoader/></OnceOff>
    }else if (startOption === "welcome"){
        //Welcome page
        currentPage = <Welcome></Welcome>
    }else if (inCompletedState(messengerState.actionState) && startOption !== "more-info") {
        currentPage = <Success title={"Thank you"} description={"We appreciate you taking the time to let us know this information and we will make sure it ends up with the right people"} pageIndex={pagesToDisplay.length-1} totalPages={pagesToDisplay.length} pageData={pageData} onPageComplete={handlePageComplete} />
    }
    else if (currentPageCode === INTEREST) {
        currentPage = <ResponderWrapper form={(props) => <ResponderInterest {...props} />} {...wrapperProps}/>
        //currentPage = <ResponderWrapper form={(props) => <PriceBracketForm {...props} />} {...wrapperProps}/>
    } else if (currentPageCode === PROPERTY_INTEREST) {
        currentPage = <ResponderWrapper form={(props) => <ResponderPropertyInterest {...props} />} {...wrapperProps}/>
    } else if (currentPageCode === PRICE_BRACKET) {
        currentPage = <ResponderWrapper form={(props) => <ResponderPriceBracket {...props} />} {...wrapperProps} />
    } else if (currentPageCode === SERVICE_PROVIDER) {
        currentPage = <ResponderWrapper form={(props) => <ResponderMarketingEnquiry {...props} />} {...wrapperProps} />
    } else if (currentPageCode === INFORMATION) {
        currentPage = <ResponderWrapper form={(props) => <ResponderInformation {...props} developmentInformationMap={developmentInformationMap} />} hideNextButton={true} {...wrapperProps}  />
    } else if (currentPageCode === CONTACT) {
        currentPage = <ResponderWrapper form={(props) => <ResponderContact {...props} />} nextText="Submit" {...wrapperProps}/>
    } else if (currentPageCode === PERSONAL_FINANCIAL) {
        currentPage = <ResponderWrapper form={(props) => <ResponderPersonalFinance {...props} />} {...wrapperProps} />
    } else if (currentPageCode === SUCCESS) {
        currentPage = <Success title={"Thank you"} description={"We appreciate you taking the time to let us know this information and we will make sure it ends up with the right people"} pageIndex={currentPageIndex} totalPages={pagesToDisplay.length} pageData={pageData} onPageComplete={handlePageComplete} />
    }

    return <OnceOff>{currentPage}</OnceOff>
}

export default withRouter(Responder)

export {
    BlockRadioGroup,
    MultipleSelectBox,
    BlockCheckboxGroup,
    SelectTwoInRow,
    fetchLocation
}