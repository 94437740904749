import React, {useContext, useState, useEffect} from "react";
import { useTracked} from "../../../MainRouter"
import { inCompletedState, updateState, updateUnitReservationStep, getUnitReservationStepProgress } from '../../../services/EventTriggerService'
import { convertTimestampToDate } from "../../../components/progress/CountdownTicker";
import { ActionRequestContext } from "../ActionRequestContext";
import OnceOffRoadBlock from "../../../components/roadBlocks/OnceOffRoadBlock"
import OnceOff from "../OnceOff";
import moment from 'moment';

import styled from "styled-components";
import { Skeleton} from 'antd';
import { postActionData } from "../../../services/PropertyPassService";

const PaddedSkeleton  = styled(Skeleton)`
    padding: ${props => props.theme.skeleton.padding};
`;


const Timeout = (props) => {
    return <OnceOffRoadBlock icon="alarm-error" 
                             heading="This session has expired" 
                             description="You took too long to complete and the unit has been released"
                             actions={props.actions}
                             />
}

const InvalidStep = (props) => {
    return <OnceOffRoadBlock icon="warning" 
                             color="red"
                             heading="Invalid step" 
                             description={props.description?props.description:"It seems like you have skipped a step, please try again"}
                             actions={props.actions}
                             />
}

const AdminCancellation = (props) => {
    return <OnceOffRoadBlock icon="warning" 
                             color="red"
                             heading={props.heading?props.heading:"Admin cancellation"}
                             description={props.description?props.description:"It seems this unit is no longer available, please contact your agent to find out more information."}
                             actions={props.actions}
                             />
}

const AlreadyCompleted = (props) => {
    return <OnceOffRoadBlock icon="checkmark-circle" 
                             heading="Already completed" 
                             description="This action can only be done once and you've already completed it"
                             actions={props.actions}
                             />
}


const withReservation = (WrappedComponent) => (props) => {      
    const [appContext, setAppContext] = useContext(ActionRequestContext);       
    const [messengerState, messengerDispatch] = useTracked();
    const [expiryDate, setExpiryDate] = useState(appContext.currentAction?appContext.currentAction.expiryDate:undefined);   

    useEffect(() => {        
        //First thing first,     
        if (appContext.currentAction){
            //console.log(appContext.currentAction.expiryDate)
        }                  
    }, [])

    useEffect(() => {        
        if (appContext.currentAction && (appContext.currentAction.unitKey || appContext.currentAction.unitLabel)){                     
            /* Data sent back
                "unitKey": "ism-hartland-phase-1a-491",
                "arToken" :"Ww8SHBbjqSVzUDnDQgia"
            */                
            if (!messengerState.currentActionInit){                
                if (inCompletedState(appContext.currentAction.state)){                    
                    if (appContext.currentAction.state === "admin-cancelled"){                        
                        setAppContext(appContext => ({...appContext, blocked:{active:true, displayComponent:<AdminCancellation actions={appContext.currentAction.returnPaths} heading={appContext.currentAction.cancelledHeading} description={appContext.currentAction.cancelledDescription}/>}}))                                            
                    }if (appContext.currentAction.state === "user-cancelled"){                        
                        setAppContext(appContext => ({...appContext, blocked:{active:true, displayComponent:<AdminCancellation actions={appContext.currentAction.returnPaths} heading={"User Cancellation"} description={"This was cancelled by the user"}/>}}))                                            
                    }else if (appContext.currentAction.state === "expired"){
                        setAppContext(appContext => ({...appContext, blocked:{active:true, displayComponent:<Timeout actions={appContext.currentAction.returnPaths}/>}}))                                                            
                    }else{
                        setAppContext(appContext => ({...appContext, blocked:{active:true, displayComponent:<AlreadyCompleted actions={appContext.currentAction.returnPaths}/>}}))                    
                    }
                    
                }                        
                if (!inCompletedState(appContext.currentAction.state) && moment(convertTimestampToDate(appContext.currentAction.expiryDate)).isBefore(new Date())){                                        
                    setAppContext(appContext => ({...appContext, blocked:{active:true, displayComponent:<Timeout actions={appContext.currentAction.returnPaths}/>}}))                                                        
                } 
                         
                //Setting the currentActionState up                
                if (appContext.currentAction.step === undefined && messengerState.step === undefined){
                    //first time (as in the user has hit this page for the first time ever) 
                    updateLastStep("started", true); 
                }
                //mark as viewed (how do we use the constant value?)      
                console.log("Setting state on LOAD..", appContext.currentAction.state)              
                if (appContext.currentAction.state === "created" || appContext.currentAction.state === undefined || appContext.currentAction.state === ""){
                    markStarted();      
                    messengerDispatch({ type:"setStep", step:"started"});
                    messengerDispatch({ type:"setState", state:"viewed"});        
                }else{
                    console.log("we are here, this should trigger dispatch...")
                    updateActionState(appContext.currentAction.state); 
                    if (appContext.currentAction.step !== undefined){
                        let progress = getUnitReservationStepProgress(appContext.currentAction.step);
                        messengerDispatch({ type:"setStep", step:appContext.currentAction.step, progress:progress});
                    }                         
                }   
                
                messengerDispatch({ type:"setGlobal", var:"currentActionInit", value:true});
                messengerDispatch({ type:"subscribeToChannel", channelName:"unitReservation", channelBind:"unitUpdates", channelBindFunction:(data) => {UnitCommands(data, appContext.arToken, appContext.actionToken, appContext.currentAction)}});                           
                setExpiryDate(appContext.currentAction.expiryDate);
            }                           
        }else if (appContext.currentAction && !(appContext.currentAction.unitKey || appContext.currentAction.unitLabel)){                         
            messengerDispatch({ type:"setGlobal", var:"currentActionInit", value:true});      
            setAppContext(appContext => ({...appContext, soldOut:true, blocked:{active:true, displayComponent:<InvalidStep description={"An invalid unit has been supplied, please contact your agent."} />}})) 
        }
        
    }, [appContext.currentAction])

    const handleInvalidStep = () => {        
        setAppContext(appContext => ({...appContext, blocked:{active:true, displayComponent:<InvalidStep description={"It seems like you have skipped a step, please go back and try again"}/>}}))      
    };

    const handleSoldOut = () => {
        setAppContext(appContext => ({...appContext, blocked:{active:true, displayComponent:<InvalidStep description={"It seems this unit is already marked as sold out"}/>}}))      
    };
        
    const updateExpiryDate = (newExpiryDate) => {        
        if (newExpiryDate === false){ //this means time is up        
            setAppContext(appContext => ({...appContext, blocked:{active:true, displayComponent:<Timeout actions={appContext.currentAction.returnPaths}/>}}))
            
        }else{            
            //setAppContext(appContext => ({...appContext, expiryDate:parseInt(newExpiryDate)}))
            updateCurrentAction({expiryDate:parseInt(newExpiryDate)});
            //setExpiryDate(parseInt(newExpiryDate))
        }                
    }

    //I couldn't call this "postActionData" because that is already a reserved function for actually posting the data.

    const postActionDataToServer = (data) => {
        console.log("postActionDataToServer ", data, "messengerState.userProgress", messengerState);
        let completePayload = data;
        if (messengerState.userProgress === true){
            //messengerDispatch({ type:"setProgress", value:false}); //Saving SYNC is causing so many problems with this. We aren't waiting.
            //This means the user has progressed one way or another, we should update the server.            
            if (completePayload.state === undefined && appContext.currentAction.state !== messengerState.actionState){                      
                completePayload.state = messengerState.actionState;
                completePayload.awsTrigger = true;
            }
            if (completePayload.step === undefined && appContext.currentAction.step !== messengerState.step){
                if (messengerState.progress != undefined){
                    completePayload.progress = messengerState.progress
                }else{
                    completePayload.progress = getUnitReservationStepProgress(messengerState.progress);
                }      
                completePayload.step = messengerState.step;
                completePayload.awsTrigger = true;
                completePayload.type = "reservation";
            }
            
            console.log("extra data in the payload ", completePayload)
        }
        return postActionData(appContext.arToken, appContext.actionToken, completePayload).then(responseData =>{
            console.log("postCompleted", responseData)            
            updateCurrentAction(data);
            return data
        })
    }

    const updateLastStep = (nextStep, forceSave) => {        
        let previousStep = messengerState.step?messengerState.step:appContext.currentAction.step;
        console.log("updateLastStep,  ", nextStep, forceSave, previousStep, "messengerState.step", messengerState.step, "appContext.currentAction.step", appContext.currentAction.step)        
        return updateUnitReservationStep(previousStep, nextStep).then((data)=>{
            console.log("UPDATE ACTION STEP", data)
            let progress = getUnitReservationStepProgress(nextStep);
            console.log("PROGRESS!!", progress)
            messengerDispatch({ type:"setStep", step:nextStep, hasProgressed:true, progress:progress});
            if (forceSave){
                return postActionData(appContext.arToken, appContext.actionToken, { type:"reservation", step: nextStep, progress:progress, awsTrigger:true })
            }else{
                return data                                
            }
        
                     
        }).catch( error =>   {
            console.log(error)
            if (messengerState.actionState === undefined){
                //If this is the initial load, set it
                let progress = getUnitReservationStepProgress(nextStep);
                messengerDispatch({ type:"setStep", step:nextStep, hasProgressed:false, progress:progress});   
            }
            
        } );                    
    }

    const updateActionState = (state, forceSave) => {   
        let previousState = messengerState.actionState?messengerState.actionState:appContext.currentAction.state;        
        return updateState(previousState, state).then((data)=>{            
            messengerDispatch({ type:"setState", state:state, hasProgressed:true})
            if (forceSave){
                return postActionData(appContext.arToken, appContext.actionToken, { type:"reservation", state: state, awsTrigger:true })
            }else{                    
                return data
            }
             
            
            //messengerDispatch({ type:"setState", state:data.state});            
        }).catch( error =>  {
            console.log(error)
            if (messengerState.actionState === undefined){
                //If this is the initial load, set it
                messengerDispatch({ type:"setState", state:state, hasProgressed:false});   
            }
            
        });            
    }
    
    const markCompleted = () => {
        if (!inCompletedState(messengerState.actionState)){
            let progress = getUnitReservationStepProgress("payment-completed");
            postActionData(appContext.arToken, appContext.actionToken, { type:"reservation", state: "completed", step:"payment-completed", progress:progress, awsTrigger:true, entireDataSet:true })
        }        
    }

    const markStarted = () => {
        if (!inCompletedState(messengerState.actionState)){
            let progress = getUnitReservationStepProgress("started");
            postActionData(appContext.arToken, appContext.actionToken, { type:"reservation", state: "viewed", step:"started", progress:progress, awsTrigger:true, entireDataSet:true })
        }        
    }

    const updateCurrentAction = (data) => {        
        setAppContext(appContext => ({...appContext, currentAction:{...appContext.currentAction, ...data}}))
    }

    const updateCurrentRecipient = (data) => {        
        setAppContext(appContext => ({...appContext, currentRecipient:{...appContext.currentRecipient, ...data}}))
    }

    const UnitCommands = (data, arToken, actionToken, currentAction) =>{    
        //Look out for specific unit number and id here        
        let unitKey = currentAction.unitKey  
        if (unitKey === undefined && currentAction.unitSource !== undefined){
            unitKey = currentAction.unitSource.unitKey;
        }
        //If you aren't the artoken that bought the unit, then you are sold out
        //Fix this so that extendTime workers on arToken 
        if (data.arToken === arToken || data.actionToken === actionToken){                
            if (data.command === "adminCancelled" && data.unitKey && data.unitKey === unitKey ){
                let extraProps = {}
                extraProps.heading = data.cancelledHeading
                extraProps.description = data.cancelledDescription
                setAppContext(appContext => ({...appContext, soldOut:true, blocked:{active:true, displayComponent:<AdminCancellation actions={appContext.currentAction.returnPaths} {...extraProps}/>}}))
            }else if (data.command == "extendTimer" && data.actionToken == actionToken){                
                if (data.expiryDate){                    
                    updateExpiryDate(data.expiryDate)
                }                
            }else if (data.command === "expired"){
                updateExpiryDate(false);
            }
            
        }else{
            console.log("Invalid Command: ",unitKey, data.unitKey, arToken, data.arToken, data.command)            
        }
        
    }    
    
    return (            
        // Wraps the input component in a container, without mutating it. Good!
        (!messengerState.currentActionInit || !messengerState.actionState?
        <OnceOff ready={messengerState.currentActionInit}></OnceOff>:        
        <WrappedComponent 
            expiryDate={(expiryDate !== undefined)?convertTimestampToDate(expiryDate):null}
            step={messengerState.step}  
            postActionDataToServer={postActionDataToServer}
            updateActionState={updateActionState}  
            markCompleted={markCompleted}          
            updateLastStep={updateLastStep}         
            setExpiryDate={updateExpiryDate}
            handleInvalidStep={handleInvalidStep}
            handleSoldOut={handleSoldOut}
            updateCurrentAction={updateCurrentAction}
            updateCurrentRecipient={updateCurrentRecipient}
            blocked={appContext.blocked}
            {...props} 
        />)
    );
  }

export default withReservation;

export {
    withReservation,
    PaddedSkeleton
}
