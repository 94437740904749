import AccountContainer from "../../components/containers/AccountContainer";
import React from "react";
import {LaunchPreviewSwitcher} from "../demo/Elements";
import {Link, withRouter} from "react-router-dom";

const Launch = (props) => {
    const params = props.match.params
    const entityId = params.entityId
    const launchId = params.launchId
    const slug = params.slug

    return <AccountContainer>
        <LaunchPreviewSwitcher link={<Link to={`/launch/${entityId}/${launchId}/${slug}/welcome`}>Welcome</Link>}/>
        <iframe src="https://mirror-ism.simspro.co.za/property-pass-demo/#/estate/munyaka" style={{width:"100%",height:"100%"}}/>
    </AccountContainer>

}

export default withRouter(Launch)