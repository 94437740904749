import React from "react";
import PropTypes from "prop-types";
import styled from 'styled-components';


const fileStackCdnUrl = "https://cdn.filestackcontent.com";

const placeholderSrc = (width, height) => `data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 ${width} ${height}"%3E%3C/svg%3E`;

const ResponsiveImage = styled.img`
  max-width: 100%;
  height auto; /* default */
`;

const ImagePlaceholder = (props) => {
    return <ResponsiveImage src={placeholderSrc(props.width, props.height)}/>
};



export const createUrlFromJson = (imageData, width, height, fit) => {

    try{
        //console.log("imageData",imageData);
        
        if(!imageData){
            return ""
        }

        const url = imageData.url;
        let fileId = url.replace("https://www.filepicker.io/api/file/",'');
        fileId = fileId.replace("https://cdn.filestackcontent.com/",'');

        let resize = "";
        if(width || height || fit){
            resize = "/resize=fit:"+(fit?fit:'crop');
            if(width){
                resize += ",width:"+width;
            }
            if(height)
            {
                resize += ",height:"+height;
            }

        }

        let rotate = "";
        if(imageData.rotation){
            rotate = "/rotate=deg:"+imageData.rotation
        }


        const extras = "/output=format:png,quality:95,compress:true";
        const cache =  "/cache=expiry:max";

        let processedUrl = "";
        // if we have SVGs uploaded then most of the transformation doesn't happen
        if(imageData.mimetype.indexOf("svg")>-1){
            processedUrl = fileStackCdnUrl + rotate + cache+ "/"+fileId ;
        }else{
            processedUrl = fileStackCdnUrl +resize + rotate + extras + cache+ "/"+fileId ;
        }

        //console.log("ProcessedUrl",processedUrl)

        return processedUrl
    }catch(err){
        console.log(err)
        return ""
    }
};


export const createUrlFromString = (imageString, width, height, fit) =>{
    return createUrlFromJson(JSON.parse(imageString), width, height, fit)
};

const Image = props => {

    let json = props.json;
    const jsonString = props.jsonString;
    const defaultImage = props.defaultImage;

    // console.log("json->"+json);
    if (!json && !defaultImage){
        return "";
    }else if (!json){
        json = defaultImage;
    }
    let image;
    if(json){
        image = <ResponsiveImage src={createUrlFromJson(json,props.width,props.height, props.fit)} alt={props.alt}/>
    }else if(jsonString){
        image = <ResponsiveImage src={createUrlFromString(jsonString,props.width,props.height, props.fit)} alt={props.alt}/>
    }else if(props.placeholder){
        image = <ResponsiveImage src={props.placeholder} alt={props.alt} />
    }else{
        image = ImagePlaceholder()
    }

    return image;
};

Image.propTypes = {
    json: PropTypes.object,
    defaultImage: PropTypes.object,
    width: PropTypes.oneOfType([PropTypes.number]),
    height: PropTypes.oneOfType([PropTypes.number]),
    fit: PropTypes.string,
    placeholder: PropTypes.string
};

Image.defaultProps = {
    width: 100,
    height: null,
    fit: 'crop'
};


export default Image;
