import React, { useState, useEffect } from 'react';
import { Formik } from 'formik'
import { Form, Input, Checkbox, Select, Switch, DatePicker } from 'formik-antd'
import { Tabs, Layout, Button, Row, Col, Select as AntSelect, Divider, Alert} from 'antd';
import find from 'lodash/find';


const { Option } = Select;

let invokers = []


const DemoForm = (props) =>{

    console.log("PROPS!@#", props)
    const [formValues, setFormValues] = useState(props.data)   
    const [preset, setPreset] = useState(props.preset)   
    if (props.invokers){        
        invokers = props.invokers;
    }

    const onHandleReset = () => {
        setFormValues({})
        setPreset("new")
    }

    const onPresetChange = (value) => {

        setPreset(value);
        
    }

    const onEntityChange = (value) => {
        console.log("entity", value)
        setFormValues( (formValues) => ({...formValues, entity:value}))
    }

    const onInvokerChange = (value) => {
        console.log("invoker", value)
        setFormValues( (formValues) => ({...formValues, invoker:value}))
    }

    useEffect(() => {
        console.log("PRESET CHANGED.....")
        const matchingPreset = find(props.presetList,(item)=>{
            return item.code === preset
        });

        console.log("matchingPreset", matchingPreset)
        if (matchingPreset !== undefined){
            setFormValues(matchingPreset.values)
        }
        
    }, [preset])

    return   <div>
                <Row>
                    <Col span={12}>
                        <h1>Demo Page</h1>
                        <p>Fill in fields below to generate a new link</p>
                    </Col>
                    <Col span={12}>
                        <div style={{textAlign:'right'}}>
                        <h3>Select a preset</h3>
                        <AntSelect name="preset" style={{minWidth:200}} value={preset} onChange={onPresetChange}>
                        <AntSelect.Option key={"new"}>Add a new Preset</AntSelect.Option>
                        {props.presetList.map(preset => (
                                <AntSelect.Option key={preset.code}>{preset.label}</AntSelect.Option>
                            ))}
                        </AntSelect>                                           
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col span={12}>                        
                        <h3>Load an entity</h3>
                        <AntSelect name="entity" style={{minWidth:200}} onChange={onEntityChange}>                        
                        {props.entities.map(entity => (
                                <AntSelect.Option key={entity.value}>{entity.text}</AntSelect.Option>
                            ))}
                        </AntSelect>                                                               
                    </Col>
                    <Col span={12}>                        
                        <h3>Load an invoker</h3>
                        <AntSelect name="invoker" style={{minWidth:200}} onChange={onInvokerChange}>                        
                        {props.invokers.map(invoker => (
                                <AntSelect.Option key={invoker.value}>{invoker.text}</AntSelect.Option>
                            ))}
                        </AntSelect>                                                                   
                    </Col>
                </Row>
                <Divider/>

                <Formik
                    enableReinitialize={true}
                    initialValues={formValues}
                    onSubmit={(values) => {   
                        values.presetId = preset;                     
                        props.onGenerate(values)

                    }}
                    render={(props) => {


                        let actionRequestContent = <Alert message='Select type to continue' />
                        if(props.values.actionRequestType==='creditcheck'){
                            actionRequestContent = "No further information required"
                        }else if(props.values.actionRequestType==='responder'){
                            actionRequestContent = <React.Fragment><Row gutter={16}>
                                        <Col span={8}>
                                        <h4>Developments</h4>
                                        <Form.Item label="Development List" name="developmentList">
                                        <Select
                                            mode="multiple"
                                            style={{ width: '100%' }}
                                            placeholder="Select developments"                                      
                                            optionLabelProp="label"
                                            name="developmentList"
                                        >
                                            <Option value="the-whisken">
                                                The Whisken
                                            </Option>
                                            <Option value="the-jade">
                                                The Jade
                                            </Option>
                                                                                     
                                        </Select>
                                        </Form.Item>
                                        <h4>Sub Actions</h4>
                                        <Checkbox name="actionMoveNow">Interested</Checkbox><br/>
                                        <Checkbox name="actionBrowsing">Browsing</Checkbox><br/>
                                        <Checkbox name="actionMoreInfo">More Information</Checkbox><br/>
                                        <Checkbox name="actionServiceOffering">Service Provider</Checkbox><br/>
                                        </Col>
                                    </Row>
                                </React.Fragment>
                        }else if(props.values.actionRequestType==='reservation'){
                            actionRequestContent = <React.Fragment>
                                                        <Row gutter={16}>
                                                            <Col span={8}>
                                                                <Form.Item label="Development Label" name="developmentLabel">
                                                                    <Input name="developmentLabel" placeholder="Development label" />
                                                                </Form.Item>
                                                                <Form.Item label="Unit Label" name="unitLabel">
                                                                    <Input name="unitLabel" placeholder="Unit label" />
                                                                </Form.Item>
                                                                <Form.Item label="Unit Source" name="unitSource">
                                                                    <Select name="unitSource" style={{minWidth:200}} >
                                                                        <Option key="ism">iSM</Option>                                  
                                                                        <Option key="propertyengine">PropertyEngine</Option>                                  
                                                                    </Select>    
                                                                </Form.Item>
                                                                {props.values.unitSource==='ism' ? 
                                                                <React.Fragment>
                                                                    <Form.Item label="Map Key" name="ismMapKey">
                                                                        <Input name="ismMapKey" placeholder="ISM Map Key" />
                                                                    </Form.Item>
                                                                    <Form.Item label="Development Key" name="ismDevKey">
                                                                        <Input name="ismDevKey" placeholder="ISM Development Key" />
                                                                    </Form.Item>
                                                                    <Form.Item label="Unit Key" name="ismUnitKey">
                                                                        <Input name="ismUnitKey" placeholder="ISM Unit Key" />
                                                                    </Form.Item>                                                                  
                                                                    <Form.Item label="Selected Plan" name="selectedPlanId">
                                                                        <Input name="selectedPlanId" placeholder="ISM Selected Plan id" />
                                                                    </Form.Item>
                                                                   
                                                                </React.Fragment>:''}
                                                                {props.values.unitSource==='propertyengine' ? 
                                                                <React.Fragment>
                                                                        Not authorised
                                                                    {/* <Form.Item label="Map Key" name="ismMapKey">
                                                                        <Input name="ismMapKey" placeholder="ISM Map Key" />
                                                                    </Form.Item>
                                                                    <Form.Item label="Development Key" name="ismDevKey">
                                                                        <Input name="ismDevKey" placeholder="ISM Development Key" />
                                                                    </Form.Item>
                                                                    <Form.Item label="Unit Key" name="ismUnitKey">
                                                                        <Input name="ismUnitKey" placeholder="ISM Unit Key" /> */}
                                                                    {/* </Form.Item> */}
                                                                </React.Fragment>:''}
                                                            
                                                            </Col>
                                                            <Col span={8}>
                                                                <h4>Expiry Date</h4>                                                                    
                                                                <Form.Item label="Expiry Date" name="expiryDate">
                                                                    <DatePicker name="expiryDate" showTime autoComplete='off'  />
                                                                </Form.Item>  
                                                                <h4>Reservation Fee</h4>       
                                                                <Form.Item label="Reservation Fee Label" name="reservationFeeLabel">
                                                                    <Input name="reservationFeeLabel" placeholder="Deposit" />
                                                                </Form.Item>
                                                                <Form.Item label="Reservation Fee Type" name="reservationFeeType">
                                                                    <Select name="reservationFeeType" style={{minWidth:200}} >
                                                                        <Option key="fixed">Fixed</Option>                                  
                                                                        <Option key="percentage">Percentage</Option>      
                                                                    </Select>                            
                                                                </Form.Item>
                                                                <Form.Item label="Reservation Fee Amount" name="reservationFeeAmount">
                                                                    <Input name="reservationFeeAmount" placeholder="" />
                                                                </Form.Item>       
                                                                <h4>Payment methods</h4>
                                                                <Checkbox name="paymentMethodEft">EFT Payment</Checkbox><br/>
                                                                <Checkbox name="paymentMethodPeach">Peach Credit Card Payment</Checkbox><br/>
                                                                <Checkbox name="paymentMethodFnbScan">Fnb Scan Payment</Checkbox><br/>
                                                            </Col>
                                                            <Col span={8}>
                                                                <Form.Item label="Terms &amp; Conditions PDF" name="termsPdf">
                                                                    <Input name="termsPdf" placeholder="" />
                                                                </Form.Item>   
                                                                <Form.Item label="Terms &amp; Conditions List" name="termsList">
                                                                    <Input.TextArea style={{minHeight:200}} name="termsList" placeholder="You will pay x, you will do y" />
                                                                </Form.Item>   
                                                                <Form.Item label="Example Return Path Label" name="returnPathLabel">
                                                                    <Input name="returnPathLabel" placeholder="" />
                                                                </Form.Item>   
                                                                <Form.Item label="Example Return Path Link" name="returnPathLink">
                                                                    <Input name="returnPathLink" placeholder="" />
                                                                </Form.Item>   
                                                            </Col>
                                                        </Row>
                                                    </React.Fragment>

                        }

                        return <div>
                            <Form onSubmit={props.handleSubmit} layout='vertical'>
                                <Row gutter={16}>
                                    <Col span={8}>
                                        <h3>Recipient</h3>
                                        <Form.Item label="Name" name="recipientName">
                                            <Input name="recipientName" placeholder="Name of recipient" />
                                        </Form.Item>
                                        <Form.Item label="Email" name="recipientEmail">
                                            <Input name="recipientEmail" type="email" placeholder="Enter email address" />
                                        </Form.Item>
                                        <Form.Item label="Mobile" name="recipientMobile">
                                            <Input name="recipientMobile"  placeholder="Enter phone number" />
                                        </Form.Item>
                                    </Col>
                                    <Col span={8}>
                                        <h3>Entity</h3>
                                        <Form.Item label="Enter entity token" name="entity">
                                            <Input name="entity" placeholder="Entity Token" />
                                        </Form.Item>
                                        <h3>Preset</h3>
                                        <Form.Item label="Preset Label" name="presetLabel">
                                            <Input name="presetLabel" placeholder="Preset Label" />
                                        </Form.Item>
                                    </Col>
                                    <Col span={8}>
                                        <h3>Invoker</h3>
                                        <Form.Item label="Enter invoker token" name="invoker">                                        
                                            <Input name="invoker" placeholder="Invoker Token" />                                        
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Divider/>
                                <h3>Action Request</h3>
                                <Form.Item label="Action request type" name="invoker">
                                    <Select name="actionRequestType" style={{minWidth:200}} >
                                        <Option key="creditcheck">Credit Check</Option>                                  
                                        <Option key="responder">Responder</Option>                                  
                                        <Option key="reservation">Unit Reservation</Option>                                  
                                    </Select>
                                </Form.Item>
                                <div>{actionRequestContent}</div>
                                <Divider/>
                                <Button size="large" htmlType="submit">{preset ==="new"?'Create':'Update'}</Button>
                                <Button size="large" onClick={onHandleReset}>Clear</Button>
                            </Form>
                        </div>
                    }} />
                </div>
}

export default DemoForm;