import React from 'react';
import {Card, Empty, Timeline} from "antd";
import styled from 'styled-components';
import {withRouter} from "react-router-dom";
import ProgressLineItem from "./ProgressLineItem";


const ProgressTimeLine = styled(Timeline)`
&&&{
    margin-left:0.5em;
}
`;


const ProgressList = props => {

    const progressList = props.progress.map((item, index) => {
        return <ProgressLineItem key={`progress-${index}`} item={item}/>
        ;
    });

    return (
        <ProgressTimeLine>
            {progressList.length ? progressList : <Card><Empty description={"No Progress"}/></Card>}
        </ProgressTimeLine>
    );
};
 
export default React.memo(withRouter(ProgressList));