import React, {useContext, useState, useEffect} from 'react';
import styled from "styled-components";
import { Spin,Row, Col,Alert } from 'antd';
import CreditScorePie from '../../components/creditScore/CreditScorePie';
import CreditBandRange, {getScoreBandFromScore} from '../../components/creditScore/CreditBandRange';
import { withRouter } from "react-router-dom";
import { LoadingContainer } from '../../theme/DefaultTheme';
import { SecondaryBlockButton}  from '../../components/button/Button';
import {ActionRequestContext} from "./ActionRequestContext";
import OnceOff from "./OnceOff.js";
import Amount from "../../components/display/Amount";
import OnceOffPageContent  from '../../components/containers/OnceOffPageContent';
import { fetchCreditReport } from '../../services/PropertyPassService';

const ReportTable = styled.table`
    text-align:left;
    width: 100%;
    th,td{
        font-size: 0.9em;
        font-weight: bold;

        padding: 0.85em 1em;
        border: 1px solid #EDF1F3;        
    }
    th{
        color: #9C9EA3;
        font-weight: bold;
    }

    @media only screen and (min-width: 970px) {
        th{
        max-width: 50px;
        }
    }
`;

const PieContainer = styled.div`
    border-top: 1px solid #EDF1F3;
    border-bottom: none;
    border-left: 1px solid #EDF1F3;
    border-right: 1px solid #EDF1F3;

    @media only screen and (min-width: 970px) {
        border-bottom: 1px solid #EDF1F3;
        border-right:none;

    }
`;

const UnderstandYourScore = styled.div`
    padding: 2em;
    border-left: 1px solid #EDF1F3;        
    border-bottom: 1px solid #EDF1F3;        
    border-right: 1px solid #EDF1F3;          
`;

const PageFooterActions= styled.div`
    background: #D0ECED;
    /* height: 110px; */
    padding: 15px;
`;


const CreditReport = (props) => {

    const [appContext] = useContext(ActionRequestContext);
    const [creditReport, setCreditReport] = useState({});
    const [arToken] = useState(appContext.arToken);

    
    useEffect(() => {
        if (arToken) {
            console.log("Trigger find data for step");
         
            fetchCreditReport(arToken, appContext.userToken)
            .then(data => {                
                setCreditReport(data); // this should change to below
            }).catch(err => {
                console.log('Error getting step data', err);
            });
            
        }

    }, [arToken])

    let report = null;
    if(creditReport.report){

        const agentName = appContext.invoker.name
        const entityName = appContext.entity.name
        const summary = creditReport.report.summary
        const scoring = creditReport.report.scoring
        const cur= summary.currency

        const goodPoints = Math.round(scoring.final_score);

        const scoreBands = creditReport.report.score_bands
        const scoreBand = getScoreBandFromScore(scoreBands,goodPoints)
        
        
        const pieBoxStyle = {
            height:240,
        }
       
  
        report = <React.Fragment><Row>
                <Col xs={24} lg={8}>      
                    <PieContainer>
                        <CreditScorePie scoreBand={scoreBand} goodPoints={goodPoints} totalPoints={999} scoreBands={scoreBands} style={pieBoxStyle} />                    
                    </PieContainer>                    
                </Col>
                <Col  xs={24} lg={16}>
                    <ReportTable style={{textAlign:'left'}}>
                        <tbody>
                    <tr>
                        <th>State</th>
                        <td>{scoring.score_wording} ({scoring.risk_category})</td>
                    </tr>
                    <tr>
                        <th>Total debt</th>
                        <td><Amount amount={summary.consumer_debt} round={0}/></td>
                    </tr>
                    <tr>
                        <th>Total payments</th>
                        <td><Amount amount={summary.consumer_instalments} round={0}/></td>
                    </tr>
                    <tr>
                        <th>Total in arrears</th>
                        <td><Amount amount={summary.consumer_arrears} round={0}/></td>
                    </tr>
                    <tr>
                        <th>Enquiries</th>
                        <td>{summary.enquiries}</td>
                    </tr>
                    <tr>
                        <th>Judgements</th>
                        <td>{summary.judgements.count}</td>
                    </tr>
                    </tbody>
                </ReportTable>
            </Col>
        </Row>
        <Row>
            <Col span={24}>
                <UnderstandYourScore>                   
                    <CreditBandRange title={<h4>Understanding your score</h4>} scoreBands={scoreBands} />
                </UnderstandYourScore>                
            </Col>
        </Row>
        <Row>
            <Col span={24}>
                <Alert style={{textAlign:'left', marginTop:'2em'}}
                    message={("This information has been sent through to "+entityName)}
                    description={("Your credit score will now be evaluated by "+agentName+" and they will contact you shortly with the next steps.")}
                    type="info"
                    showIcon
                    />
            </Col>
        </Row>
        </React.Fragment>
    }

    const footer = <PageFooterActions>
        <SecondaryBlockButton>Do Something</SecondaryBlockButton>
    </PageFooterActions>

    console.log("Why are you rendering now",appContext.invoker) 
    return  <OnceOff>
                    <OnceOffPageContent type="wide" title="Your credit score" >
                    {report ? report : <LoadingContainer><Spin tip="Loading your credit score..."></Spin></LoadingContainer>}
                </OnceOffPageContent>  
            </OnceOff>   
        
}

export default withRouter(CreditReport);