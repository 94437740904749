import React, { useEffect, useState, useContext } from 'react';
import styled from "styled-components";
import _ from 'lodash';

import { pathToReservationOnceOffRoute } from '../../../routes/publicRoutes';
import { withRouter, useHistory, Link } from "react-router-dom";
import {convertTimestampToDate, CountdownTicker} from "../../../components/progress/CountdownTicker";
import { Steps,Typography, Spin} from 'antd';

import { Formik } from 'formik'
import { NarrowContent } from '../../../theme/DefaultTheme';
import WizardContent from "../../../components/containers/WizardContent"
import { PrimaryBlockButton, SecondaryBlockButton } from '../../../components/button/Button';
import {postActionData, fetchEftData, fetchPaymentReference} from '../../../services/PropertyPassService';
import {getUnitReservationStepProgress, updateUnitReservationStep} from '../../../services/EventTriggerService';
import { ActionRequestContext } from "../ActionRequestContext";
import OnceOff from "../OnceOff";
import Amount from "../../../components/display/Amount";
import PaymentSummary from "./PaymentSummary";
import RoadBlock from '../../../components/containers/RoadBlock'
import withReservation from './ReservationProvider'

const { Step } = Steps;
const { Paragraph } = Typography;

const StyledSteps = styled(Steps)`
&&&{
    text-align:left;
    margin-bottom: 20px;
    border-radius: 5px;
    .ant-steps-item-icon{
        color:${props => props.theme.primaryActionColor};
        border-color:${props => props.theme.primaryActionBgColor};
        
    }
    .ant-steps-item-active{
        .ant-steps-item-icon{

            background:${props => props.theme.primaryActionBgColor};
        }
    }
}       
`;

const StepInstructions = styled.div`
    min-height: 100px;
    background: #fafafa;
`;

const BankDetail = styled.div`
    width: 100%;
    .label{
        font-weight:bold;
        display: inline-block;
        min-width: 150px;        
    }
    .value{
        display: inline-block;
    }
`;

const StepInstructionsInner = styled.div`
   padding: 15px 15px 25px;
   text-align:left;
   color: black;
   &:last-child{
       padding-bottom: 25px;
   }
`;


const ReservationPaymentEft = (props) => {

    const [appContext] = useContext(ActionRequestContext);

    const { arToken, userToken, actionToken, currentAction} = appContext;

    const [eftData, setEftData] = useState(null);
    const [paymentReference, setPaymentReference] = useState(currentAction.paymentReference);
    const [expiryDate, setExpiryDate] = useState(props.expiryDate);


    const backUrl = pathToReservationOnceOffRoute(arToken, userToken, actionToken, "payment")
    const nextUrl = pathToReservationOnceOffRoute(arToken, userToken, actionToken, "complete")
    const history = useHistory();
    
    useEffect(()=>{
        if (!(currentAction.termsAccepted && (currentAction.verificationId !== "" || currentAction.verificationId !== undefined))){            
            props.handleInvalidStep();
        }else{
            props.updateLastStep("payment-selected", true).finally(data => {
                console.log("step was updated, now do the rest...")
                if(!paymentReference){ // only generate a new payment reference if there isn't one already
                    // fetch a payment reference to so EFT can be done            
                    fetchPaymentReference(arToken,actionToken).then(response =>{
                        let ref = response.paymentReference                                    
                        setPaymentReference(ref) 
                        return props.postActionDataToServer({paymentReference:ref})            
                        
                    }).catch(err=>{
                        console.log(err)
                    })
                }
        
                fetchEftData(arToken, actionToken).then(eftData =>{
                    console.log("Got response ", eftData)
                    setEftData(eftData)
                }).catch(err=>{
                    console.log("error")
                })    
            });        
        }    
                
    },[])

    useEffect(() => {
        if (props.expiryDate !== expiryDate){            
            setExpiryDate(props.expiryDate);
        }       
    }, [props.expiryDate])


    // Handles if somebody clicks the current step
    const handleOnClick = () =>{

        if(paymentReference){
            let progress = getUnitReservationStepProgress("payment-completed");
            postActionData(appContext.arToken, appContext.actionToken, { type:"reservation", state: "completed", step:"payment-completed", paymentMethod: "manual-eft", paymentReference:paymentReference,progress:progress, awsTrigger:true, entireDataSet:true })
            history.push(nextUrl);
        }

    }
    
    const handleTimesUp = () => {
        props.setExpiryDate(false);        
    }

    return (
        <OnceOff>         
            <RoadBlock blocked={appContext.blocked}>         
                <WizardContent
                    activeStep={5} totalSteps={6} title="EFT Payment" 
                    info="You have selected to pay via EFT Payment, please follow the steps below"
                    afterHeader={(expiryDate)?<CountdownTicker expiryDate={expiryDate} handleTimesUp={handleTimesUp}/>:null}
                    >

                    {eftData ? 
                        <NarrowContent>
                            <PaymentSummary action={currentAction}/>                        
                            <StepInstructionsInner>                    
                                <h3>Payment details</h3>
                                <BankDetail>
                                    <span className='label'>Amount to pay</span>
                                    <Paragraph className='value' copyable={{ text: currentAction.reservationFeeAmount }}>
                                        <Amount amount={currentAction.reservationFeeAmount}/>
                                    </Paragraph>
                                    
                                </BankDetail> 
                                {eftData.bank ? 
                                    eftData.bank.map((bankDetail,index)=>{
                                        return <BankDetail key={index}>
                                        <span className='label'>{bankDetail.label}</span>
                                        <Paragraph className='value' copyable>{bankDetail.value}</Paragraph>
                                    </BankDetail>
                                    }) : ""
                                }                                           
                                <BankDetail>
                                    <span className='label'>Reference</span>
                                    {paymentReference?<Paragraph className='value' copyable>{paymentReference}</Paragraph>:<Spin tip="Loading reference number..." />}
                                    
                                </BankDetail> 
                            </StepInstructionsInner>
                            {eftData.popEmail ?  
                                <StepInstructionsInner>
                                    <h3>Please send Proof of Payment to</h3>                                    
                                    <BankDetail>
                                        <span className='label'>POP Email Address</span>
                                        <Paragraph className='value' copyable>{eftData.popEmail}</Paragraph>
                                    </BankDetail> 
                                </StepInstructionsInner> : ""
                                }
                           
                            <PrimaryBlockButton onClick={handleOnClick} loading={!paymentReference}>Ok I've made payment</PrimaryBlockButton>
                            <SecondaryBlockButton>
                                <Link to={backUrl}>Back</Link>                            
                            </SecondaryBlockButton>
                        </NarrowContent> : <Spin/>
                    }
                </WizardContent>
            </RoadBlock>
        </OnceOff>
    )
}

export default withRouter(withReservation(ReservationPaymentEft))
