import React from 'react'
import {AuthBrand, RegistrationContainer, RegistrationMotivation, RegistrationOptions} from "./AuthForm";
import Image from "../../components/image/Image";
import Icon from "../../components/icon/Icon";


/**
 * This is just the HTML layout of the Auth Form
 * @param coBrand
 * @param customBenefits
 * @param props
 * @returns {*}
 * @constructor
 */
const AuthFormTemplate = ({coBrand,customBenefits,...props})=>{


    return  <RegistrationContainer>
        <RegistrationOptions>
            {coBrand ?
                <AuthBrand>
                    <Image className="primaryBrand" json={coBrand} height={200} width={200} fit="clip"/>
                </AuthBrand>
                :
                <AuthBrand>
                    <img src='/images/logo/property-pass-full-logo.svg' alt="Property Pass"/>
                </AuthBrand>
            }
            {props.children}
        </RegistrationOptions>
        <RegistrationMotivation>
            {customBenefits ?
                customBenefits :
                <React.Fragment>
                    {coBrand ? <AuthBrand className="coBrand">
                        <img src='/images/logo/property-pass-full-logo.svg' alt="Property Pass"/>
                    </AuthBrand> : ""}
                    <p>Property Pass is a tool to help with the following:</p>
                    <Icon icon="checkmark-circle"/> Keep control of your personal data<br/>
                    <Icon icon="checkmark-circle"/> Make registration to property launches easy<br/>
                    <Icon icon="checkmark-circle"/> Keep track of the properties you have purchased<br/>
                </React.Fragment>
            }
        </RegistrationMotivation>
    </RegistrationContainer>
}

export default AuthFormTemplate