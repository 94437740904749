import React from 'react';
import NumberFormat from 'react-number-format';

/**
 * Used to display currency
 */
const Amount = (props) =>{

    const currency = props.currency || "R";
    const amount = Number(props.amount);

    return <span className="amount" style={props.style}>
                <NumberFormat value={amount} displayType={'text'} thousandSeparator={true} prefix={currency} />
           </span>
}

export default Amount;