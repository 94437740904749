import React from 'react';
import propTypes from "prop-types";
import Image from "../image/Image";
import {Col, Row} from "antd";

const Gallery = (props) => {

    const {images, thumbWidth, maxImages,imageRatio} = props;

    const ratioArray = imageRatio.split(':');
    
    if(ratioArray.length < 2){
        throw new Error("Ratio must be in the format H:W where H = height and W = width, e.g. 6:9 ")
    }

    const heightMultiplier = ratioArray[0]/ratioArray[1];
    const maxHeight = Math.round(heightMultiplier * thumbWidth);

    
    

    const numOfImages = Math.min(images.length, maxImages);
    // console.log("images",images);
    // console.log("thumbWidth",thumbWidth);
    // console.log("images.length",images.length);
    // console.log("maxImages",maxImages);
    // console.log("numOfImages",numOfImages);

    let singleSetCount = 0;
    let doubleSetCount = 0;
    let tripleSetCount = 0;

    if(numOfImages < 5){
        doubleSetCount = Math.floor(numOfImages / 2);
        // console.log("numOfImages",numOfImages, numOfImages/2)
        let remainderOfTwos = numOfImages % 2;
        if(remainderOfTwos > 0 ){
            singleSetCount = 1;
        }
    }else{
        tripleSetCount =  Math.floor(numOfImages /3);
        let remainderOfThrees = numOfImages % 3;
        if(remainderOfThrees){
            doubleSetCount =  Math.floor(remainderOfThrees / 2);
            let remainderOfTwos = remainderOfThrees % 2;
            if(remainderOfTwos > 0 ){
                singleSetCount = 1;
            }
        }
    }

    // console.log("counts",singleSetCount,doubleSetCount,tripleSetCount)

    let singleImageDom = null;
    let currentIndex = 0;
    if(singleSetCount>0){
        singleImageDom =  <Row><Col><Image json={images[currentIndex]} height={maxHeight}  width={thumbWidth}/></Col></Row>
        currentIndex++;
    }

    const doubleImageDom = [];
    if(doubleSetCount>0){

        const doubleWidth = Math.round(thumbWidth/2);
        const doubleHeight = Math.round(maxHeight/2);
        for(let i=0;i<doubleSetCount;i++){
            doubleImageDom.push(<Row key={'image-2-'+i}>
                <Col span={12}><Image json={images[currentIndex]} height={doubleHeight} width={doubleWidth}/></Col>
                <Col span={12}><Image json={images[currentIndex+1]} height={doubleHeight}  width={doubleWidth}/></Col>
            </Row>)
            currentIndex = currentIndex+2;
        }
    }
    const tripleImageDom = [];

    if(tripleSetCount>0){

        const tripleWidth = Math.round(thumbWidth/3);
        const tripleHeight = Math.round(maxHeight/3);
        for(let i=0;i<tripleSetCount;i++){
            tripleImageDom.push(<Row key={'image-3-'+i}>
                <Col span={8}><Image json={images[currentIndex]} height={tripleHeight}  width={tripleWidth}/></Col>
                <Col span={8}><Image json={images[currentIndex+1]} height={tripleHeight}  width={tripleWidth}/></Col>
                <Col span={8}><Image json={images[currentIndex+2]} height={tripleHeight}  width={tripleWidth}/></Col>
            </Row>);
            currentIndex = currentIndex+3;
        }
    }


    return (
        <div>
            {singleImageDom}
            {doubleImageDom.map((item,index) => {
                return item
            })}
            {tripleImageDom.map((item,index) => {
                return item
            })}
        </div>
    );



};


Gallery.propTypes = {
    images: propTypes.arrayOf(propTypes.string).isRequired,
    thumbWidth: propTypes.number,
    maxImages: propTypes.number,
    imageRatio: propTypes.string
};

Gallery.defaultProps = {
    images: [],
    thumbWidth:1024,
    maxImages: 7,
    imageRatio: "1:1"
};


export default Gallery;