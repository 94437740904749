import React from 'react';
import styled from 'styled-components';


const ImageContainer = styled.div`
    background-repeat: no-repeat; 
    background-position: center center;
    background-attachment: fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  width: 100%;
  height: 100%;
`

function FullBackgroundImage (props) {
    return (
        <ImageContainer style={{backgroundImage: `url(${props.image})`}}>
            {props.children}
        </ImageContainer>
    )
}

export default FullBackgroundImage;