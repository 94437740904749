import React, { useEffect, useState, useMemo } from 'react';
import styled from "styled-components";
import PropTypes from 'prop-types';
import moment from 'moment';


const CountdownContainer = styled.div`
    padding: 5px;
    background: red;
    color: white;
    text-align: center;
`;

const defaultTimeout = 45;


/*
Timer work
"startTime":"",
        		"timeout":"30",
        		"webhookUrl":"",
        		"webhookData":{"extraDataToBePassedBack":""}

2 - save to firebase 
3 - write postman queries for all cases
 -- all cases are
 - preset expiry timer
 - max allowed time
 - fixed end date
 - nothing set 
 */

const CountdownTicker = ({...props}) =>{  

  const calculateTimeLeft = (expiryDate) => {        
    //If we have a fixed date rather than a set amount of minutes we can passin timeOutTime      
    const difference = +expiryDate - +new Date();        
    let timeLeftObject = {};

    if (difference > 0) {    
      timeLeftObject = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60)
      };
    }
    return timeLeftObject;
  };   

  const startClock = (expiryDate) =>{
    let interval = setInterval(() => {
    
      let intervalTimeLeft = calculateTimeLeft(expiryDate);            
      //Render if we are happy
      if (Object.keys(intervalTimeLeft).length !== 0){        
        setTimeLeft(intervalTimeLeft);
      }else{
        //End logic loop       
        if (props.handleTimesUp !== undefined){          
          props.handleTimesUp();
        }        
        setTimeLeft(intervalTimeLeft);
        clearInterval(intervalHandler);          
      }
      
    }, 1000);
    setIntervalHandler(interval);
  }

  const [expiryDate, setExpiryDate] = useState(props.expiryDate);
  const [timeLeft, setTimeLeft] = useState(null);
  const [intervalHandler, setIntervalHandler] = useState(null)
  //componentDidMount
  useEffect(() => {        
    //Run first instance then start loop
    setTimeLeft(calculateTimeLeft(expiryDate));
    //Start up the logic loop (this allows us to run a logic loop outside of the render cycle if we chose to do so)
    startClock(expiryDate);
    return () =>{      
      //clear logic if we move on to next screen
      //clearInterval(intervalHandler);
    } 
  }, []);   
  
  useEffect(() => {    
    if (props.expiryDate-expiryDate !== 0){      
      clearInterval(intervalHandler);
      setExpiryDate(props.expiryDate);            
      startClock(props.expiryDate);
    }      
  }, [props.expiryDate])
  return  useMemo(() => <TimerDisplay timeLeft={timeLeft}/>)
}

const TimerDisplay = ({timeLeft}) =>{
  const timerComponents = [];

  if (timeLeft === null){
      return <CountdownContainer><span>What goes here?</span></CountdownContainer>
  }

  Object.keys(timeLeft).forEach(interval => {
    if (!timeLeft[interval]) {      
      return;
    }

    timerComponents.push(
      <span key={interval}>
        {timeLeft[interval]} {interval}{" "}
      </span>
    );
  });    

  return <CountdownContainer>{timerComponents.length ? timerComponents : <span>Time's up!</span>}</CountdownContainer>
}

const convertTimestampToDate = (unixTimestamp) => {
  if ((new Date().valueOf() / unixTimestamp) > 100){
    //if this is an epoch timestamp the amount will always be greater than 100
    return new Date(parseInt(unixTimestamp) * 1000);        
  }else{
    //This isn't a unixTimeStamp, but just a normal millisecond timestamp
    return new Date(parseInt(unixTimestamp));        
  }
  
}

const calculateExpiryTime = (timeOutTime, timeOut, startTime,  setExpiryDate) => {
  let expiryTime = null;

  console.log("startTime? ", startTime)
  //StartTime should be a moment timestamp
  if (startTime === undefined){
    startTime = new Date();
  } 
  var momentObj = moment();

  if (!(startTime instanceof Date)){
    //convert timestamp to date      
    startTime = convertTimestampToDate(startTime); 
    console.log("we have a start time", startTime)
  }

  momentObj.set(moment(startTime).toObject());

  console.log("momentObj - ", momentObj)
  console.log("timeOutTime - ", timeOutTime)
  /*The idea behind this timer is that we want to work out how much time a user has left
    We calculate the expiry time based on a bunch of criteria
    We need to know what the start time was (when did this process begin)
    We need to know if we are working on a fixed time limit based on config/params (30mins) or a fixed date (config)
    Once the above is known we can calculate the expirty time and set it to the applicationContext
  */
  //TimeOutTime is if we are working on a preset expiry date. If we have a fixed date then we have our answer
  if (timeOutTime === undefined || timeOutTime === null){                
    //If no timeOutTime is set, we need to work it out based on a start time
    if (timeOut === undefined || timeOut === 0){
      timeOutTime = momentObj.add(defaultTimeout, 'minutes').toDate();
    }else{
      timeOutTime = momentObj.add(timeOut, 'minutes').toDate();
    }     
  }else{
    if (!(timeOutTime instanceof Date)){
      //convert timestamp to date        
      timeOutTime = convertTimestampToDate(timeOutTime);        
    }
  }  

  //Set the value back in the parent  
  expiryTime = timeOutTime;
  setExpiryDate(timeOutTime); 

  return expiryTime
}

export default CountdownTicker;

export {
  TimerDisplay,
  CountdownTicker, 
  calculateExpiryTime,
  convertTimestampToDate,
}