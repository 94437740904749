import React, { useState, useEffect, useContext } from 'react';

import { TextContent } from '../../../theme/DefaultTheme';
import { notification } from 'antd';
import { Form } from 'formik-antd'
import { Formik } from 'formik'
import styled from "styled-components";
import OtpInput from 'react-otp-input';
import { withRouter, useHistory } from "react-router-dom";
import { pathToReservationOnceOffRoute } from '../../../routes/publicRoutes';
import WizardContent from "../../../components/containers/WizardContent"
import { PrimaryBlockButton, SecondaryBlockButton } from '../../../components/button/Button';
import { postSendOtp, postConfirmOtp } from '../../../services/PropertyPassService';
import OnceOff from "../OnceOff";
import { convertTimestampToDate, CountdownTicker } from "../../../components/progress/CountdownTicker";
import { ActionRequestContext } from "../ActionRequestContext";
import RoadBlock from '../../../components/containers/RoadBlock'
import withReservation from './ReservationProvider'
import moment from 'moment'

const OtpField = styled.div`
    margin: 0 auto ${props => props.theme.defaultBlockMargin};

    & > div{
        & > div{
            input {
                /* Remove First */
                -webkit-appearance: none;
                -moz-appearance: none;
                appearance: none;
            
                /* Then Style */
                line-height: 20px;
                text-align: center;
                background: transparent;
                outline: none;    
                padding:5px;

                width: 1.5em!important;
                height: 1.5em;
                margin: 0 0.25rem;
                font-size: 1.2rem;
                border-radius: 4px;
                border: 1px solid #d9d9d9;

                @media only screen and (min-width: 350px) {
                    font-size: 1.6rem;
                    margin: 0 0.5rem;
                }
                @media only screen and (min-width: 500px) {
                    font-size: 2rem;
                    margin: 0 0.5rem;

                }
            }

     

            /* Chrome, Safari, Edge, Opera */
            input::-webkit-outer-spin-button,
            input::-webkit-inner-spin-button {
                -webkit-appearance: none!important;
                margin: 0;
            }

            /* Firefox */
            input[type=number] {
                background: pink;

                -moz-appearance: textfield!important;
            } 

        }
    }
 
`;



const ReservationOtp = (props) => {        
    let numberOfOtpDigits = 6;    
    
           
    
    const [appContext] = useContext(ActionRequestContext);
    const { arToken, userToken, actionToken, currentAction, currentRecipient} = appContext;

    const [resending, setResending] = useState(false);
    //const [phoneNumber, setPhoneNumber] = useState(currentRecipient.phone)    
    const [verificationId, setVerificationId] = useState(null);
    const [otp, setOtp] = useState('')
    const [nextEnabled, setNextEnabled] = useState(false)
    const [expiryDate, setExpiryDate] = useState(props.expiryDate);
    const history = useHistory();


    const clearOtp = () => {
        setOtp("")
    };
    const handleResend = () => {
        setResending(true);
        clearOtp();
        canUserContinue("");
        postSendOtp(arToken, "actions", actionToken, currentRecipient.phone)
        .then(responseData => {
            console.log("SMS SENT.", responseData) 
            if (responseData.token === actionToken){                
                setVerificationId(responseData.verificationId.sid);
                notification['success']({
                    message: "We've sent an SMS to " + currentRecipient.phone,
                    description:
                        'You should receive an One Time Pin (OTP) shortly, this is a short number that you must type into the inputs below to verify you own that number'
                });
                setResending(false);                
            }else{
                throw new Error("We received verificationId for incorrect token")
            }
            
        }).catch(error => {
            console.log("postOtpResend", error)
            notification['error']({
                message: "Something went wrong when trying to resend your One Time Pin",
                description:
                    'Please contact your agent to let them know of the problem'
            });
            setResending(false);                
        });  
    }

    //On load, if we have all the information required to send an OTP off AND we haven't validated, then let us send it off
    useEffect(() =>{        
        props.updateLastStep("otp");

        if (currentAction.verificationId){
            console.log("we have a valid verification id", currentAction.verificationId)
            let momentVerifiedDate = moment(convertTimestampToDate(currentAction.verifiedDate));
            console.log("date of verification", momentVerifiedDate)
            console.log(momentVerifiedDate.diff(momentCurrentTime, 'hours'))
            var momentCurrentTime = moment();
            if (momentVerifiedDate.diff(momentCurrentTime, 'hours') === 0){
                console.log("Seems we are already validated, so lets just move on to the next step")
                history.push(nextUrl);
                return
            }                       
        }        
        if (!verificationId && !otp){
            // console.log("no id and no otp sent yet.. fire off the kraken!", currentRecipient.phone)
            //@simon I don't know what is going on here, but if I don't use the let it doesn't work. Something about the PPS no longer returning promises
            postSendOtp(arToken, "actions", actionToken, currentRecipient.phone)
            .then(responseData => {
                console.log("SMS SENT.", responseData) 
                if (responseData.token === actionToken){                    
                    setVerificationId(responseData.verificationId.sid);
                    notification['success']({
                        message: "We've sent an SMS to " + currentRecipient.phone,
                        description:
                            'You should receive an One Time Pin (OTP) shortly, this is a short number that you must type into the inputs below to verify you own that number'
                    });                    
                }else{
                    throw new Error("We received verificationId for incorrect token")
                }
                
            });            
        }

    }, [])

    useEffect(() => {
        if (props.expiryDate !== expiryDate){            
            setExpiryDate(props.expiryDate);
        }       
    }, [props.expiryDate])

    useEffect(() =>{
        canUserContinue(otp);
    }, [verificationId])

    const handleTimesUp = () => {    
        props.setExpiryDate(false);        
    }

    const handleOtpChange = (newOtp) => {        
        setOtp(newOtp);  
        canUserContinue(newOtp);      
    };

    const canUserContinue = (otpValue) => {
        console.log("otpValue...", otpValue)
        if (otpValue.toString().length === numberOfOtpDigits && verificationId !== null) {
            setNextEnabled(true)
        } else {
            setNextEnabled(false)
        }
    }

    const nextUrl = pathToReservationOnceOffRoute(arToken, userToken, actionToken, "payment")

    return (<OnceOff>
        <RoadBlock blocked={appContext.blocked}>         
            <WizardContent activeStep={3} totalSteps={6} image="/images/creditcheck/otp.svg"
                afterHeader={(expiryDate)?<CountdownTicker expiryDate={expiryDate} handleTimesUp={handleTimesUp}/>:null}
            >
                <TextContent>
                    <h4>Let's verify your phone number</h4>
                    <p>Enter the pin just sent to {currentRecipient.phone}</p>
                </TextContent>
                <Formik
                    enableReinitialize={true}
                    initialValues={{ otp: otp }}
                    onSubmit={(values, actions) => {                      
                        postConfirmOtp(arToken, "actions", actionToken, verificationId, values.otp).then(response => {
                            actions.setSubmitting(false);                            
                            if (response.verified === true) {
                                //if we want to block otps postActionData(arToken, actionToken, {verified:response.verified})
                                props.postActionDataToServer({verificationId:response.verificationId, verified:response.verified, verifiedDate:response.verifiedDate});
                                //props.updateCurrentAction({verificationId:response.verificationId, verified:response.verified, verifiedDate:response.verifiedDate});
                                history.push(nextUrl);
                            } else {
                                console.log("OTP ERROR!?!@# since when?!@#")                    
                                throw "OTP Error"
                            }           
                        }).catch(() => {
                            actions.setSubmitting(false);
                            setNextEnabled(false)
                            clearOtp();
                            notification['error']({
                                message: "Could not verify the OTP",
                                description: "The One Time Pin you entered does not match our records, if you have resent the OTP please select the last one that you received."
                            });
                        });                     
                    }}
                    render={(formProps) => (
                        <Form layout="inline" size="large" style={{ marginBottom: "3em" }}>
                            {/* REUSE POSSIBILITY HERE */}
                            <OtpField>
                                <OtpInput                            
                                    numInputs={numberOfOtpDigits}
                                    shouldAutoFocus={true}
                                    onChange={(otp) => handleOtpChange(otp)}
                                    isInputNum={false}
                                    value={otp} />
                            </OtpField>
                            <PrimaryBlockButton disabled={!nextEnabled} htmlType="submit" loading={formProps.isSubmitting} style={{ marginLeft: 'auto', marginRight: 'auto' }}>Next</PrimaryBlockButton>
                        </Form>
                    )} />




                <TextContent>
                    <h4>Didn't receive an SMS?</h4>
                </TextContent>
                <SecondaryBlockButton onClick={() => handleResend()} loading={resending} style={{ marginLeft: 'auto', marginRight: 'auto' }}>Resend</SecondaryBlockButton>


            </WizardContent>
        </RoadBlock>
    </OnceOff>
    )
}

export default withRouter(withReservation(ReservationOtp))
