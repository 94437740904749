import React from 'react';
import NumberFormat from 'react-number-format';
import { Input} from 'formik-antd'

/**
 * Used to display currency
 */
const IdNumber = (props) =>{
    
    const idNumber = Number(props.idNumber);

    return <span className="idNumber">
                <NumberFormat value={idNumber} customInput={Input} displayType={'input'} type='text' format="#############" {...props} />
           </span>
}

export default IdNumber;