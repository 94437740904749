//--- STATE MANAGEMENT FOR ALL ACTIONS ---/
const ACTION_STATE_CREATED = "created";
const ACTION_STATE_VIEWED = "viewed";
const ACTION_STATE_STARTED = "started";
const ACTION_STATE_COMPLETED = "completed";
const ACTION_STATE_USER_CANCELLED = "user-cancelled";
const ACTION_STATE_ADMIN_CANCELLED = "admin-cancelled";
const ACTION_STATE_EXPIRED = "expired";

const ACTION_STATE_STEPS = [ACTION_STATE_CREATED, ACTION_STATE_VIEWED, ACTION_STATE_STARTED, ACTION_STATE_COMPLETED]
const ACTION_STATE_COMPLETED_STATES = [ACTION_STATE_COMPLETED, ACTION_STATE_USER_CANCELLED, ACTION_STATE_ADMIN_CANCELLED, ACTION_STATE_EXPIRED]

const inCompletedState = (actionState) => {
    return ACTION_STATE_COMPLETED_STATES.indexOf(actionState) > -1
}


const updateState = (currentState, newState) => {            
    return new Promise((resolve, reject) => {
        if(currentState !== "" && currentState === newState){
            return reject("We are already on this state")
        }
        if (!inCompletedState(currentState)){                    
            if (currentState === undefined || currentState === "" || (ACTION_STATE_STEPS.indexOf(currentState) < ACTION_STATE_STEPS.indexOf(newState)) ){                                
                return resolve({state:newState})            
            }else{
                return reject("Can't revert a state")                
            }
            
        }else{
            return reject("State already marked as completed")            
        }
    })    
}

const getLatestState = (currentState, newState) => {

    if(currentState === newState){
        return currentState;
    }
    if (inCompletedState(currentState)){
        return "completed"
    }else {
        if (currentState === undefined || (ACTION_STATE_STEPS.indexOf(currentState) < ACTION_STATE_STEPS.indexOf(newState))) {
            return newState
        }else{
            return currentState
        }
    }
}


//--- STEP MANAGEMENT ---/

//UNIT RESERVATION STEPS

const UNIT_RESERVATION_STEPS = ['started', 'confirmation', 'purchaser', 'otp', 'payment', 'payment-selected', 'payment-completed']
let dispatch = null;

const updateUnitReservationStep = (currentStep, nextStep) => {
    return updateStep(UNIT_RESERVATION_STEPS, currentStep, nextStep)
}

const getUnitReservationStepProgress = (currentStep) =>{
    //Bit of hackery here to support the face that we only have 7 steps not 9    
    let stepCount = UNIT_RESERVATION_STEPS.indexOf(currentStep) + 1;    
    return {currentStep: stepCount, totalSteps:7}
}

const getStepProgress = (steps, currentStep) =>{
    return {currentStep:steps.indexOf(currentStep), totalSteps:steps.length}
}

const getLastStep = (steps, currentStep, nextStep) => {

    if (currentStep.indexOf('completed') > -1 || currentStep === nextStep) {
        return currentStep
    }

    if (currentStep === undefined || (steps.indexOf(currentStep) <= steps.indexOf(nextStep))) {
        return nextStep
    }else{
        return currentStep
    }
}

const updateStep = (steps, currentStep, nextStep) => {     
    return new Promise((resolve, reject) => {
        if (currentStep.indexOf('completed') > -1){        
            return reject("Process is already complete");            
        }

        if(currentStep === nextStep){        
            return reject("Trying to process the same step")            
        }
    
        if (currentStep === undefined || (steps.indexOf(currentStep) <= steps.indexOf(nextStep))){             
            //messengerDispatch({type:"setStep", step:nextStep});
            return resolve({step:nextStep});            
        }else{
            return reject("Can't go back a  step") 
        }                
    });        
}

export {
    inCompletedState,
    getLatestState,
    updateState,
    updateUnitReservationStep,
    getUnitReservationStepProgress,
    getStepProgress,
    getLastStep,
    updateStep
}