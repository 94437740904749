import React from 'react';
import { Form, Radio, DatePicker } from 'formik-antd'
import {BlockRadioGroup} from './Responder'

const ResponderInterest = ({ formProps }) => {

    return (<React.Fragment>
        <Form.Item label="I'm looking to..." name="rentOrBuy">
            <Radio.Group name="rentOrBuy" defaultValue="">
                <Radio.Button value="buy">Buy</Radio.Button>
                <Radio.Button value="rent">Rent</Radio.Button>
                <Radio.Button value="either">Either</Radio.Button>
            </Radio.Group>
        </Form.Item >
        <Form.Item label="When are you looking to move?" name="expectedOccupation">
            <BlockRadioGroup name="expectedOccupation">
                <Radio.Button value="asap">As soon as possible</Radio.Button>
                <Radio.Button value="unknown">Don't know</Radio.Button>
                <Radio.Button value="deadline">A specific date</Radio.Button>
            </BlockRadioGroup>
        </Form.Item >
        {formProps.values.expectedOccupation === 'deadline' ?
            <Form.Item label="What date do you want move by?" name="occupationDate">
                <DatePicker name="occupationDate" showTime={false} placeholder="Select a date" />
            </Form.Item >
            : ""}

        <Form.Item label="Your current housing situation" name="buyerType">
            <BlockRadioGroup name="buyerType">
                <Radio.Button value="first-time-buyer">First time buyer</Radio.Button>
                <Radio.Button value="home-owner">Home owner</Radio.Button>
                <Radio.Button value="investor">Investor</Radio.Button>
            </BlockRadioGroup>
        </Form.Item >
    </React.Fragment>)
}

export default ResponderInterest;