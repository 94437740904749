import React, {useState, useEffect} from 'react';

//import {propertyEngineDb} from "../../MainRouter";
import ProgressList from "./ProgressList";
import styled from 'styled-components';
import {createUrlFromString} from "../../components/image/Image";
import FullBackgroundImage from "../../components/image/FullBackgroundImage";



const ProgressWrapper = styled.div`
    // background:green;
    width:100%;
`;

const ProgressHeader = styled.div`
    margin-top: 40px;
    // background:yellow;
    width:100%;
    height: 300px;
    position:relative;
`;

const ProgressHeaderInner = styled.div`
    position:relative;
    max-width: 768px;
    text-align: center;
    height:100%;
    margin: 0 auto;
`;

const ProgressHeaderContent = styled.div`
    position:absolute;
    bottom:2em;    
    background:rgba(255, 255, 255, 0.6);

    width: 100%;
    text-align: left;
    padding: 1em 2em;
    margin: 0 auto;
`;


const ProgressListWrapper = styled.div`
    // background:pink;
    max-width: 768px;
    text-align: left;
    padding: 2em 0;
    margin: 0 auto;
`;


export default function  Progress(props) {


    const [progressPosts,setProgressPosts] = useState([]);
    const [progressConfig,setProgressConfig] = useState({});


    //let db = propertyEngineDb.firestore();

    console.log("Rendering Progress",props.match.params)

    const params = props.match.params;
    const accountKey = params.accountKey;
    const developmentKey = params.developmentKey;

    let progressConfigRef = null;
    let progressPostsRef = null;
    /*
    let progressConfigRef = db.collection('progress').doc(accountKey).collection('channels').doc(developmentKey);
    let progressPostsRef = db.collection('progress')
                             .doc(accountKey)
                             .collection('posts').orderBy('dateCreated', 'desc');
    */

// .where('channels', 'array-contains', 'development')
//         .where('developmentPk','==',developmentKey) //

    function fetchProgressConfig(){
        progressConfigRef.get()
            .then(doc => {
                if (!doc.exists) {
                    console.log('No such document!');
                } else {
                    setProgressConfig(doc.data());
                    console.log('Document data:', doc.data());

                }
            })
            .catch(err => {
                console.log('Error getting document', err);
            });
    }

    function fetchProgressPosts(){
        console.log("fetchProgressPosts")
        progressPostsRef.get()
            .then(snapshot => {
                console.log("fetchProgressPosts:snapshot",snapshot)
                if (snapshot.empty) {
                    console.log('No posts found');
                } else {
                    const progressArray = []
                    snapshot.docs.forEach(doc => {
                        progressArray.push(doc.data())
                        // console.log(doc.id, '=>', doc.data());
                    });
                    setProgressPosts(progressArray);

                }
            })
            .catch(err => {
                console.log('Error getting document', err);
            });
    }


    //On load only// This is componentDidMount lifecycle
    useEffect( () => {
        console.log("are we triggering this?!@#")
        fetchProgressConfig();
        fetchProgressPosts();
    }, []);


    let leadImage;
    if(progressConfig && progressConfig.leadImage){
        console.log("leadImage",progressConfig.leadImage)
        leadImage = createUrlFromString(progressConfig.leadImage)
    } 



    return (
            <ProgressWrapper>
                {progressConfig? 
                <React.Fragment>
                    <ProgressHeader>                        
                        <FullBackgroundImage image={leadImage}>
                            <ProgressHeaderInner>
                                <ProgressHeaderContent>
                                <h1>{progressConfig.title}</h1>
                                <div>{progressConfig.description}</div>
                                </ProgressHeaderContent>
                            </ProgressHeaderInner>
                        </FullBackgroundImage>                     
                    </ProgressHeader>                
                    <ProgressListWrapper>
                        <ProgressList handleChange={() => {}} progress={progressPosts}/>
                    </ProgressListWrapper>
                </React.Fragment>
                : 'Loading'}
           </ProgressWrapper>
    );

}


