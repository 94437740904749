import React, { useState, useEffect } from 'react';
import { Collapse, Button,Spin } from 'antd';
import each from 'lodash/each';
import {Link } from 'react-router-dom';
import moment from 'moment';
import {postActionRequest} from  '../../services/PropertyPassService';

const { Panel } = Collapse;

const generateTermsArray = (termsText) => {
    const termsList = []
    each(termsText.split(/\r?\n/),(item)=>{
        termsList.push(item.trim());
    });
    return termsList
}
const generateUnitReservationData = (values) => {
    const data = {}

    let unitLabel = values.unitLabel
    const unitSource = {}
    if(values.unitSource === 'ism'){
        unitSource.source = 'ism'
        unitSource.mapKey = values.ismMapKey
        unitSource.developmentKey = values.ismDevKey
        unitSource.unitKey = values.ismUnitKey
        unitLabel = unitLabel || "Unit "+values.ismUnitKey
    }else if(values.unitSource === 'propertyengine'){
        unitSource.source = 'propertyengine'
        unitSource.account = values.account
        unitSource.development = values.development
        unitSource.unit = values.unit
        unitLabel = values.unit        
    } else{
        throw new Error("unsupported unit source type")
    }
    data.unitLabel = unitLabel
    data.developmentLabel = values.developmentLabel
    data.unitSource = unitSource

    if(values.selectedPlanId){
        data.selectedPlan = { id:values.selectedPlanId }
    }

    data.reservationFee = {
        type: values.reservationFeeType,
        label: values.reservationFeeLabel,
        amount: values.reservationFeeAmount
    }
    
    if (values.expiryDate !== "" && values.expiryDate !== undefined){
        var expiryDate = moment(values.expiryDate).toDate();
        var millisecondTimestamp = parseInt(expiryDate.getTime());
        data.expiryDate = millisecondTimestamp;
    }

    const mop = []
    if(values.paymentMethodEft){
        mop.push('manual-eft')
    }
    if(values.paymentMethodPeach){
        mop.push('peach-payments')
    }
    if(values.paymentMethodFnbScan){
        mop.push('fnb-scan')
    }
    data.paymentMethods = mop

    data.termsPdf = values.termsPdf
    data.termsList = generateTermsArray(values.termsList)

    data.returnPaths = [{label:values.returnPathLabel,url:values.returnPathLink}]
    return data
}

const generateDataForRequest = (values) => {
    const type = values.actionRequestType;
    console.log("generateDataForRequest", type,  values);
    const data = {
        application: 'propertyengine-simon',
        entityId: values.entity,
        invokerId: values.invoker,
        recipients: [{ name: values.recipientName, email: values.recipientEmail, phone: values.recipientMobile }],        
    }
    const actionData = {}
    if (type === "creditcheck") {
        actionData.type = "creditcheck"
    } else if (type === "responder") {
        actionData.type = "responder"
        const subActions = []
        if(values.actionMoveNow){
            subActions.push('move-now')
        }
        if(values.actionBrowsing){
            subActions.push('browsing')
        }
        if(values.actionMoreInfo){
            subActions.push('more-info')
        }  
        if(values.actionServiceOffering){
            subActions.push('service-offering')
        }                
        actionData.data = {"subActions":subActions , "developmentsOfInterest":values.developmentList}
    } else if (type === "reservation") {
        actionData.type = "reservation"
        actionData.data = generateUnitReservationData(values)
    }
    let actions = []
    actions.push(actionData)
    console.log("ACTIONS", actions)
    data.actions = actions

    return data
}


const DemoOutput = (props) => {

    const [response, setResponse] = useState(null);

    const requestData = generateDataForRequest(props.data);

    useEffect(()=>{
        postActionRequest(requestData).then(response =>{
            setResponse(response)
        })
    },[])

 

    return <div>
        <Button onClick={() => props.handleBack()} style={{marginBottom:10}}>Back</Button>
        <Collapse defaultActiveKey={['1']} >
            <Panel header="Quick links" key="1">
                {response ? response.recipients.map((item, index)=>{
                    return <Button key={index} href={`/once-off/action/${response.id}/${item.id}/`} target="_blank">{item.name}</Button>
                }):<Spin/>}
            </Panel>
            <Panel header="Request Data" key="2">
                <pre>{JSON.stringify(requestData,null,2)}</pre>
            </Panel>
            <Panel header="Response" key="3">
            {response ? <pre>{JSON.stringify(response, null, 2)}</pre>:<Spin/>}
            </Panel>
        </Collapse>
    </div>

}

export default DemoOutput