import React, {useState, useEffect, useContext} from 'react';

import { NarrowForm } from '../../../theme/DefaultTheme';
import { pathToReservationOnceOffRoute } from '../../../routes/publicRoutes';
import { withRouter, useHistory } from "react-router-dom";

import { Formik } from 'formik'
import { Form, Input,Checkbox} from 'formik-antd'
import IdNumber from "../../../components/display/IdNumber";
import * as Yup from 'yup';
import WizardContent from "../../../components/containers/WizardContent"
import TermsAndConditions from "../../../components/display/TermsAndConditions"
import { PrimaryBlockButton}  from '../../../components/button/Button';
import { postRecipientData } from '../../../services/PropertyPassService';
import OnceOff from "../OnceOff";
import {CountdownTicker} from "../../../components/progress/CountdownTicker";
import withReservation from './ReservationProvider'
import RoadBlock from '../../../components/containers/RoadBlock'
import { ActionRequestContext } from "../ActionRequestContext";

const ReservationPurchaser = (props) => {

    const [appContext] = useContext(ActionRequestContext);
    const { arToken, userToken, actionToken, currentAction, currentRecipient} = appContext;
    const [startState] = useState({termsAndConditions:false, idNumber:"",email:"", phone:"", ...currentRecipient});
    const [expiryDate, setExpiryDate] = useState(props.expiryDate);

    const nextUrl = pathToReservationOnceOffRoute(arToken, userToken, actionToken, "otp")
    const history = useHistory();

    const termsAndConditionsPresent = currentAction.termsList||currentAction.termsPdf;

    useEffect(() => {
        //console.log("if we land here it is because user has started the next step");   
        console.log("reservation purchaser.... useEffect");      
        props.updateLastStep("purchaser");        
    }, [])

    const handleTimesUp = () => {
        props.setExpiryDate(false);        
    }

    useEffect(() => {
        if (props.expiryDate !== expiryDate){            
            setExpiryDate(props.expiryDate);
        }       
    }, [props.expiryDate])

    const purchaserSchema = Yup.object({
        name: Yup.string()
          .min(2, 'Too Short!')
          .required('We need your full name please'),
        idNumber: Yup.string()
          .min(13, "We require all 13 digits")
          .required('A 13 digit South African ID Number is required'),
        email: Yup.string()
          .email('Invalid email')
          .required('An email address is required'),
        phone: Yup.string()
          .required('A valid South African mobile number is required')
          .matches(/[\+]?(276|277|278|06|07|08)[0-9]{7,8}/s,'Please only numbers that start with 06, 07, 08, 276, 277 or 278')
    });

    const termsSchema = Yup.object({
        termsAndConditions: Yup.boolean()
            .required("Please accept the terms and conditions")
            .oneOf([true], 'Please accept the terms and conditions'),
    })

    const validationSchema = termsAndConditionsPresent ? purchaserSchema.concat(termsSchema) : purchaserSchema;      
    return (
            <OnceOff>        
                <Formik 
                enableReinitialize={true}
                initialValues={startState}
                validationSchema={validationSchema}
                onSubmit={(values) => {
                    let data = {
                        name: values.name,
                        phone: values.phone,
                        idNumber: values.idNumber.trim(),
                        email: values.email
                    }                                        
                    postRecipientData(arToken, userToken, data).then((response)=>{
                        console.log("DATA?", data)
                        props.updateCurrentRecipient(data);                        
                        return props.postActionDataToServer({termsAccepted:values.termsAndConditions, purchaser:data});
                    }).then((response) =>{
                        history.push(nextUrl);
                    });                                                           
                }}
                render={(props) => (   
                    <RoadBlock blocked={appContext.blocked}>                                      
                        <WizardContent 
                            activeStep={2} totalSteps={6} title="Excellent!" info="To complete the reservation we are going to need some of your personal details"
                            afterHeader={(expiryDate)?<CountdownTicker expiryDate={expiryDate} handleTimesUp={handleTimesUp}/>:null}
                            >

                            <NarrowForm layout='vertical' size='large' onSubmit={props.handleSubmit}>
                                <Form.Item label="Full name" name="name">
                                    <Input name="name" placeholder="Enter your full name" size='large' />
                                </Form.Item>
                                <Form.Item label="South African ID Number" name="idNumber">                                
                                    <IdNumber name="idNumber" value={props.values.idNumber} />                                
                                </Form.Item >
                                <Form.Item  label="Email address" name="email">
                                    <Input name="email" placeholder="me@domain.com"  size='large'/>
                                </Form.Item >
                                <Form.Item label="Mobile phone number" name="phone">
                                    <Input name="phone" placeholder="South African phone number" size='large' />
                                </Form.Item >
                                {                                
                                    termsAndConditionsPresent ? <Form.Item  name="termsAndConditions">
                                        <Checkbox name="termsAndConditions" style={{marginBottom:10}}>I agree to Terms &amp; Conditions below</Checkbox>
                                        <TermsAndConditions termsList={currentAction.termsList} termsPdf={currentAction.termsPdf}/>                                                
                                    </Form.Item > : ""
                                } 
                                
                                <PrimaryBlockButton htmlType="submit" loading={props.isSubmitting}>Next</PrimaryBlockButton>

                            </NarrowForm>
                        </WizardContent>
                    </RoadBlock>
                )}/>
            </OnceOff>
    )
}

export default withRouter(withReservation(ReservationPurchaser))