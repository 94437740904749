import {Layout} from "antd";
import React from "react";
import Image from "../components/image/Image";
import styled, {withTheme} from "styled-components";

const { Header } = Layout;

export const PageHeader = styled(Header)`    
    background: ${props => props.theme.topbar.bg};
    padding:5px 20px;
    position: fixed;
    z-index: 1;
    width: 100%;
    height: 70px;
    line-height: 70px;
    border-bottom: ${props => props.theme.topbar.bottomBorder};
    overflow:hidden;
`;

export const PageHeaderLogoContainer = styled.a`
    float: left;    
    max-height: 60px;
    display:block;
    height: 60px;
    max-width: 150px;    
    margin-right: 10px;
`;

export const PageHeaderLogo = styled.div`
    img{
        max-height: 50px;
    }  
`;

export const LeftMenu = styled.div`
    float: left;    
    height: 60px;
    line-height: 60px;
    border: none;
`;

export const RightMenu = styled.div`
    float: right;    
    height: 60px;
    line-height: 60px;
    border: none;
`;
const TopBar = React.memo((props) => {
    
    return (
    <PageHeader className="header">
        <LeftMenu>
            {props.theme ? <PageHeaderLogo><Image json={props.theme.logo} defaultImage={props.theme.defaultLogo} /></PageHeaderLogo> :''}
        </LeftMenu>     
        <RightMenu>
            {props.theme && props.theme.coBrand ? <PageHeaderLogo><Image json={props.theme.coBrand} /></PageHeaderLogo> :''}
        </RightMenu>
    </PageHeader>
    )
})

export default withTheme(TopBar)
