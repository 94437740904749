import React, { useEffect, useState, useContext } from 'react';
import { NarrowForm, LoadingContainer} from '../../theme/DefaultTheme';
import { Button, Spin, Divider } from 'antd';
import { Formik } from 'formik'
import styled from "styled-components";
import { notification } from 'antd';
import { withRouter ,useHistory} from "react-router-dom";

import WizardContent from "../../components/containers/WizardContent"
import { pathToCreditCheckOnceOffRoute } from '../../routes/publicRoutes';
import OnceOff from "./OnceOff";
import { fetchActionSecurityQuestions, postVerificationAnswers } from '../../services/PropertyPassService';

import { ActionRequestContext } from "./ActionRequestContext";

const QuestionButton = styled(Button)`
&&&{
   white-space: normal;
   height: auto; 
   margin-bottom: 15px;
}
`;

const CreditSecurity = () => {

    const [appContext] = useContext(ActionRequestContext);
    const [securityState, setSecurityState] = useState({});
    const [arToken] = useState(appContext.arToken);
    const [activeQuestion, setActiveQuestion] = useState(0)
    const [answers, setAnswers] = useState([])
    const [complete, setComplete] = useState(false)
    const history = useHistory();


    const mockData = false; // This should be false when committed

    useEffect(() => {
        if (arToken) {
            console.log("Trigger find data for step");
            if (mockData) { // because there is a max number of times you can hit an ID it becomes a problem in development, this allows developers to just work with dummy content
                const tempData = { "verified": false, "questions": [{ "id": 7001, "title": "Have you worked at IMAGINE DEVELOPMENT?", "count": 1, "answers": { "14433082": "Yes", "14433083": "No" } }, { "id": 8036, "title": "In what year did you become a director CLEAVER CONSULTING?", "count": 1, "answers": { "14433084": "1991", "14433085": "2001", "14433086": "2000", "14433087": "2015", "14433088": "None of these" } }, { "id": 8061, "title": "What was the purchase price for the property at 31 OSTERLOH ROAD, CASSELDALE, SPRINGS, 1559?", "count": 1, "answers": { "14433099": "5450000", "14433100": "4000000", "14433101": "1650000", "14433102": "1250000", "14433103": "None of these" } }, { "id": 5002, "title": "Name one of your postal addresses", "count": 1, "answers": { "14433089": "01 CAMELIA STREET, STILL BAY, STILL BAY, 6674", "14433090": "01 MBHANYANA ROAD, ELLIOTDALE, ELLIOTDALE, 5070", "14433091": "19 RAVENSWOOD RD, KINGSBURGH, TABLE VIEW, WESTERN CAPE, 7441", "14433092": "01 HENRY MOREY STREET, WHITE RIVER, WHITE RIVER, 1240", "14433093": "None of these" } }, { "id": 6006, "title": "Name one of your home phone numbers?", "count": 5, "answers": { "14433094": "0100100360", "14433095": "0100101010", "14433096": "0100106117", "14433097": "0100107379", "14433098": "None of these" } }] }
                setSecurityState(tempData)

            } else {

                fetchActionSecurityQuestions(arToken, appContext.userToken)
                    .then(data => {
                        setSecurityState(data);
                    }).catch(err => {
                        console.log('Error getting step data', err);
                    });
            }
        }

    }, [arToken])




    useEffect(() => {
        if (securityState.verified) {
            console.log("Already verified, should redirect")
        } else {
            console.log("SECURITY QUESTIONS", securityState.questions)
        }
    }, [securityState])


    console.log("CreditSecurity", appContext);

    const verfiedSuccessUrl = pathToCreditCheckOnceOffRoute(appContext.arToken,appContext.userToken, "credit-report")
    const verfiedFailedUrl = pathToCreditCheckOnceOffRoute(appContext.arToken,appContext.userToken, "error") +"/verification-failure"


    const onQuestionSelection = (questionId, selectedValue) => {
        setActiveQuestion(activeQuestion + 1)
        let newAnswers = answers.concat({ question: questionId, answer: selectedValue })
        setAnswers(newAnswers)

        // If we have answered all the questions then submit
        if (activeQuestion === securityState.questions.length - 1) {
            setComplete(true);

            postVerificationAnswers(arToken, appContext.userToken, newAnswers).then(response => {
                console.log("postVerificationAnswers response",response)
                if (response.authenticated) {

                    history.push(verfiedSuccessUrl);
                } else {
                    history.push(verfiedFailedUrl);
                }
            }).catch(error => {
                notification['error']({
                    message: "Something went wrong",
                    description: error.message
                });
            })
        }
    }


    let questionToRender = <LoadingContainer><Spin tip="Loading security questions..."></Spin></LoadingContainer>;
    if (securityState.questions) {

        if (complete) {
            questionToRender = <LoadingContainer><Spin tip="Verifying your answers..."></Spin></LoadingContainer>;
        }
        else {
            let activeQuestionInfo = securityState.questions[activeQuestion];

            let items = Object.keys(activeQuestionInfo.answers).map(function (keyName, keyIndex) {
                return <QuestionButton block key={keyIndex} onClick={() => onQuestionSelection(activeQuestionInfo.id, keyName)}>{activeQuestionInfo.answers[keyName]}</QuestionButton>
            });
            questionToRender =  <Formik initialValues={{ '': '' }}
                    onSubmit={(values) => {
                        console.log("submitting", values)

                    }}>
                    <NarrowForm layout='vertical' size='large' >
                        <h3 style={{ textAlign: 'center',marginBottom:15}}>{activeQuestionInfo.title}</h3>
                        {items}
                    </NarrowForm>
                </Formik>
           
        }
    }

    return (
        <OnceOff>
            <WizardContent activeStep={4} totalSteps={5} image="/images/creditcheck/shield.svg" imageClass="middle">
                <div>
                    <h3 style={{marginBottom:15}}>Please answer the following 5 verification questions</h3>
                    <Divider />
                    {questionToRender}
                </div>
            </WizardContent>
        </OnceOff>
    )
}
export default withRouter(CreditSecurity);