import React , {useEffect} from 'react';


const RoadBlock = (props) => {
    
    useEffect(() => {        
        //console.log("WITH ROAD BLOCK!@#", props) 
    }, [])        
    
    let displayComponent = props.children
    
    if ((props.blocked && props.blocked.active)){
        if (props.blocked.displayComponent === null || props.blocked.displayComponent === undefined){
            displayComponent = <span>Default</span>
        }else{
            //Ideal here is to inject or be able to pass in components that you want to display to the user
            displayComponent = props.blocked.displayComponent
        }        
    }

    return (
        displayComponent
    )
    
}

export default RoadBlock;