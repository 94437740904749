import React, { useState, useContext } from 'react';
import styled from "styled-components";
import _ from 'lodash';
import qs from 'qs';
import { TextContent } from '../../../theme/DefaultTheme';

import { pathToReservationOnceOffRoute } from '../../../routes/publicRoutes';
import { withRouter, useHistory, Link } from "react-router-dom";
import { Spin } from 'antd';
import Icon from "../../../components/icon/Icon";
import { PrimaryBlockButton } from '../../../components/button/Button';

import WizardContent from "../../../components/containers/WizardContent"
import {fetchPaymentReference, fetchTransactionStatus, postActionData} from '../../../services/PropertyPassService';
import { ActionRequestContext } from "../ActionRequestContext";
import useInterval from "../../../hooks/useInterval";
import OnceOff from "../OnceOff";
import RoadBlock from '../../../components/containers/RoadBlock'
import withReservation from './ReservationProvider'
import {getUnitReservationStepProgress} from "../../../services/EventTriggerService";

const WaitingContainer = styled.div`

`;

const ErrorContainer = styled.div`

`;

const ReservationPaymentProcessing = (props) => {       

    const [appContext] = useContext(ActionRequestContext);
    const { arToken, userToken, actionToken, currentAction} = appContext;

    const params = props.match.params;  

    let queryParams = qs.parse(props.location.search, { ignoreQueryPrefix: true });
    let transactionId = params.transactionId;
    console.log("ReservationPaymentProcessing.queryParams",queryParams);
    const history = useHistory();

    const restartUrl = pathToReservationOnceOffRoute(arToken, userToken, actionToken, "payment");
    const nextUrl = pathToReservationOnceOffRoute(arToken, userToken, actionToken, "complete");

    const [paymentReference, setPaymentReference] = useState(currentAction.paymentReference);

    let [status, setStatus] = useState('initial')
    let [failure, setFailure] = useState(null)
    let [count, setCount] = useState(0);
    let [, setTransaction] = useState(null);
    const checkTransactionStatus = () =>{
        setCount(count + 1);
        fetchTransactionStatus(transactionId).then(transaction=>{
            console.log("Checking transaction status",transaction)

            setStatus(transaction.status)
            setTransaction(transaction)
            if(transaction.status === "paid"){
                let progress = getUnitReservationStepProgress("payment-completed");
                postActionData(appContext.arToken, appContext.actionToken, { type:"reservation", state: "completed", step:"payment-completed", paymentMethod: "peach-payments", paymentReference:paymentReference,progress:progress, awsTrigger:true, entireDataSet:true })

                history.push(nextUrl);
            }else if(transaction.status === "failed") {
                setFailure(transaction.description)
            }else{
               console.log("Still waiting....", transaction.status)     
            }

        
        })
    }

    useInterval(() => {
      if(status!== "paid" && status !== "failed"){
        checkTransactionStatus()
      }        
    }, 4000);

    let content = ""
    
    if(status === 'initial'){
        content = <WaitingContainer>
                    <Icon icon="checkmark-circle" color="green" style={{ fontSize: '4em', marginBottom: '1rem' }} />
                    <h2>Verifying payment</h2>
                    <p>Currently checking with the banks to verify that payment has cleared</p>
                    <Spin size="large" />
            </WaitingContainer>

    }else if (status === 'failed'){
        content = <ErrorContainer>
                    <Icon icon="cross-circle" color="red" style={{ fontSize: '4em', marginBottom: '1rem' }} />
                    <h2>Unable to take payment</h2>
                    <p>Unfortunately we were not able to take payment from your card because of the following reason</p>
                    <p><strong>{failure}</strong></p>
                    <PrimaryBlockButton><Link to={restartUrl}>Try again here</Link></PrimaryBlockButton>
                </ErrorContainer>  
    }
                         

    return (
        <OnceOff>
            <RoadBlock blocked={appContext.blocked}>         
                <WizardContent>
                    <TextContent style={{paddingTop:40,paddingBottom:40}}>                
                        {content}                    
                    </TextContent>
                </WizardContent>
            </RoadBlock>
        </OnceOff>
    )
}

export default withRouter(withReservation(ReservationPaymentProcessing))