import React from 'react';
import styled from "styled-components";

const StyledOnceOffPageContent = styled.div`
    background: ${props => props.theme.contentBgColor};    
    max-width: ${props => props.theme.onceOffPages.maxWidth};
    text-align: center;
    margin: 0 auto;  
    .inner-content{
        padding: 50px 40px;        
    }
    
    &.with-title{
        .inner-content{ padding-top: 0px; };
    }
    &.with-footer{
        .inner-content{ margin-bottom: 0px;}
    }
`;

const StyledOnceOffPageHeader = styled.div`
    padding: 20px 40px;
    h2 {
        margin-bottom: 0;
    }
`;

const OnceOffPageContent = ({title,footer, ...props}) => {


    let inlineStyle = {};
    if(props.type==='wide'){
        inlineStyle = {maxWidth:'100%'}
    }

    let className = "";
    let titleNode = null;
    
    if(title){
        className += " with-title";
        titleNode = <StyledOnceOffPageHeader><h2>{title}</h2></StyledOnceOffPageHeader>;
    }

    if(footer){
        className += " with-footer"
    }


    return <StyledOnceOffPageContent className={className} {...props} style={inlineStyle}>            
            {titleNode}
            <div className="inner-content">
            {props.children}
            </div>
            {footer}
           </StyledOnceOffPageContent>
}

export default OnceOffPageContent
