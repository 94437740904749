import React from "react";
// import Login from "./Login";
import Join from "../pages/auth/Join";

import Dashboard from "../pages/dashboard/Dashboard";
import Progress from "../pages/progress/Progress";
import CreditStart from "../pages/once-off/CreditStart";
import CreditOtp from "../pages/once-off/CreditOtp";
import CreditIdentity from "../pages/once-off/CreditIdentity";
import CreditSecurity from "../pages/once-off/CreditSecurity";

const publicRoutes = [
    { name: "Join", path: "/join", exact: true, main: (props) => <Join  {...props} /> },
    // { name: "Login", path: "/login", exact: true, main: (props) => <Login  {...props} /> },
    { name: "Dashboard", path: "/dashboard", exact: true, main: (props) => <Dashboard  {...props} /> },
    { name: "Credit Check - Start", path: "/account/:accountKey/:accountSlug/once-off/:token/start", exact:true, main: (props) => <CreditStart  {...props} /> },
    { name: "Credit Check - OTP", path: "/account/:accountKey/:accountSlug/once-off/:token/otp", exact:true, main: (props) => <CreditOtp  {...props} /> },
    { name: "Credit Check - Identity", path: "/account/:accountKey/:accountSlug/once-off/:token/identity", exact:true, main: (props) => <CreditIdentity  {...props} /> },
    { name: "Credit Check - Security", path: "/account/:accountKey/:accountSlug/once-off/:token/security", exact:true, main: (props) => <CreditSecurity  {...props} /> },
    { name: "Account Progress", path: "/account/:accountKey/:accountSlug/progress", exact: true, main: (props) => <Progress  {...props} /> },
    { name: "Development Progress", path: "/account/:accountKey/:accountSlug/progress/:developmentKey", exact: true, main: (props) => <Progress  {...props} /> },
];


export const pathToCreditCheckOnceOffRoute = (arToken,userToken,path) => {
    return `/once-off/action/${arToken}/${userToken}/credit-check/${path}`
}

export const pathToResponderOnceOffRoute = (arToken,userToken,actionToken,path) => {
    return `/once-off/action/${arToken}/${userToken}/${actionToken}/responder/${path}`
}

export const pathToReservationOnceOffRoute = (arToken,userToken,actionToken,path) => {
    return `/once-off/action/${arToken}/${userToken}/${actionToken}/reservation/${path}`
}

export const pathToOnceOffError = (reason) =>{
    return `/once-off/error/`+reason
}

export default publicRoutes;