import React, {useEffect,useState, useContext} from 'react';
import { NarrowForm } from '../../theme/DefaultTheme';
import { pathToCreditCheckOnceOffRoute } from '../../routes/publicRoutes';
import { withRouter, useHistory } from "react-router-dom";
import { notification } from 'antd';

import { Formik } from 'formik'
import { Form, Input} from 'formik-antd'
import * as Yup from 'yup';
import WizardContent from "../../components/containers/WizardContent"
import { PrimaryBlockButton}  from '../../components/button/Button';
import { fetchActionRequestRecipientStep,fetchActionRequestRecipient, postCreditCheckStart } from '../../services/PropertyPassService';
import {ActionRequestContext} from "./ActionRequestContext";
import OnceOff from "./OnceOff";



const CreditStart = (props) => {
    console.log("Rendering Credit Start");
    
    const params = props.match.params;

    const [appContext, setAppContext] = useContext(ActionRequestContext);
    const [startState, setStartState] = useState({});
    const [arToken] = useState(params.arToken);
    const [userToken] = useState(params.userToken);

    const nextUrl = pathToCreditCheckOnceOffRoute(arToken,userToken, "otp")
    const history = useHistory();


   

    useEffect(() =>{
        console.log("Trigger find data for step",arToken);
        if(arToken){
            fetchActionRequestRecipientStep(arToken,userToken,"start")
            .then(data =>{
                setStartState(data);
            }).catch(err => {
                fetchActionRequestRecipient(arToken,userToken).then(data=>{
                    setStartState(data)
                })
                console.log('Error getting step data', err);
            });
        }
    },[arToken])

    
    

    const StartSchema = Yup.object().shape({
        name: Yup.string()
          .min(2, 'Too Short!')
          .required('We need your full name please'),
        email: Yup.string()
          .email('Invalid email')
          .required('An email address is required'),
        phone: Yup.string()
            .required('A valid South African mobile number is required')
            .matches(/[\+]?(276|277|278|06|07|08)[0-9]{7,8}/s,'Please only numbers that start with 06, 07, 08, 276, 277 or 278')
      });


      console.log("REndering",startState)

    return (
            <OnceOff><Formik 
                enableReinitialize={true}
                initialValues={startState}
                validationSchema={StartSchema}
                onSubmit={(values, actions) => {
                    
                    postCreditCheckStart(arToken,userToken,values.email, values.name, values.phone, values.termsAndConditions).then(response=>{
                        actions.setSubmitting(false);
                        setAppContext((state) =>({...state,otpVerificationId:response.verificationId.sid})); 
                        console.log("appContext",appContext)
                        history.push(nextUrl);
                        
                    }).catch(error=>{
                        const errorData = error.data;
                        const code = errorData.code;
                        if(code === 60200){
                            notification['error']({
                                message: "Invalid mobile number",
                                description: "You need to enter a valid mobile number to be able to continue, the mobile should be registered against your name"
                              });
                        }else {
                            console.log(error)
                            actions.setSubmitting(false);
                            notification['error']({
                                message: "Something went wrong",
                                description: error.data.message
                              });
                        }                        
                    });
                           
                }}
                render={(props) => (
                    <WizardContent activeStep={1} totalSteps={5} title="Welcome" info="We've prefilled some of your information. Please check that it's correct and complete the rest if necessary.">

                        <NarrowForm layout='vertical' size='large' onSubmit={props.handleSubmit}>
                            <Form.Item label="Full name" name="name">
                                <Input name="name" placeholder="Enter your full name" size='large' />
                            </Form.Item>
                            <Form.Item  label="Email address" name="email">
                                <Input name="email" placeholder="me@domain.com"  size='large'/>
                            </Form.Item >
                            <Form.Item label="Mobile phone number" name="phone">
                                <Input name="phone" placeholder="South African phone number" size='large' />
                            </Form.Item >
                            {/* <Form.Item  name="termsAndConditions">
                                <Checkbox  name="termsAndConditions">I agree to <a href="https://www.google.com" target="_blank">terms and conditions</a></Checkbox>
                            </Form.Item > */}
                            <PrimaryBlockButton htmlType="submit" loading={props.isSubmitting}>Next</PrimaryBlockButton>

                        </NarrowForm>
                    </WizardContent>

                )}/></OnceOff>
    )
}

export default withRouter(CreditStart)