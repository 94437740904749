import React from 'react';
import styled from "styled-components";
import {Divider, Timeline, Typography} from "antd";
import Icon from "../../components/icon/Icon";
// import {PROGRESS_CATEGORIES} from "../../constraints/constants";
import Gallery from "../../components/gallery/Gallery";
import Video from "../../components/video/Video";

const {Title, Text} = Typography;

const StyledProgressItem = styled(Timeline.Item)`
&&&{
    .ant-timeline-item-tail{
        border-color:#737A80;
        opacity: 0.5;
    }
    
    .ant-timeline-item-head-custom{
        top: 15px;
        height: 26px;
        width: 24px;
        border-radius: 24px;
        padding: 0;
        margin: 0;
    }
    
    .ant-timeline-item-content{
        max-width: 800px;
        background: #FFF;
        padding: 1.5em;
        top: 14px;
        margin-left: 44px;
        border-radius: 0 10px 10px 10px ;
        h2{
            margin-bottom: 0.5em;
            line-height: 1em;
        }
        &:after{
            position:absolute;
            top:0;
            left:-20px;
            content:'';
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 0 20px 20px 0;
            border-color: transparent #FFF transparent transparent;
        }
    }
}
`;

const ProgressLineItem = (props) => {

    const {item} = props;

    let videos = item.videos.map( (video) => {
        return <Video json={video} width={1024} height={768}/>
     });

    return(
        <StyledProgressItem key={props.key} dot={<Icon icon="checkmark-circle" style={{ fontSize: '26px' }} />} >
            <Title level={2}>
                {item.title}
            </Title>
            <Text type="secondary">
                <p dangerouslySetInnerHTML={{ __html: item.text }}/>
            </Text>
            {videos}

            {item.images.length>0 ? <Gallery images={item.images} imageRatio="6:9"/> : '' }
            <Divider dashed />
            {/* <Tag>Published: {item.publishedDate?moment(item.publishedDate).format("DD-MM-YYYY hh:mm a"):'-'}</Tag>
            <Tag>Status: {startDate > now ?"Pending": (item.approvedDate ? "Published" : (item.userPublishedDate ? "Awaiting Moderation" : "Draft"))}</Tag>
            <Tag>Category: {PROGRESS_CATEGORIES.find(task => task.key === item.category).label}</Tag> */}
        </StyledProgressItem>
    )
};

export default ProgressLineItem;