import axios from 'axios';
import {db} from '../MainRouter'

/**
 * NOTE : Due to the horrible delay we are currently struggling with when it comes to colb boot times
 * certain functions are now hitting the DB directly, their old functions now have a suffix of FS for FunctionService
 */

//Start time is for debugging purposes
let startTime = new Date();
//small tool
const getElapsedTime = (step) => {
    console.log( "Step " + step + " done, time since step", (new Date().getTime() - startTime)/1000)
}

// Url
const url = process.env.REACT_APP_PROPERTYPASS_ENDPOINT;
//const url = 'https://us-central1-propertypass-demo.cloudfunctions.net/app';

const NON_EXISTANT = "non-existant";
const ENTITY_DOES_NOT_EXIST = "entity-does-not-exist";
const USER_DOES_NOT_EXIST = "user-does-not-exist";

// UTILITIES
export const getDocDataList = (docs) =>{
    let dataList = []
    docs.forEach(doc=>{
        const data = doc.data()
        data.id = doc.id
        dataList.push(data)
    })
    return dataList
}

// Endpoints
const actionRequestEndpoints = "action-requests"

const ping = () =>{
    //trigger cold boot
    return axios.get(url+'/ping').then(response=> console.log(response.data));
}

// Presets...
const postPreset = (data) => {
    return axios.post(url+"/presets", data)
}

const putPreset = (presetId, data) => {
    return axios.put(url+"/presets/"+presetId, data)
}

const fetchPresets = () => {
    return axios.get(url+"/presets").then(response=>response.data);
} 

const fetchPreset = (presetId) => {
    return axios.get(url+"/presets/"+presetId).then(response=>response.data);
}

// PUBLIC USERS
// -------------
export const savePublicUser = (uid,data) => {
    console.log("savePublicUser save", uid,data)

    return db.collection('publicUsers').doc(uid).set(data).then(result =>{
        return uid
    })
}

export const fetchPublicUser = (uid) => {
    return db.collection('publicUsers').doc(uid).get()
}

export const fetchPublicUserLaunchData = (uid, launchId) =>{
    return db.collection('publicUsers').doc(uid).collection('launches').doc(launchId).get()
}
export const fetchPublicUserLaunchList = (uid) =>{
    return db.collection('publicUsers').doc(uid).collection('launches').get()
}

export const savePublicUserLaunchData = (uid, launchId,data) => {
    console.log("savePublicUserLaunchData",uid,launchId)

    return db.collection('publicUsers').doc(uid)
             .collection('launches').doc(launchId).set(data,{merge:true})
}


// Fetch an entity given a entityId
const fetchEntity = (entityId) => {
    startTime = new Date();
    getElapsedTime("fetching entity " + entityId);
    return db.collection('entities').doc(entityId).get().then(doc => {
        getElapsedTime("Entity has been retried");
        if (!doc.exists) {
            console.log(': ' + entityId)
            throw new Error(ENTITY_DOES_NOT_EXIST)
        } else {            
            let documentData = doc.data();
            documentData.id = doc.id;
            getElapsedTime("Returning data");
            return documentData;
        }
    });
}

export const fetchLaunchInfo = (launchId) => {
    return db.collection('launchPortals').doc(launchId).get()
}


export const fetchLaunchSections = (launchId) => {
    return db.collection('launchPortals').doc(launchId).collection("preLaunchInformation").get()
}


const fetchEntities = () => {
    return axios.get(url+'/entities').then(response=>response.data);
}

/**
 * Fetch a user given a userId
 */ 
const fetchUser = (userId) => {
    getElapsedTime("fetching user " + userId);
    return db.collection('users').doc(userId).get().then(doc => {
        getElapsedTime("fetching user Complete ");
        if (!doc.exists) {
            console.log(': ' + userId)
            throw new Error(USER_DOES_NOT_EXIST)
        } else {
            getElapsedTime("Returning user");            
            return doc.data();
        }
    })
}


/**
 * Fetch all users (this should be locked behind some permission)
 */ 
const fetchUsers = () => {
    return axios.get(url+'/users').then(response=>response.data);
}

// Fetches the action but the given token
const fetchActionByToken = (token) => {
    startTime = new Date();
    getElapsedTime("Fetching action Token")
    let actionTokenRef = db.collection('actionRequests').doc(token);
    return actionTokenRef.get()
        .then(doc => {
            getElapsedTime("Document fetched for ARTOKEN")
            if (!doc.exists) {
                console.log('Could not find the action request based on token: ', token);

                return new Error("Invalid Action Request")
            } else {
                getElapsedTime("Fetching action documents")
                let arData = doc.data();                
                return fetchActionsForActionRequest(token).then(actionDocs => {
                    getElapsedTime("Action fetch completed")
                    if (arData) {
                        let actionData = []
                        actionDocs.forEach(actionDoc => {
                            let actionMap = actionDoc.data()
                            actionMap.id = actionDoc.id
                            actionData.push(actionMap)
                        })
                        getElapsedTime("Returning all data")
                        arData.actionData = actionData;
                        return arData;                        
                    }
                })
            }
            //getElapsedTime(startTime, "Document fetched");
            //return doc.data();
        });
   
    /*return new Promise((resolve, reject) => {
        axios
        .get(url+'/'+actionRequestEndpoints+'/'+token)
        .then(response => {
            resolve(response.data)            
        }).catch(error => {
            reject(error.response)
        });       
    })*/
}



const fetchActionsForActionRequest = (actionRequestId) =>{
    return db.collection('actionRequests').doc(actionRequestId).collection('actions').get()
        .then(snapshot => {
            let data = []
            snapshot.forEach(doc => {                
                data.push(doc)
            })
            return data
        })
}

// Fetches the recipient of the action request
const fetchActionRequestRecipient = (arToken,userToken) => {

    return new Promise((resolve, reject) => {
        axios
        .get(url+'/'+actionRequestEndpoints+'/'+arToken+'/recipients/'+userToken)
        .then(response => {
            resolve(response.data)            
        }).catch(error => {
            reject(error.response)
        });       
    })
}


// Fetches the current step for this action and recipient
const fetchActionSecurityQuestions = (arToken,userToken) => {

    return new Promise((resolve, reject) => {
        axios
        .get(url+'/'+actionRequestEndpoints+'/'+arToken+'/'+userToken+'/credit-check/security')
        .then(response => {
            resolve(response.data)            
        }).catch(error => {
            reject(error.response)
        });       
    })
}


// Fetches the current step for this action and recipient
const fetchActionRequestRecipientStep = (arToken,userToken,step) => {

    return new Promise((resolve, reject) => {
        axios
        .get(url+'/'+actionRequestEndpoints+'/'+arToken+'/recipients/'+userToken+'/steps/'+step)
        .then(response => {
            resolve(response.data)            
        }).catch(error => {
            reject(error.response)
        });       
    })
}

const fetchDevelopmentInformationMap = (developmentArray) => {    
    let csvList =  developmentArray.join(",");
    return axios.get(url+'/development-information?developments='+csvList).then(response=>response.data);
}

const fetchUnitData = (unitKey) => {
    return axios.get(url+'/units/'+unitKey).then(response=>response.data);
}

const postUnitSource = (unitSource, forceRetry) => {
    console.log("unitSource", unitSource)
    if (forceRetry === undefined){
        forceRetry = false;
    }
    unitSource.forceRetry = forceRetry;
    return axios.post(url+'/units/fetch-unit', unitSource).then(response =>{
        return response;
    })
}


// Fetches the current step for this action and recipient
// const putActionRequestRecipientStep = (arToken,userToken,step,data) => {
//     return new Promise((resolve,reject)=>{
//         axios.put(url+'/'+actionRequestEndpoints+'/'+arToken+'/recipients/'+userToken+'/steps/'+step, data)
//         .then(response =>{
//             resolve(response.data)
//         }).catch(error => {
//             console.log(error.response.data)
//             reject(error.response)
//         });
//     })
// }

const postCreditCheckStart = (arToken,userToken,email, name, phone, terms)=>{
    return new Promise((resolve,reject)=>{
        let data = {
            email: email,
            name: name,
            phone: phone, 
            terms: terms
        }
        axios
        .post(url+'/action-requests/'+arToken+'/'+userToken+'/credit-check/start',data)
        .then(response =>{
            resolve(response.data)
        }).catch(error => {
            console.log(error.response.data)
            reject(error.response)
        });
    })
}

const postSendOtp = (arToken, tokenType, token, phoneNumber) => {
    let data = {phone:phoneNumber}
    return axios
    .post(url+'/action-requests/'+arToken+'/send-otp/'+tokenType+'/'+token,data)
    .then(response =>{
        return response.data
    }).catch(error => {
        console.log(error.response.data)
        throw new Error(error.response);
    });
}

const postConfirmOtp = (arToken, tokenType, token, verificationId, otp) => {
    let data = {otp:otp, verificationId:verificationId}
    return axios
    .post(url+'/action-requests/'+arToken+'/confirm-otp/'+tokenType+'/'+token,data)
    .then(response =>{
        return response.data
    }).catch(error => {
        console.log(error.response.data)
        throw new Error(error.response);
    });
} 

const postOtpResend = (arToken,userToken,phone)=>{
    return new Promise((resolve,reject)=>{
        let data = {
            phone: phone
        }

        axios
        .post(url+'/action-requests/'+arToken+'/'+userToken+'/resend-otp',data)
        .then(response =>{
            resolve(response.data)
        }).catch(error => {
            console.log(error.response.data)
            reject(error.response)
        });
    })
}



const postOtpVerification = (arToken,userToken,verificationId,otp)=>{
    return new Promise((resolve,reject)=>{
        let data = {
            verificationId: verificationId,
            otp: otp
        }
        axios
        .post(url+'/action-requests/'+arToken+'/'+userToken+'/credit-check/otp',data)
        .then(response =>{
            resolve(response.data)
        }).catch(error => {
            reject(error.response)
        });
    })
}

const postIdNumber = (arToken,userToken,idNumber) => {
    return new Promise((resolve,reject)=>{

        let data = {
            idNumber: idNumber
        }
        axios
        .post(url+'/action-requests/'+arToken+'/'+userToken+'/credit-check/identity',data)
        .then(response =>{
            resolve(response.data)
        }).catch(error => {
            reject(error.response)
        });
    });
};

const postVerificationAnswers = (arToken,userToken,answers) =>{
    return new Promise((resolve, reject)=>{
        axios
        .post(url+'/action-requests/'+arToken+'/'+userToken+"/credit-check/security",{answers:answers})
        .then(response =>{
            resolve(response.data)
        }).catch(error =>{
            reject(error.response)
        });
    });
}


const fetchCreditReport = (arToken, userToken) => {
    return new Promise((resolve, reject) => {
        axios
        .get(url+'/action-requests/'+arToken+'/'+userToken+"/credit-check/credit-report")
        .then(response => {
            resolve(response.data)            
        }).catch(error => {
            reject(error.response)
        });       
    })
}

/** New POST AND GET's */

const postActionRequest = (data) => {
    return new Promise((resolve, reject) => {
        return axios
        .post(url+'/action-requests',data)
        .then(response =>{
            resolve(response.data)
        }).catch(error => {
            console.log(error)
            reject(error)
        });
    })
}


const postActionData = (arToken, actionToken, data)=>{    
    return new Promise((resolve,reject)=>{       
        return axios
        .put(url+'/action-requests/'+arToken+'/actions/'+actionToken ,data)
        .then(response =>{
            resolve(response.data)
        }).catch(error => {
            console.log(error.response.data)
            reject(error.response)
        });
    })
}

const fetchActionData = (arToken, actionToken) => {
    startTime = new Date();
    getElapsedTime("fetching action Data " + arToken +  ", " + actionToken)
    let actionRef = db.collection('actionRequests').doc(arToken).collection('actions').doc(actionToken);
    return actionRef.get().then(actionDoc => {
        getElapsedTime("Action data fetched");
        if (!actionDoc.exists) {
            console.log("NON_EXISTANT", NON_EXISTANT)
            throw new Error(NON_EXISTANT);
        } else {
            getElapsedTime("Action data returned");            
            return actionDoc.data();
        }
    })
}

const fetchActionDataFS = (arToken, actionToken) => {
    return axios
        .get(url+'/action-requests/'+arToken+'/actions/'+actionToken)
        .then(response => {
            return response.data;
        }).catch(error => {
            throw new Error(error.response)
        });       
    
}

const postRecipientData = (arToken, userToken, data)=>{
    return new Promise((resolve,reject)=>{               
        axios
        .put(url+'/action-requests/'+arToken+'/recipients/'+userToken ,data)
        .then(response =>{
            resolve(response.data)
        }).catch(error => {
            console.log(error.response.data)
            reject(error.response)
        });
    })
}

const fetchRecipientData = (arToken, userToken) => {
    startTime = new Date();
    getElapsedTime("fetching recipient Data " + arToken +  ", " + userToken)
    let recipientRef = db.collection('actionRequests').doc(arToken).collection('recipients').doc(userToken);
    return recipientRef.get().then(recipientDoc => {
        getElapsedTime("recipient Data received");
        if (!recipientDoc.exists) {
            console.log("NON_EXISTANT", NON_EXISTANT)
            throw new Error(NON_EXISTANT);
        } else {
            getElapsedTime("Recipient fetch complete");            
            return recipientDoc.data();
        }
    })
}



// const fetchRecipientDataFS = (arToken, userToken) => {
//     return new Promise((resolve, reject) => {
//         axios
//         .get(url+'/action-requests/'+arToken+'/recipients/'+userToken)
//         .then(response => {
//             resolve(response.data)
//         }).catch(error => {
//             reject(error.response)
//         });
//     })
// }

const fetchPeachPaymentsFormData = (arToken,actionToken,transactionId, forceNew) => {

    return axios.post(url+'/payment-gateways/'+arToken+'/'+actionToken+'/peachpayments/'+(transactionId?transactionId:''),{forceNew:forceNew}).then(response => {        
        return response.data;
    })    
}

const fetchEftData = (arToken,actionToken) => {

    return axios.get(url+'/payment-gateways/'+arToken+'/'+actionToken+'/eft').then(response => {        
        return response.data;
    })    
}


const fetchPaymentReference = (arToken,actionToken) => {

    return axios.get(url+'/payment-gateways/'+arToken+'/'+actionToken+'/reference').then(response => {        
        return response.data;
    })    
}


const fetchTransactionStatus = (transactionId) =>{
    return axios.get(url+'/payment-gateways/transactions/'+transactionId).then(response =>{
        return response.data;
    })
}

export {
    ping,
    postPreset,
    putPreset,
    fetchPresets,
    fetchPreset,
    fetchEntity,
    fetchEntities,
    fetchUser,
    fetchUsers,
    fetchActionByToken,
    fetchActionRequestRecipient,    
    fetchActionRequestRecipientStep,
    fetchActionSecurityQuestions,
    fetchCreditReport,
    fetchDevelopmentInformationMap,
    fetchUnitData,
    postSendOtp,
    postConfirmOtp,
    postOtpResend,
    postCreditCheckStart,
    postOtpVerification,
    postIdNumber,
    postVerificationAnswers,
    postActionRequest,
    postActionData,
    fetchActionData,
    postRecipientData,
    fetchRecipientData,
    fetchPeachPaymentsFormData,
    fetchEftData,
    fetchPaymentReference,
    fetchTransactionStatus,
    postUnitSource,
}
 