/**
 * This service is for all incentive related actions
 */
import React from 'react';
import {db} from "../MainRouter";


export const getUserIncentiveKey = (incentive) =>{
    return incentive.taskForm
}

export const hasMatchingCompleteIncentive = (completedIncentives,possibleIncentive) =>{

    const matchingIncentive = completedIncentives.find(i=>{
        if(i.taskType==="form" && i.id===possibleIncentive.taskForm){
            return true
        }else if (i.taskType==="register"){
            return true
        }
        return false
    })

    // console.log("found matchingIncentive  for ",incentiveId,completedIncentives,incentive,matchingIncentive)
    return matchingIncentive !== null && matchingIncentive !==undefined
}


/**
 * When a user completes an incentive action certain things need to happen, namely:
 * - if the incentive was to fill in some form information then it needs to save that data
 * - if there was a discount associated to then that discount needs to be added to the user data for the launch
 * @param uid
 * @param launchId
 * @param incentive
 */
export const recordCompletedIncentive = (uid, launchId, incentive, data) =>{

    let promises = []
    if(incentive.task==='form'){
        // record form data
        let incentiveData = {}
        incentiveData.taskType = "form"
        incentiveData.formData = data
        promises.push(db.collection('publicUsers').doc(uid).collection('launches').doc(launchId)
            .collection('completedIncentives').doc(incentive.taskForm)
            .set(incentiveData))
    }

    if(incentive.type==='discount'){
        // record the discount
        promises.push(db.collection('publicUsers').doc(uid).collection('launches').doc(launchId)
            .collection('discountsEarned').doc(incentive.discountCode)
            .set({timestampCreated:new Date().valueOf(),dateCreated:new Date() }))
    }

    return Promise.all(promises)
}

export const getCompletedIncentives = (uid,launchId) =>{
    console.log("getCompletedIncentives",uid,launchId)
    return db.collection('publicUsers').doc(uid)
        .collection('launches').doc(launchId)
        .collection('completedIncentives').get()
}