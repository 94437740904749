import React from 'react';

/**
 * Display a text area content with multiple lines as text with brs
 */
const DisplayTextArea = ({text, ...props}) => {


    return text
        .split('\n')
        .map((item, idx) => {
            return (
                <React.Fragment key={idx}>
                    {item}
                    <br/>
                </React.Fragment>
            )
        })
}
export default DisplayTextArea