import React from 'react';
import styled from "styled-components";
import Icon from '../icon/Icon';
import PropTypes from 'prop-types';

const Container = styled.div`
    float:left;
    text-align: center;
    margin-bottom: 1em;
    h4{
        font-weight: bold;
    }
`;

const typeMap = {
    "type": { label: "Type", icon: 'building-type' },
    "bedrooms": { label: "Bedrooms", icon: 'bed' },
    "bathrooms": { label: "Bathrooms", icon: 'bath' },
    "garages": { label: "Garages", icon: 'garage' },
    "parking": { label: "Parking", icon: 'garage' },
    "area": { label: "Area", icon: 'pencil-ruler', suffix: 'sqm' },
}


const PropertyAttr = ({ type, label, value, ...props }) => {

    const typeInfo = typeMap[type];
    const heading = label ? label : typeInfo.label;
    
    return (value === undefined || !value)?"":<Container>
        <Icon icon={typeInfo.icon} style={{ fontSize: '1.4em' }} />
        <h4>{heading}</h4>
        <span>{value} {props.suffix ? props.suffix : ''}</span>
    </Container>
}

PropertyAttr.propTypes = {
    type: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default PropertyAttr;