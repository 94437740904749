import React from 'react';
import { Form, Radio, Select, Input } from 'formik-antd'
import {BlockCheckboxGroup} from './Responder'
const { Option } = Select;


const ResponderMarketingEnquiry = () => {

    const categories = [
        { value: "access-control", label: "Access Control" },
        { value: "architects", label: "Architects" },
        { value: "association", label: "Association" },
        { value: "connectivity", label: "Connectivity" },
        { value: "estate-management", label: "Estate Management" },
        { value: "estate-specialists", label: "Estate Specialists" },
        { value: "finance", label: "Finance" },
        { value: "golfing", label: "Golfing" },
        { value: "health-fitness", label: "Health &amp; Fitness" },
        { value: "home-entertainment", label: "Home Entertainment" },
        { value: "home-improvement", label: "Home Improvement" },
        { value: "home-loans", label: "Home loans" },
        { value: "investment", label: "Investment" },
        { value: "landscaping", label: "Landscaping" },
        { value: "legal-services", label: "Legal Services" },
        { value: "private-online-high-school", label: "Private Online High School" },
        { value: "property-investment", label: "Property Investment" },
        { value: "property-sales", label: "Property Sales" },
        { value: "property-statistics", label: "Property Statistics" },
        { value: "security", label: "Security" },
        { value: "other", label: "Other" }
    ];

    const regions = [
        { label: "Gauteng", value: "Gauteng" },
        { label: "Western Cape", value: "Western Cape" },
        { label: "KwaZulu Natal", value: "KwaZulu Natal" },
        { label: "Free State", value: "Free State" },
        { label: "Mpumalanga", value: "Mpumalanga" },
        { label: "North West", value: "North West" },
        { label: "Limpopo", value: "Limpopo" },
        { label: "Northern Cape", value: "Northern Cape" }
    ]
    return <React.Fragment>
        <Form.Item label="Do you offer a service or product?" name="offerType">
            <Radio.Group name="offerType">
                <Radio.Button value="product">Product</Radio.Button>
                <Radio.Button value="service">Service</Radio.Button>
                <Radio.Button value="both">Both</Radio.Button>
            </Radio.Group>
        </Form.Item >
        <Form.Item label="In which regions is this available?" name="offerRegion">
            <BlockCheckboxGroup name="serviceProviderRegion" options={regions} />
        </Form.Item>
        <Form.Item label="What category does your offering fall under?" name="offerCatetory">
            <Select name="offerCatetory" placeholder="Select category">
                {categories.map(category => (
                    <Option key={category.value}>{category.label}</Option>
                ))}
            </Select>
        </Form.Item>
        <Form.Item label="Please describe in detail what you are offering" name="offerInformation">
            <Input.TextArea name="offerInformation" placeholder="Describe your offering..." />
        </Form.Item >
        <Form.Item label="Please give a link to where we can find out more" name="offerLink">
            <Input name="offerLink" placeholder="https://www.myservice.co.za" />
        </Form.Item >
    </React.Fragment >
}


export default ResponderMarketingEnquiry;