import React, {useState, useEffect} from 'react';
import theme from "../../theme/DefaultTheme";
import {ping, fetchActionByToken, fetchActionData, fetchEntity,fetchUser, fetchRecipientData} from '../../services/PropertyPassService';
import { withRouter } from "react-router-dom";
import _ from 'lodash';

const startTime = new Date().getTime()

const ActionRequestContext = React.createContext([{},()=>[]]);

const ActionRequestProviderTemp = (props) => {

    const [arToken, setArToken] = useState(props.match.params.arToken)
    const [userToken, setUserToken] = useState(props.match.params.userToken)
    const [actionToken, setActionToken] = useState(props.match.params.actionToken)
    const [state, setState] = useState({ arToken:arToken,userToken:userToken,actionToken:actionToken,theme:theme});
    const [readyToRender, setReadyToRender] = useState(false);
    const [actionRequest, setActionRequest] = useState(null);    

    const getElapsedTime = () => {
        return (new Date().getTime() - startTime)/1000
    }

    //On Load, didComponentMount call
    useEffect(() =>{ 
        console.log("starting time", startTime)
        ping();
        //Not sure there is ever a case where it won't be set, but lets be safe 
        if(actionRequest == null){                        
            let newState = {}
            fetchActionByToken(arToken).then(actionRequestData=>{                  
                newState = {...actionRequestData};             

                let requestArray = [];
                requestArray.push(fetchEntityContext(actionRequestData.entityId));
                requestArray.push(fetchInvokerContext(actionRequestData.invokerId));
                requestArray.push(fetchRecipient(arToken, userToken));
                if (actionToken){
                    requestArray.push(fetchAction(arToken, actionToken))
                }

                Promise.all(requestArray).then( (values) =>{                                        
                    values.forEach((value) =>{                        
                        if (value !== undefined){
                            newState = {...newState, ...value}                            
                        }
                    })           
                    newState.initialised = true;
                    if(newState.invoker === null && newState.entity.welcomer){
                        const welcomer = newState.entity.welcomer
                        newState.invoker = {name:welcomer.name,avatar:welcomer.avatar}
                    }
                    console.log("Finished init process", getElapsedTime())   
                    return setState(state =>({...state, ...newState}))                                                       
                }).catch((error) =>{
                    console.log("WE HAD AN ERROR?!@#", error)
                });                  

                                           
    
    
                /*
                old style of loading one at a time for FS issues ( do not delete this for now)
                fetchEntityContext(actionRequestData.entityId).then( data => {
                    newState = {...newState, ...data}
                    setState(state =>({...state, ...newState}))//trigger a state set to allow for reload , this is stage 1 complete                                  
                    return fetchInvokerContext(actionRequestData.invokerId).then(data => {
                        return newState = {...newState, ...data}                        
                    })                    
                }).then(data => {                
                    if (userToken){
                        return fetchRecipient(arToken, userToken).then(data => {
                            return newState = {...newState, ...data}
                        })
                    }else{
                        return newState
                    }                    
                    //fetchAction                 
                }).then(data => {                                                        
                    if (actionToken){
                        console.log("actionToken from the start.....")
                        return fetchActionData(arToken, actionToken).then(data => {
                            return newState = {...newState, currentAction:{...data}}
                        })
                    }else{
                        return newState
                    }

                }).then(data => {                                                         
                    newState.initialised = true;  //everything has been fetched           
                    console.log("newState", newState)                         
                    return setState(state =>({...state, ...newState})) // stage 2 is now complete              
                })       
                */                
            }).catch(function(error){
                console.log(error);
            });
        }else{
            // console.log("Invoker already loaded",state.invoker)
        }
    },[])

    const fetchEntityContext = (entityId) => {
        if(!state.entity){
            return fetchEntity(entityId).then(data => {                            
                let theme = state.theme;
                theme = {...theme,
                    logo:data.theme.logo,
                    defaultLogo:data.theme.resellerLogo,
                    coBrand:data.theme.coBrand,
                    primaryActionColor:data.theme.primaryActionColor,
                    primaryActionBgColor:data.theme.primaryActionBgColor};                                                        
                return {entity:data, theme:theme , blocked:{active:false, displayComponent:null}}
                
                // console.log('Entity data done:', doc.data(),state);

                // console.log("set activeTheme",state.theme)
                
            }).catch(err => {
                console.log('Error getting document', err);
            });   

        }else{
            // console.log("Entity already loaded",state.entity)
        }
           
    }

    const fetchInvokerContext = (invokerId) => {

        // Handle the case where the invoker is not set
        if(!invokerId || invokerId===""){
            return {invoker:null}
        }
        if(!state.invoker){            
            return fetchUser(invokerId).then(data => {                                      
                //setState(state =>({...state, invoker:data}))                    
                return {invoker:data}
            }).catch(err => {
                console.log('Error getting user', err);
            }); 
        }else{
            // console.log("Invoker already loaded",state.invoker)
        }
    }

    const fetchRecipient = () => {                    
        return fetchRecipientData(arToken, userToken)
        .then(data => {                                  
            //setState(state =>({...state, currentRecipient:{...data}}))              
            return {currentRecipient:{...data}}                             
        }).catch(err => {                        
            console.log('Error getting step data', err);
        });
    }

    const fetchAction = () => {        
        return fetchActionData(arToken, actionToken)
        .then(data => {                 
            //setState(state =>({...state, currentAction:{...data}}))               
            return {currentAction:{...data}}                            
        }).catch(err => {                        
            console.log('Error getting step data', err);
        });
    }

    useEffect(() => {
        //console.log("BLOCK STATE SET?!@#", state.blocked)
    }, [state.blocked])

    //Check if the params have changed
    useEffect(() => {               
        if (state.entity){
            let params = props.match.params;                      
        
            if (arToken !==  params.arToken || userToken !==  params.userToken || actionToken !==  params.actionToken){                                              
                //This is a problem update the state   
                if (params.arToken != arToken){
                    
                    console.error("AR token doesn't match, this is a problem...")
                    setArToken( params.arToken);
                } 
                if (params.userToken != userToken){
                    console.info("User token has changed, set it");
                    setUserToken(params.userToken)
                }

                if (params.actionToken != actionToken){
                    console.info("action token has changed, set it");
                    setActionToken(params.actionToken)
                }
                                
                setState((state) => ({...state, arToken:params.arToken, userToken:params.userToken, actionToken:params.actionToken}))            
            }
        }
        
    }, [props.match.params]);
        

    useEffect(() =>{                  
        if (state.arToken !== undefined){            
            setReadyToRender(true);
        }        
    }, [state])
    

    useEffect(() =>{        
        if(state.initialised && state.currentAction == undefined && actionToken){       
            fetchRecipientData(arToken, userToken)
            .then(data => {                           
                setState(state =>({...state, currentRecipient:{...data}}))                               
            }).catch(err => {                        
                console.log('Error getting step data', err);
            });
        }
    }, [userToken])


    useEffect(() =>{                  
        if(state.initialised && state.currentAction == undefined && actionToken){                    
            fetchAction()
            .then(data => {                           
                //From reading the problem is that state checks won't trigger useEffect if it deems nothing has changed.
                //I'm adding in a new variable "currentACtionReady" to make sure that the state is marked as changed
                setState(state =>({...state, ...data, currentActionReady:true}));                
            }).catch(err => {                        
                console.log('Error getting step data', err);
            });
        }
    }, [actionToken])   

    return (<ActionRequestContext.Provider value={[state,setState]}>            
                {readyToRender ? props.children : ''}            
        </ActionRequestContext.Provider>)
    
}

const ActionRequestProvider = withRouter(ActionRequestProviderTemp);



export { ActionRequestContext, ActionRequestProvider};