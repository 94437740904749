import React from 'react';
import Modal from "antd/es/modal";
import FormByJson from '@bit/cleaver.propertyengine.form-by-json';
import Button from "antd/es/button";
import styled from "styled-components";
import {PrimaryBlockButton, SecondaryBlockButton} from "../../components/button/Button";

const StyledForm = styled(FormByJson)`
    &&&{
      max-width: 400px;
      margin: 0 auto 15px;
      text-align: left;
        button{
            margin-left:auto;
            margin-right:auto;
        }
        .ant-form-item-label {
            padding:0;
            & > label{
                font-weight:bold;
            }
        }
    }
`

const SurveyModal = ({title,formId,formSchema,uiSchema,visible,handleSave,handleCancel,...props}) => {

    const handleSubmit = (values) =>{
        handleSave(formId,values)
    }

    return <Modal
        title={title}
        footer={null}
        visible={visible}
        onCancel={handleCancel}
    >
        <StyledForm formSchema={formSchema} uiSchema={uiSchema} showErrorList={false} onSubmit={handleSubmit}>
            <input type="hidden" name="formId" value={formId}/>
            <PrimaryBlockButton block htmlType={"submit"} type="primary">Save</PrimaryBlockButton>
            <SecondaryBlockButton type="link" block  onClick={handleCancel}>
                Cancel
            </SecondaryBlockButton>

        </StyledForm>
    </Modal>
}

export default SurveyModal;