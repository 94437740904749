import React, {useState} from 'react';
import Row from "antd/es/grid/row";
import Col from "antd/es/grid/col";
import {EmailButton, FacebookButton, GoogleButton} from "../../components/button/Button";
import Divider from "antd/es/divider";
import Icon from "../../components/icon/Icon";
import {
    checkSignInMethods,
    handleEmailRegister,
    handleEmailSignIn,
    handleFacebookLogin,
    handleGoogleLoggedIn
} from "./AuthProvider";
import styled from "styled-components";
import {Formik} from "formik";
import { Form, Input} from 'formik-antd'
import Button from "antd/es/button";
import * as Yup from "yup";
import {useTranslation} from "react-i18next";
import Alert from "antd/es/alert";
import Image from "../../components/image/Image";
import AuthFormTemplate from "./AuthFormTemplate";
import {notification} from "antd/es";

export const AuthBrand = styled.div`
    max-width: 175px;
    margin: 0 auto 15px;
    img{
        width: 100%;
        height: auto;
    }
    
    &.coBrand{
        margin: 0 0 15px;
    }
`

export const RegistrationContainer = styled.div`
    width: 100%; 
    overflow:hidden;
`

export const RegistrationOptions = styled.div`
     width: 100%; 
     margin-bottom: 20px;
     @media only screen and (min-width: 450px) {
        width: 50%; 
        float:left;
        margin-bottom: 0;
    }       
`

const StyledForm = styled(Form)`
    &&&{
        .ant-form-item{
            margin-bottom: 10px;
            .ant-form-item-label{
                padding-bottom: 0;
            }
        } 
    }
`

export const RegistrationMotivation = styled.div`
    width: 100%; 
    border-radius: 5px;
    background: whitesmoke;
    padding: 20px;
    height: 100%;
    text-align:left;    
    @media only screen and (min-width: 450px) {
       width: calc(50% - 30px); 
       float:left;
       margin-left:30px;
       margin-bottom: 0;
       min-height: 350px;
    }    
`

const AuthForm = ({actionLabel, coBrand, handleLoggedIn,customBenefits,...props})=>{

    const {t} = useTranslation();

    const [showManualAuth,setShowManualAuth] = useState(false)
    const [emailAddress,setEmailAddress] = useState(null)
    const [previousAuthProviders,setPreviousAuthProviders] = useState(null)
    const [showRegisterForm,setShowRegisterForm] = useState(false)
    const [showLoginForm,setShowLoginForm] = useState(false)

    const handleGoogle = () =>{
        return handleGoogleLoggedIn().then(uid=>{
            return handleLoggedIn(uid)
        })
    }

    const handleFacebook = () =>{
        return handleFacebookLogin().then(uid=> {
            return handleLoggedIn(uid)
        })
    }

    const handleManualAuthShow = () => {
        setShowManualAuth(true)
    }

    const hideLoginForm = () => {
        setEmailAddress(null)
        setShowManualAuth(false)
    }

    const checkEmailAddress = () =>{
        setEmailAddress(null)
    }

    const handleManualRegister = (name,email,phone,password) =>{
        console.log("manual register",email)
        return handleEmailRegister(name,email,phone,password).then(uid=>{
            console.log("manual register",uid,email)
            return handleLoggedIn(uid)
        })
    }

    const handleManualSignIn = (email,password) =>{
        return handleEmailSignIn(email,password).then(uid=>{
            return handleLoggedIn(uid)
        })
    }

    console.log(emailAddress)


    const emailSchema = Yup.object().shape({
        email: Yup.string()
            .email('Invalid email')
            .required('An email address is required')
    });
    const emailForm = <Formik
            initialValues={{email:''}}
            validationSchema={emailSchema}
            onSubmit={(values, actions) => {
                console.log(values)
                checkSignInMethods(values.email).then(authProviders =>{
                    console.log("existing authProviders",authProviders)
                    setEmailAddress(values.email)
                    setPreviousAuthProviders(authProviders)
                    actions.setSubmitting(false)
                })

            }}
        >{(props) =>{
            return  <StyledForm layout='vertical' size='large' style={{textAlign: "left"}} >
                <Form.Item name="email" label="Enter your email address">
                    <Input type="email" required name="email" placeholder="e.g. email@domain.com" autoComplete="off"/>
                </Form.Item>
                <Button type="primary" block loading={props.isSubmitting}  htmlType="submit" >
                    Continue <Icon icon="arrow-right" color={'white'}/>
                </Button>
                <Button type="link"  block onClick={hideLoginForm}>Cancel</Button>
            </StyledForm>
        }}
        </Formik>

    const registerSchema = Yup.object().shape({
        name: Yup.string()
            .min(2, 'Too Short!')
            .required('We need your full name please'),
        email: Yup.string()
            .email('Invalid email')
            .required('An email address is required'),
        phone: Yup.string()
            .required('A valid mobile number is required'),
        password: Yup.string()
            .required('Please enter a password')
            .min(6, 'Too Short!')
    });

    const registerForm =   <Formik
            initialValues={{name:'',email:emailAddress,phone:'',password:''}}
            validationSchema={registerSchema}
            onSubmit={(values, actions) => {
                console.log('sdfubsdf', values)
                handleManualRegister(values.name,values.email,values.phone,values.password).then(r =>{
                    actions.setSubmitting(false);
                })
            }}
        >{(props) =>{
            return  <StyledForm layout='vertical' size='large' style={{textAlign: "left"}} onSubmit={props.handleSubmit} >

                <Form.Item name="name" label="Your name">
                    <Input name="name" required placeholder="Enter your name" autoComplete="off"/>
                </Form.Item>
                {/*<Form.Item name="email" label="Email">*/}
                {/*    <Input type="email" required name="email" placeholder="e.g. email@domain.com" autoComplete="off"/>*/}
                {/*</Form.Item>*/}
                <Form.Item name="phone" label="Contact Number">
                    <Input required name="phone" placeholder="" autoComplete="off"/>
                </Form.Item>
                <Form.Item name="password" label="Password">
                    <Input.Password required name="password" placeholder="Enter password" />
                </Form.Item>
                <Button type="primary" block loading={props.isSubmitting} htmlType="submit" onClick={props.handleSubmit}>Register</Button>
                <Button type="link" block onClick={hideLoginForm}>Cancel</Button>
            </StyledForm>
        }}
        </Formik>

    const loginSchema = Yup.object().shape({
        email: Yup.string()
            .email('Invalid email')
            .required('An email address is required'),
        password: Yup.string()
            .required('Please enter your password')
            .min(6, 'Too Short!')
    });

    const loginForm = <Formik
        initialValues={{email:emailAddress,password:''}}
        validationSchema={loginSchema}
        onSubmit={(values, actions) => {
            console.log('sdfubsdf', values)
            handleManualSignIn(values.email,values.password).then(r=>{
                actions.setSubmitting(false);
            }).catch(err=>{
                notification['error']({
                    message: "We couldn't log you in",
                    description: err.message
                });
                actions.setSubmitting(false);
            })

        }}
    >{(props) =>{
        return  <StyledForm layout='vertical' size='large' style={{textAlign: "left"}} onSubmit={props.handleSubmit} >
            <Alert style={{marginBottom:10}} type="warning" message="You've previously created a Property Pass with this email address. To continue just enter your password"/>
            <Form.Item name="email" label="Email Address">
                <Input disabled bordered={false} name="email" />
            </Form.Item>
            <Form.Item name="password" label="Password">
                <Input.Password required name="password" placeholder="Enter password" />
            </Form.Item>
            <Button type="primary" block loading={props.isSubmitting} htmlType="submit" onClick={props.handleSubmit}>Login</Button>
            <Button type="link" block onClick={hideLoginForm}>Cancel</Button>
        </StyledForm>
    }}
    </Formik>

    let formToShow = <React.Fragment>
        <FacebookButton actionLabel={actionLabel} key="facebook" className='centered' onClick={() => handleFacebook()}/>
        <GoogleButton actionLabel={actionLabel} key="google" className='centered' onClick={() => handleGoogle()}/>
        <Divider key="divider" style={{marginTop:0, marginBottom:5}}>OR</Divider>
        <EmailButton actionLabel={actionLabel} key="email" className='centered' onClick={handleManualAuthShow}/>
    </React.Fragment>

    const getLoginReminder = (provider) => {

        let button = null
        if(provider==="google"){
            button = <GoogleButton actionLabel={actionLabel} key="google" className='centered' onClick={() => handleGoogle()}/>
        } else if(provider==="facebook"){
            button = <FacebookButton actionLabel={actionLabel} key="facebook" className='centered' onClick={() => handleFacebook()}/>
        }
        return <div>
            <Alert style={{marginBottom:10}} type="warning"
                   message={`You've already created a Property Pass with ${emailAddress} using ${t(provider)}. To continue just click the button below`}/>
            {button}
            <Button type="link" onClick={hideLoginForm}>Cancel</Button>
        </div>
    }

    if(showManualAuth){
        if(!emailAddress){
            formToShow = emailForm
        }else{
            if(previousAuthProviders && previousAuthProviders.length>0){
                let authProvider = previousAuthProviders[0]
                console.log("authProviders")
                if(authProvider==="google.com"){
                    formToShow = getLoginReminder("google")
                } else if(authProvider==="facebook.com"){
                    formToShow = getLoginReminder("facebook")
                } else if(authProvider==="password"){
                    formToShow = loginForm
                }

            }else{
                formToShow = registerForm
            }
        }
    }



    return   <Row gutter={0}>
                <Col flex="650px" style={{textAlign:'center'}}>
                    <AuthFormTemplate coBrand={coBrand} customBenefits={customBenefits}>
                        {formToShow}
                    </AuthFormTemplate>
                </Col>
            </Row>
}

export default AuthForm