import React from 'react';
import styled from "styled-components";

const IndicatorContainer = styled.div`
    width:100%;
    display:flex;
    margin-bottom: 2px;
`;
const Indicator = styled.div`
    height: 6px;
    background: ${props => props.theme.wizard.progressIndicatorBgColor};
    float:left;

    &.incomplete{
        background: ${props => props.theme.wizard.progressIndicatorBgActiveColor};
    }
`;

const ProgressStepIndicator = (props) => {

    let totalSteps = props.totalSteps;
    let stepGap = 2;
    
    let indicatorWidth = 100/totalSteps;

    let indicators = []
    for(let i=0;i<totalSteps;i++){
        let gap = stepGap
        let className = ""
        if(i===totalSteps-1){
            gap = 0;    
        }
        let widthMinusGap =  "calc("+indicatorWidth+"% - "+gap+"px)";
        if(props.activeStep >= i+1){
            className = "incomplete"
        }

        indicators.push(<Indicator className={className} key={'psi-'+i} style={{width:widthMinusGap, marginRight:gap}}></Indicator>)
    }



    return <IndicatorContainer>
        {indicators.map((item, index) =>{return item})}
    </IndicatorContainer>
}

export default ProgressStepIndicator;