import React from "react";
import { withRouter } from 'react-router-dom';
import {Row, Col} from "antd";
import AuthForm from "./AuthForm";
import Layout from "antd/es/layout";
import styled from "styled-components";
const {Content } = Layout;

const LoginContainer = styled.div`
    max-width: 600px;
    background: white;
    padding: 30px;
    border-radius: 5px;
    margin: 0 auto;
`

const Join = ({history}) => {

    const handleAuthLoggedIn = (uid) =>{
        history.push('/dashboard');
    }

    return (
        <Layout>
            <Content>
                <Row style={{height: "100vh"}}  align="middle">
                    <Col sm={{span:24, offset:0}} lg={{span:12, offset:6}}>
                        <LoginContainer>
                            <AuthForm handleLoggedIn={handleAuthLoggedIn} />
                        </LoginContainer>
                    </Col>
                </Row>
            </Content>
        </Layout>



    );
};

export default withRouter(Join);