import React, {useContext, useEffect, useState} from 'react';
import AccountContainer from "../../components/containers/AccountContainer";
import {Link, withRouter} from "react-router-dom";
import {fetchLaunchInfo, fetchPublicUserLaunchData} from "../../services/PropertyPassService";
import Countdown from "react-countdown";
import moment from "moment";
import styled from "styled-components"
import {PreLaunchTimer} from "./PreLaunch";
import Icon from "../../components/icon/Icon";
import Button from "antd/es/button";
import Typography from "antd/es/typography";
import message from "antd/es/message";
import List from "antd/es/list";
import {AuthContext} from "../auth/AuthProvider";
import {useTranslation} from "react-i18next";
import SurveyModal from "../survey/SurveyModal";
import {
    getCompletedIncentives,
    hasMatchingCompleteIncentive,
    recordCompletedIncentive
} from "../../services/IncentiveService";
import Spin from "antd/es/spin";
import RewardModal from "./RewardModal";
import {createUrlFromJson} from "../../components/image/Image";
import Modal from "antd/es/modal";
import {Form, Input, Checkbox} from "formik-antd";
import {Formik} from "formik";
import TermsAndConditions from "../../components/display/TermsAndConditions";
import {LaunchPreviewSwitcher} from "../demo/Elements";

const { Paragraph, Text } = Typography;

const ContentContainer = styled.div`
    padding: 0px;
    min-height: 100%;
    height: 100vh;   
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;    
    @media only screen and (min-width: 450px) {
       padding: 40px;
    }
`
const WelcomeInfo = styled.div`
     height: 100%;   
    max-width: 640px;
    text-align:center;
    margin: 0 auto 2em;    
    background: white;
    padding: 30px 20px 30px;
    @media only screen and (min-width: 450px) {
        height: auto;   
        border-radius: 5px;
    }
`
const TimerBlock = styled.div`
    margin-bottom: 2em;
`

const Welcome = (props) =>{
    const params = props.match.params
    const entityId = params.entityId
    const launchId = params.launchId
    const slug = params.slug

    console.log("entityId",entityId)
    console.log("launchId",launchId)
    console.log("slug",slug)
    const {t} = useTranslation();

    const {user,userData} = useContext(AuthContext)


    const [loading, setLoading] = useState(true)
    const [launched, setLaunched] = useState(false)
    const [launchInfo, setLaunchInfo] = useState({})
    const [userLaunchData, setUserLaunchData] = useState({})
    const [incentives, setIncentives] = useState([])
    const [loadingIncentives, setLoadingIncentives] = useState(true)
    const [completedIncentives, setCompletedIncentives] = useState([])
    const [surveyModal, setSurveyModal] = useState(null)
    const [rewardModal, setRewardModal] = useState(null)
    const [demoModal,setDemoModal] = useState({visible:false,title:'',variant:''})

    let launchDate = moment(launchInfo.launchDate).toDate()

    const setCompletedIncentivesFromDocs = (completedIncentiveDocs) =>{
        let completedIncentives = []
        completedIncentiveDocs.forEach(incentiveDoc=>{
            let incentive = incentiveDoc.data()
            incentive.id = incentiveDoc.id
            completedIncentives.push(incentive)
        })
        setCompletedIncentives(completedIncentives)
        setLoadingIncentives(false)
    }

    const getTaskDescription = (incentive) =>{
        return incentive.taskDescription ? incentive.taskDescription : t(`task.${incentive.task}`)
    }

    const getIncentiveDescription = (incentive) =>{
        return incentive.incentiveDescription ? incentive.incentiveDescription : t(`incentive.${incentive.type}`)
    }

    const hasUserEarnedThisIncentive = (incentive) =>{

        return hasMatchingCompleteIncentive(completedIncentives,incentive)
    }

    const handleComplete = () => {
        setLaunched(true)
    }

    const handleIncentiveReward = (listIncentive) => {
        console.log(listIncentive)
        const matchingIncentive = incentives[listIncentive.incentiveIndex]
        console.log(matchingIncentive)

        setRewardModal({visible:true,incentive:matchingIncentive})

    }

    const handleDemoIncentive = (demoTask) => {
        if(demoTask==='fnb'){
            setDemoModal({visible:true,title:'Apply for an FNB bond',variant:'fnb'})
        }
        if(demoTask==='betterbond'){
            setDemoModal({visible:true,title:'Apply for a bond through Betterbond',variant:'betterbond'})
        }
    }
    const handleDemoIncentiveClose = () =>{
        setDemoModal({visible: false})
    }

    const handleIncentiveTask = (selectedForm) => {
        console.log(incentives,selectedForm)
        const matchingIncentive = incentives.find(item=>{
            return item.taskForm===selectedForm.formId
        })

        const incentiveDescription = getIncentiveDescription(matchingIncentive)

        if(matchingIncentive){
            setSurveyModal({title:incentiveDescription,visible:true,formId:selectedForm.formId,formSchema:matchingIncentive.formSchema,uiSchema:matchingIncentive.uiSchema})
        }else{
            message.error("Could not find matching incentive")
        }

    }

    const handleRewardModalCancel = () => {
        setRewardModal(modalProps => {return {...modalProps,visible:false}})
    }

    const handleSurveyModalCancel = () => {
        setSurveyModal(modalProps => {return {...modalProps,visible:false}})
    }

    const handleSurveyModalSave = (formId,values) => {
        const matchingIncentive = incentives.find(item=>{
            return item.taskForm===formId
        })

        if(!matchingIncentive){
            message.error("Something went wrong E-W001 "+user.uid)
            return
        }
        return recordCompletedIncentive(user.uid, launchId, matchingIncentive, values.formData)
            .then(result  =>{
                console.log("handleSurveyModalSave",matchingIncentive,formId, values)
                setSurveyModal(modalProps => {return {...modalProps,visible:false}})
                return getCompletedIncentives(user.uid,launchId)
            }).then(completedIncentiveDocs=>{
                setCompletedIncentivesFromDocs(completedIncentiveDocs)
            })
    }

    useEffect(()=>{
        fetchLaunchInfo(launchId).then(doc=>{
            let launchData = doc.data();
            setLaunchInfo(launchData);
            console.log(launchData)
            setIncentives(launchData.incentives)
            setLoading(false)
        })
        // fetchPublicUserLaunchData()

    },[launchId])

    useEffect(()=>{
        if(user ){
            console.log("user id",user.uid)
            fetchPublicUserLaunchData(user.uid,launchId).then(launchDataDoc=>{
                console.log("launchDataDoc",launchDataDoc.data())
                setUserLaunchData(launchDataDoc.data())
                return getCompletedIncentives(user.uid,launchId)
            }).then(completedIncentiveDocs=>{
                setCompletedIncentivesFromDocs(completedIncentiveDocs)
            })

        }

        // if(userData.uid){
        //
        // }
    },[user,launchId])


    const incentiveList = []
    console.log(incentives)
    incentives.forEach((incentive,index) => {

        if (incentive.task === 'register') {
            incentiveList.push({
                incentiveIndex: index,
                incentiveTitle: getIncentiveDescription(incentive),
                actionTitle: getTaskDescription(incentive),
                unlocked: userLaunchData.registered
            })
        } else {
            let action = null
            if (incentive.task === 'form') {
                action = {type: 'form', formId: incentive.taskForm}
            }
            incentiveList.push({
                incentiveIndex: index,
                incentiveTitle: getIncentiveDescription(incentive),
                actionTitle: getTaskDescription(incentive),
                unlocked: hasUserEarnedThisIncentive(incentive),
                action: action

            })
        }
    })

    let demoStuff =null
    let demoForm = <Formik
        enableReinitialize={true}
        initialValues={{}}
        onSubmit={(values) => {
            message.success("This is just a demo")
        }}
        render={(props) => {
            return <Form layout={"vertical"}>
                <Form.Item label="SA Identity Number" name="idNumber">
                    <Input name="idNumber"/>
                </Form.Item >
                <Form.Item label="" name="terms">
                    <Checkbox name="terms">
                        I agree to Terms & Conditions
                    </Checkbox>
                </Form.Item>
                <TermsAndConditions termsList={["Example Terms 1","Example Terms 2"]} termsPdf={"https://www.google.com"}/>

            </Form>
        }}/>
    if(launchInfo.demo==="fnb"){
        demoStuff = <div style={{marginTop:20}}>
            <Paragraph>
                <Text
                    strong
                    style={{fontSize: 18}}
                >
                    Additional Incentives
                </Text>
            </Paragraph>
            <div>
                <List
                    style={{background:"#FFFFFF"}}
                    split={true}
                    itemLayout="horizontal"
                    bordered={true}
                    dataSource={[{test:''}]}
                    renderItem={item => (
                <List.Item  style={{textAlign:'left'}}
                            actions={[
                                <Button type="primary"  shape="round"
                                        icon={<Icon icon="unlock" color={'white'} />}
                                        onClick={() => handleDemoIncentive('fnb')}>Unlock</Button>]}>
                    <List.Item.Meta
                        avatar={<img src="/images/demo/fnb.png" style={{width:150}}/> }
                        title="Get pre-approved by FNB"
                        description="FNB will give up to XXX discount on your transfer fees if you apply directly"
                    />
                </List.Item>
                )}/>
            </div>
        </div>
    }

    if(launchInfo.demo==="betterbond"){
        demoStuff = <div style={{marginTop:20}}>
            <Paragraph>
                <Text
                    strong
                    style={{fontSize: 18}}
                >
                    Additional Incentives
                </Text>
            </Paragraph>
            <div>
                <List
                    style={{background:"#FFFFFF"}}
                    split={true}
                    itemLayout="horizontal"
                    bordered={true}
                    dataSource={[{test:''}]}
                    renderItem={item => (
                        <List.Item  style={{textAlign:'left'}}
                                    actions={[
                                        <Button type="primary"  shape="round"
                                                icon={<Icon icon="unlock" color={'white'} />}
                                                onClick={() => handleDemoIncentive('betterbond')}>Unlock</Button>]}>
                            <List.Item.Meta
                                avatar={<img src="/images/demo/betterbond.png" style={{width:150}}/> }
                                title="Get pre-approved by Betterbond"
                                description="Betterbond will give up to XXX discount on your transfer fees if you apply directly"
                            />
                        </List.Item>
                    )}/>
            </div>
        </div>
    }

    return <AccountContainer >
        {launchInfo.demo?<LaunchPreviewSwitcher link={<Link to={`/launch/${entityId}/${launchId}/${slug}/launch`}>Launch Preview</Link>}/>:''}
        <ContentContainer   style={{  backgroundImage: `url('${createUrlFromJson(launchInfo.preLaunchImage,1400,1400, null)}')`}} >
        <WelcomeInfo>
            {surveyModal ? <SurveyModal handleSave={handleSurveyModalSave} handleCancel={handleSurveyModalCancel} {...surveyModal}/> : null}
            {rewardModal ? <RewardModal handleCancel={handleRewardModalCancel} {...rewardModal}/> : null}
            <TimerBlock>
                <h2>{launchInfo.preLaunchTitle}</h2>
                {launched ? "" :
                    <Countdown date={launchDate} style={{color:'black'}}
                               renderer={PreLaunchTimer} onComplete={handleComplete}/>}
            </TimerBlock>

            {incentiveList && incentiveList.length>0 && !loadingIncentives ? <React.Fragment>
                    <Paragraph>
                        <Text
                            strong
                            style={{
                                fontSize: 18,
                            }}
                        >
                            While you wait you can unlock the following optional rewards
                        </Text>
                    </Paragraph>
                    <List
                        style={{background:"#FFFFFF"}}
                        split={true}
                        itemLayout="horizontal"
                        bordered={true}
                        dataSource={incentiveList}
                        renderItem={item => (
                            <List.Item  style={{textAlign:'left'}}
                                        actions={[ <span>{item.unlocked ?
                                            <Button type="primary" style={{background:"rgb(83, 195, 27)",border:"rgb(83, 195, 27)",color:"black"}}  shape="round" onClick={() => handleIncentiveReward(item)}>View Reward</Button> :
                                            <Button type="primary"  shape="round" icon={<Icon icon="unlock" color={'white'} />} onClick={() => handleIncentiveTask(item.action)}>Unlock</Button>}</span>]}
                            >
                                <List.Item.Meta
                                    avatar={item.unlocked ?
                                            <Icon icon="checkmark-circle" color={"rgb(83, 195, 27)"} style={{fontSize:'40px'}}/>:
                                            <Icon icon="cross-circle" color={"red"} style={{fontSize:'40px'}}/>
                                    }
                                    title={item.incentiveTitle}
                                    description={item.actionTitle}
                                />
                            </List.Item>
                        )}
                    />
                </React.Fragment>
                :<Spin/>
            }

            {demoStuff}

        </WelcomeInfo>
            <Modal title={""} visible={demoModal.visible} cancelText={"No thanks"} okText={"Send my application"} onCancel={()=>handleDemoIncentiveClose()}>
                {demoModal.variant==='fnb'?<div>
                    <div style={{textAlign:'center',marginBottom:20}}><img src="/images/demo/fnb.png" style={{width:150,margin:'0 auto'}}/></div>
                    <p><strong>FNB is offering a discount of up to R100000 on transfer fees if a new bond is secured through them directly</strong></p>
                    <p>If you would like to see if you qualify please fill in the additional information below</p>
                    <div>{demoForm}</div>
                </div>:''}
                {demoModal.variant==='betterbond'?<div>
                    <div style={{textAlign:'center',marginBottom:20}}><img src="/images/demo/betterbond.png" style={{width:150,margin:'0 auto'}}/></div>
                    <p><strong>Betterbond is offering a discount of up to R100000 on transfer fees if a new bond is secured through them directly</strong></p>
                    <p>If you would like to see if you qualify please fill in the additional information below</p>
                    <div>{demoForm}</div>
                </div>:''}
            </Modal>
        </ContentContainer>
    </AccountContainer>
}

export default withRouter(Welcome)