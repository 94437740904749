import React  from 'react';
import styled from "styled-components";
import _ from 'lodash';
import { Helmet } from "react-helmet";
import PropTypes from 'prop-types';
import { Spin } from 'antd';
import {LoadingPaymentForm} from "../../pages/once-off/reservation/ReservationPeachPayment";


const PeachContainer = styled.div`
    min-height: 384px;

.wpwl-form{
    margin-bottom: 0;
    
}
.wpwl-container{
    text-align:left;

}
.wpwl-label{
    display:block;
    width:100%;
    font-weight: bold;
    height: 2em;
    padding: 0 0 8px;
    line-height: 1.5715;
}
.wpwl-wrapper{
    display:block;
    width:100%;
    
}

.wpwl-control{
    padding: 6.5px 11px!important;
    font-size: 16px!important;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    -webkit-box-sizing: border-box!important;
    box-sizing: border-box!important;
    font-variant: tabular-nums;
    list-style: none;
    -webkit-font-feature-settings: 'tnum';
    font-feature-settings: 'tnum', "tnum";
    position: relative;
    display: inline-block;
    width: 100%;
    min-width: 0;
    color: rgba(0, 0, 0, 0.65);
    height: 40px!important;
    background-color: #fff;
    background-image: none;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
}

.wpwl-wrapper-cardNumber input{
    padding: 4px 11px

}

.wpwl-brand,.wpwl-group-brand{
    display:none;
}
.wpwl-wrapper-submit{
    text-align: center;
}
.wpwl-button{
    float:none;
    width:100%;        
    max-width: 400px;
    margin: 0 auto;
    color:${props => props.theme.primaryActionColor};
    background-color:${props => props.theme.primaryActionBgColor};
    border-color:${props => props.theme.primaryActionBgColor};
    height: 40px;
    padding: 6.4px 15px;
    font-size: 16px;
    border-radius: 2px;
    font-weight: bold;
}

`;

const PeachCardForm = ({checkoutId,resultUrl}) =>{


    
    const peachScript = ` var wpwlOptions = { 
        'style': "plain",
        autofocus : 'card.holder',
        showCVVHint: true,
        iframeStyles: {
            'card-number-placeholder': {
                'color': '#BFBFBF',
                'font-size': '16px',
            },
            'cvv-placeholder': {
                'color': '#BFBFBF',
                'font-size': '16px',
            }
        }
    };`

    const url = "https://test.oppwa.com/v1/paymentWidgets.js?checkoutId="+checkoutId;

    return  <div>
             <Helmet>
                <script type="text/javascript">
                    {peachScript}
                </script>
                <script async src={url}></script>
            </Helmet>

            <PeachContainer>
                <form action={resultUrl} className="paymentWidgets" data-brands="VISA MASTER AMEX">
                    <LoadingPaymentForm>
                        <Spin tip="Loading secure form..." />
                    </LoadingPaymentForm>
                </form>
            </PeachContainer>
        </div>

}


PeachCardForm.propTypes = {
    checkoutId: PropTypes.string.isRequired,
    resultUrl: PropTypes.string.isRequired
};

export default PeachCardForm;