import React, { useState, useEffect, useContext } from 'react';
import { TextContent } from '../../theme/DefaultTheme';
import { notification } from 'antd';
import { Form } from 'formik-antd'
import { Formik } from 'formik'
import styled from "styled-components";
import OtpInput from 'react-otp-input';
import { withRouter, useHistory } from "react-router-dom";
import { pathToCreditCheckOnceOffRoute } from '../../routes/publicRoutes';
import WizardContent from "../../components/containers/WizardContent"
import { PrimaryBlockButton, SecondaryBlockButton } from '../../components/button/Button';
import { fetchActionRequestRecipientStep, postOtpResend, postOtpVerification } from '../../services/PropertyPassService';
import OnceOff from "./OnceOff";
import { ActionRequestContext } from "./ActionRequestContext";


const OtpField = styled.div`
    margin: 0 auto ${props => props.theme.defaultBlockMargin};

    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none!important;
        margin: 0;
    }

    /* Firefox */
    input[type=number] {
        -moz-appearance: textfield!important;
    }  
`;


const CreditOtp = (props) => {
    console.log("Rendering Credit Welcome");
    // const backUrl = "/account/HdmbfZMcAL/rawson/once-off/XXXYYYZZZ/start";
    const params = props.match.params;

    let numberOfOtpDigits = 6;

    const [appContext, setAppContext] = useContext(ActionRequestContext);

    const [arToken] = useState(params.arToken);
    const [userToken] = useState(params.userToken);
    const [resending, setResending] = useState(false);
    const [phoneNumber, setPhoneNumber] = useState(null)
    const [verificationId, setVerificationId] = useState(null)
    const [otp, setOtp] = useState('')
    const [nextEnabled, setNextEnabled] = useState(false)
    const history = useHistory();


    useEffect(() => {
        console.log("Trigger find data for step", arToken);
        if (arToken) {
            fetchActionRequestRecipientStep(arToken, userToken, "start")
                .then(data => {
                    setPhoneNumber(data.phone);
                    setVerificationId(data.verificationId);
                }).catch(err => {
                    console.log('Error getting step data', err);
                });
        }
    }, [arToken])

    const clearOtp = () => {
        setOtp("")
    };
    const handleResend = () => {
        setResending(true);

        postOtpResend(arToken, userToken, phoneNumber).then(response => {
            setAppContext((state) => ({ ...state, otpVerificationId: response.verificationId.sid }));
            setVerificationId(response.verificationId.sid);

            notification['success']({
                message: "We've sent an SMS to " + phoneNumber,
                description:
                    'You should receive an One Time Pin (OTP) shortly, this is a short number that you must type into the inputs below to verify you own that number'
            });
            setResending(false);
            clearOtp();

        }).catch(error => {
            console.log("postOtpResend", error)
            notification['error']({
                message: "Something went wrong when trying to resend your One Time Pin",
                description:
                    'Please contact your agent to let them know of the problem'
            });
            setResending(false);

        });

    }

    const handleOtpChange = (newOtp) => {
        console.log("newOtp",newOtp)
        setOtp(newOtp);
        if (newOtp.toString().length === numberOfOtpDigits) {
            setNextEnabled(true)
        } else {
            setNextEnabled(false)
        }
    };

    const nextUrl = pathToCreditCheckOnceOffRoute(arToken, userToken, "identity")

    return (<OnceOff>
        <WizardContent activeStep={2} totalSteps={5} image="/images/creditcheck/otp.svg">

            <TextContent>
                <h4>Let's verify your phone number</h4>
                <p>Enter the pin just sent to {phoneNumber}</p>
            </TextContent>
            <Formik
                enableReinitialize={true}
                initialValues={{ otp: otp }}
                onSubmit={(values, actions) => {

                    console.log("Submitting", appContext, values)

                    postOtpVerification(arToken, userToken, verificationId, values.otp).then(response => {
                        actions.setSubmitting(false);
                        if (response.verified) {
                            history.push(nextUrl);
                        } else {
                            throw "OTP Error"
                        }

                    }).catch(() => {
                        actions.setSubmitting(false);
                        clearOtp();
                        notification['error']({
                            message: "Could not verify the OTP",
                            description: "The One Time Pin you entered does not match our records, if you have resent the OTP please select the last one that you received."
                        });
                    });

                }}
                render={(props) => (
                    <Form layout="inline" size="large" style={{ marginBottom: "3em" }}>
                        <OtpField>
                            <OtpInput inputStyle={{
                                width: '3rem',
                                height: '3rem',
                                margin: '0 0.5rem',
                                fontSize: '2rem',
                                borderRadius: 4,
                                border: '1px solid #d9d9d9'
                            }}
                                numInputs={numberOfOtpDigits}
                                shouldAutoFocus={true}
                                onChange={(otp) => handleOtpChange(otp)}
                                isInputNum={false}
                                value={otp} />
                        </OtpField>
                        <PrimaryBlockButton disabled={!nextEnabled} htmlType="submit" loading={props.isSubmitting} style={{ marginLeft: 'auto', marginRight: 'auto' }}>Next</PrimaryBlockButton>
                    </Form>
                )} />




            <TextContent>
                <h4>Didn't receive an SMS?</h4>
            </TextContent>
            <SecondaryBlockButton onClick={() => handleResend()} loading={resending} style={{ marginLeft: 'auto', marginRight: 'auto' }}>Resend</SecondaryBlockButton>


        </WizardContent>
    </OnceOff>
    )
}

export default withRouter(CreditOtp)
