import React, {useContext} from "react";
import { ThemeProvider } from "styled-components";
import { withRouter } from "react-router-dom";

import TopBar from "../../ui/TopBar.js";
import { Layout} from "antd";
import { PageContentWrapper } from '../../theme/DefaultTheme';
import MainPageLoader from '../../components/loading/MainPageLoader';

import {ActionRequestContext} from "./ActionRequestContext";
const { Content } = Layout;


const OnceOff = (props) => {    
    const [appContext] = useContext(ActionRequestContext);
    const ready = props.ready !== undefined?props.ready:appContext.initialised;
    let body = ready?<Content style={{ overflow: 'scroll'}}>
                <PageContentWrapper>                         
                    {props.children}                                                  
                </PageContentWrapper>
            </Content>:<MainPageLoader/>
    if(appContext.theme === undefined){
        return <div> not set up</div>
    }else{
        return  <ThemeProvider theme={appContext.theme}>
                    <Layout style={{backgroundColor:appContext.theme.bodyBgColor}}>
                        <TopBar/>
                        {body}
                    </Layout>
                </ThemeProvider>
    }
    

   
}

export default withRouter(OnceOff);