import React from 'react';
import styled from "styled-components";


const TermsAndConditionsHolder = styled.div`
    padding: 10px;
    background: whitesmoke;
    border-radius: 5px;
    ul{
        padding-left: 20px;
    }
`;

const TermsAndConditions = React.memo(({termsList,termsPdf}) => {    
    let termInfo = [];
    if(termsList){
        if(termsList || termsPdf){
            if(termsList){
                termInfo.push(<ul key={"term-list"}>{termsList.map((term,index)=>{                    
                    return <li key={"terms"+index}>{term}</li>
                })}</ul>)
            }
                
            if(termsPdf){
                termInfo.push(<a href={termsPdf} key={"terms-full"} target="_blank" rel="noopener noreferrer">View full Terms &amp; Conditions PDF</a>)
            }
        }
    }
    



    return  <TermsAndConditionsHolder>
                <h4>Terms &amp; Conditions</h4>
                {termInfo}
            </TermsAndConditionsHolder>   
})

export default TermsAndConditions