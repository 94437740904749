import React, {useState, useContext} from 'react';
import { TextContent, NarrowForm } from '../../theme/DefaultTheme';
import { Input} from 'formik-antd'
import { Formik } from 'formik'
import { notification } from 'antd';

import WizardContent from "../../components/containers/WizardContent"
import { PrimaryBlockButton } from '../../components/button/Button';
import { withRouter ,useHistory} from "react-router-dom";
import OnceOff from "./OnceOff";
import { pathToCreditCheckOnceOffRoute } from '../../routes/publicRoutes';
import { postIdNumber } from '../../services/PropertyPassService';
import {ActionRequestContext} from "./ActionRequestContext";


const CreditIdentity = () => {

    const [appContext, setAppContext] = useContext(ActionRequestContext);

    const [nextEnabled, setNextEnabled] = useState(false)
    const history = useHistory();


    const handleIdChange = (e) => {
        let idNumber = e.target.value
        if(idNumber.length===13){
            setNextEnabled(true)
        }else{
            setNextEnabled(false)
        }
    }

    const nextUrl = pathToCreditCheckOnceOffRoute(appContext.arToken,appContext.userToken, "security")

    return (
            <OnceOff>
                <WizardContent activeStep={3} totalSteps={5} image="/images/creditcheck/id.svg" imageClass="middle">
                    <TextContent>
                        <h4>Let's verify your identity</h4>
                        <p>Enter your 13 digit South African ID number</p>
                    </TextContent>
                    <Formik initialValues={{idNumber:''}}
                        onSubmit={(values, actions) => {    
                            console.log("submitting",values)
                            postIdNumber(appContext.arToken,appContext.userToken,values.idNumber).then(identityStepResponse=>{
                                setAppContext((state) =>({...state,identityStepData:identityStepResponse})); 
                                actions.setSubmitting(false);
                                history.push(nextUrl);
                            }).catch(error=>{
                                notification['error']({
                                    message: "Something went wrong",
                                    description: error.message
                                });
                                actions.setSubmitting(false);
                            });                       
                        }}
                        render={(props) => (
                            <React.Fragment>
                                <NarrowForm layout='vertical' size='large'  onSubmit={props.handleSubmit}>
                                    <Input name="idNumber" placeholder="#############" autoComplete="off"  onChange={handleIdChange} onBlur={handleIdChange} style={{letterSpacing:"2px",marginBottom:'1em'}}/>    
                                    <PrimaryBlockButton disabled={!nextEnabled} htmlType="submit" loading={props.isSubmitting} style={{marginLeft:'auto',marginRight:'auto'}}>Next</PrimaryBlockButton>
                                </NarrowForm>
                            </React.Fragment>
                    )}/>                
                </WizardContent>
            </OnceOff>
    )
}
export default withRouter(CreditIdentity)